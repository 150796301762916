import { Axios } from '../../axios';

const getNSITF = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/nsitf?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkNSITF = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/nsitf/bulk', payload);
  return data;
};

const fetchNSITFByRef = async (payload) => {
  const { data } = await Axios.get('/statutory-payments/nsitf/by-ref/' + payload);
  return data;
};


export const nsitfApis = {
    getNSITF,
    bulkNSITF,
    fetchNSITFByRef
};