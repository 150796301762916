import { user } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { queryClient } from '../..';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

export function useGetUsers(limit, page, start_date, end_date, search) {
  return useQuery(
    ['users', { limit, page, start_date, end_date, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return user.getUsers({ limit, page, start_date, end_date, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((user) => {
          user.created = moment(user.created).format('MMMM DD, YYYY')
          user.computedActions = [0, 1]
          user.status = user.user_activation
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateDeactivateUser() {
  return useMutation(
    (payload) => {
      return user.activateDeactivateUser(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('payroll-history');
        store.dispatch(setAlert(true, 'success', 'User status changed Sent Successfuly'));
      },
    },
  );
}



