import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import Info from './info';
import ChooseFileSVG from '../assets/svg/choose-file.svg';

const FormFileInput = ({
  id,
  name,
  label,
  multiSource = false,
  placeholder,
  accept,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  ...rest
}) => {
  return (
    <>
      {multiSource ? (
        <div className='mt-10 max-w-[366px] w-full h-[170px] bg-secondary-2-extralight flex justify-center items-center flex-col'>
          <div className=''>
            <UncontrolledDropdown group className='flex flex-row h-[45px]'>
              {/* <FileButton color='primary'>Choose File</FileButton> */}
              <div className='file-select-wrapper w-[95%]'>
                <button className='w-full rounded-l-md border-r border-r-color-white  flex flex-row justify-center px-3 items-center bg-secondary-2 h-full'>
                  <div>
                    <img src={ChooseFileSVG} alt='' />
                  </div>
                  <span className='ml-2 text-color-white'>Choose File</span>
                </button>
                <input
                  type='file'
                  accept={accept}
                  ref={inputRef}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly ? true : false}
                  value={value}
                  {...rest}
                  name='file'
                />
              </div>

              <DropdownToggle
                caret
                className='!bg-secondary-2 !w-[60px] !px-0'
              />
              <DropdownMenu>
                <DropdownItem header>Upload from</DropdownItem>
                <DropdownItem disabled>Google Drive</DropdownItem>
                <DropdownItem disabled>One Drive</DropdownItem>
                <DropdownItem disabled>From URL</DropdownItem>
                <DropdownItem divider />
                <DropdownItem disabled>Documents</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          <span className='p4 pt-2'> or drop file here</span>

          {error && <span>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      ) : (
        <div className='pb-[20px]'>
          <div className='flex flex-col'>
            {label && (
              <label className='text-[18px] leading-[23px] font-normal text-color-gray'>
                {label}
              </label>
            )}
            <input
              name={name}
              type='file'
              placeholder={placeholder}
              accept={accept}
              // defaultValue={value}
              value={value}
              className='file-input bg-color-off-white rounded-md h-[65px] p-[20px] border-[0.5px] border-color-gray mt-[10px] text-color-black'
              ref={inputRef}
              onChange={onChange}
              onKeyDown={onKeyDown}
              readOnly={readOnly ? true : false}
              {...rest}
            />
          </div>

          {error && <span>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      )}
    </>
  );
};

export default FormFileInput;
