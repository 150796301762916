import { Axios } from '../../axios';

const getPension = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/statutory-payments/pensions?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkPension = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/pensions/bulk', payload);
  return data;
};

const fetchPensionByRef = async (payload) => {
  const { data } = await Axios.get('/statutory-payments/pensions/by-ref/' + payload);
  return data;
};

export const pensionApis = {
    getPension,
    bulkPension,
    fetchPensionByRef,
};