import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';

import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetMobileUsers } from '../../redux/mobile-user/hook';
import SendEmail from '../../components/modal/emails/send-email';
import Button from '../../components/button';
import SendFeatureEmail from '../../components/modal/emails/send-feature-email';
import SendSingleEmail from '../../components/modal/emails/send-single-email';
import SendNotification from '../../components/modal/emails/send-notification';
import SendSingleNotification from '../../components/modal/emails/send-single-notification';

const MobileUsersPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search:''
  });

  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendSingleEmail, setShowSendSingleEmail] = useState(false);
  const [showSendNotification, setShowSendNotification] = useState(false);
  const [showSendSingleNotification, setShowSendSingleNotification] = useState(false);
  const [showSendFeatureEmail, setShowSendFeatureEmail] = useState(false);
  const [userId, setUserId] = useState(false);

  const { data: users } = useGetMobileUsers(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const tableConfig = {
    headers: ['Date Created', 'First Name', 'Last Name', 'Email', 'Mobile'],
    keys: ['created', 'first_name', 'last_name', 'email', 'mobile'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'First Name',
        key: 'first_name',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Send Email',
      color: 'orange',
      action: (id) => {
        let index = users.data.findIndex((item) => item.id === id);
        setUserId(users.data[index].id)
        setShowSendSingleEmail(true)
      },
    },
    {
      name: 'Send Notification',
      color: 'orange',
      action: (id) => {
        let index = users.data.findIndex((item) => item.id === id);
        setUserId(users.data[index].id)
        setShowSendSingleNotification(true)
      },
    },
  ];

  return (
    <DashboardLayout title='Mobile Users'>
      {showSendSingleEmail ?
        <SendSingleEmail
            title={'Send Email To Mobile User'}
            isOpen={showSendSingleEmail}
            closeModal={() => setShowSendSingleEmail(false)}
            type={'mobile'}
            id={userId}
            buttonText='Send Email'
        />
      : null}
      {showSendEmail ?
        <SendEmail
            title={'Send Notification To Mobile Users'}
            isOpen={showSendEmail}
            closeModal={() => setShowSendEmail(false)}
            type={'mobile'}
            buttonText='Send Email'
        />
      : null}
      {showSendSingleNotification ?
        <SendSingleNotification
            title={'Send Notification To Mobile User'}
            isOpen={showSendSingleNotification}
            closeModal={() => setShowSendSingleNotification(false)}
            id={userId}
            buttonText='Send Notification'
        />
      : null}
      {showSendNotification ?
        <SendNotification
            title={'Send Notification To Mobile Users'}
            isOpen={showSendNotification}
            closeModal={() => setShowSendNotification(false)}
            buttonText='Send Notification'
        />
      : null}
      {showSendFeatureEmail ?
        <SendFeatureEmail
            title={'Send Feature Email To Mobile Users'}
            isOpen={showSendFeatureEmail}
            closeModal={() => setShowSendFeatureEmail(false)}
            type={'mobile'}
            buttonText='Send Email'
        />
      : null}
      <div className='flex flex-col'>
        <div className='flex flex-col bg-white'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Mobile Users
            </div>
            <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Notification'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowSendNotification(true)}}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Email'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowSendEmail(true)}}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Feature Email'
                  type='button'
                  theme='secondary'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowSendFeatureEmail(true)}}
                />
              </div>
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={users}
            tableConfig={tableConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
            actionConfig={actionConfig}
            computedActions={true}
          />
        </div> 
      </div>
    </DashboardLayout>
  );
};

export default MobileUsersPage;
