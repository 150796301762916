import { Axios } from './axios';

const getMobileUsers = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/mobile-users?limit=${payload.limit}&page=${payload.page}${search}`);
  return data?.data;
};

export const mobile_user = {
  getMobileUsers
};
