import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, path }) => {
  const { user, token } = useSelector((state) => state.userSlice);

  if (!user) {
    return <Redirect to='/' />;
  }
  return <Route component={Component} path={path} />;
};
export default ProtectedRoute;
