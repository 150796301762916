import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useFetchAdminByToken, useSetAdminPassword } from '../../redux/admin/hook';

const SetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();
  const { user } = useSelector((state) => state.userSlice);

  const { mutate: setPassword, isLoading: loading } = useSetAdminPassword();

  const { token } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { data: currentUser } = useFetchAdminByToken(token);

  const submitForm = async (data) => {
    const password = DOMPurify.sanitize(data?.password);
    const confirm_password = DOMPurify.sanitize(data?.confirm_password);

    const requestData = {
        'email_token':token,
        'password_confirmation':confirm_password,
        'password':password,
    };

    await setPassword(requestData).then(() => {
      history.push(`/`);
    });
  };

  if (user) return window.location.assign('/dashboard');
  return (
    <AuthLayout>
      <React.Fragment>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='w-full text-center flex flex-col pb-[25px]'>
            <span className='header-3-regular md:header-2 text-secondary-2'>
              Welcome, {currentUser? currentUser.first_name: null} {currentUser? currentUser.last_name: null}
            </span>
            <span className='header-4-regular'>
              Enter Your New Password
            </span>
          </div>

          <FormInput
            label='Password'
            name='password'
            type='password'
            inputRef={register(formValidation('password', true))}
            readOnly={loading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
          />

          <FormInput
            label='Confirm Password'
            name='confirm_password'
            type='password'
            inputRef={register(formValidation('password', true))}
            readOnly={loading}
            error={errors.confirm_password}
            errorMessage={errors.confirm_password && errors.confirm_password.message}
          />
          <Button
            text='COMPLETE'
            type='submit'
            loading={loading}
            disabled={!isValid}
          />
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default SetPassword;
