import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import AddSalaryAdvance from '../modal/employees/add-salary-advance';

import { useGetEmployeeSalaryAdvance } from '../../redux/employee/hook/salary-advance';

const EmployeeSalaryAdvanceTab = () => {


  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: employeeAdvance } = useGetEmployeeSalaryAdvance(
    pagination?.limit,
    pagination?.page,
  );

  // useEffect(() => {
  //   // console.log('employee =', employeeAdvance);
  // }, [employeeAdvance]);

  const [saladData, setSaladData] = useState();
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const tableConfig = {
    title: 'Salary Advance',
    headers: [
      'Date Created',
      'Date Added',
      'Employee Name',
      'Phone number',
      'Purpose',
      'Month',
      'Amount',
    ],
    keys: ['created', 'employeeName', 'phone', 'purpose', 'month', 'amount'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [

    {
      name: 'Edit/View',
      color: 'green',
      action: (id) => {
        let index = employeeAdvance.data.findIndex((item) => item.id === id);
        setSaladData(employeeAdvance.data[index])
        setShowAddSalaryAdvance(true)
      },
    },
  ];

  return (
    <div>
      {showAddSalaryAdvance && saladData?
        <AddSalaryAdvance
          isOpen={showAddSalaryAdvance}
          closeModal={() => setShowAddSalaryAdvance(false)}
          saladData={saladData}
          title='Edit Salary Advance'
          buttonText="UPDATE"
        />
      :
      null} 
      <DataTable
        data={employeeAdvance}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
      />
    </div>
  );
};

export default EmployeeSalaryAdvanceTab;
