import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignInPage from './pages/auth/sign-in';
import Alert from './components/alert';
import { AdminRoutes } from './routes/admin';
import { useEffect } from 'react';
import { isLoading, setIsLoading } from './redux/components/components-slice';
import { useDispatch } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PageNotFound from './pages/errors/page-not-found';
import GuestRoute from './routes/guest';
import ResetPassword from './pages/auth/reset-password';
import SetPassword from './pages/auth/set-password';
import ForgotPasswordPage from './pages/auth/forgot';
import CheckEmailPassword from './pages/auth/check-email';

const App = () => {
  const { alert } = useSelector((state) => state.componentsSlice);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    //stop any interrupted loader on reload
    if (loading) {
      dispatch(setIsLoading(false));
    }
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <GuestRoute exact path='/' component={SignInPage} />
          <GuestRoute
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <GuestRoute
            exact
            path='/forgot'
            component={ForgotPasswordPage}
          />
          <GuestRoute
            exact
            path='/check-email'
            component={CheckEmailPassword}
          />
          <GuestRoute
            exact
            path='/activate/:token'
            component={SetPassword}
          />
          {AdminRoutes()}
          <Route path='*' component={PageNotFound} />
        </Switch>
      </BrowserRouter>
      {alert?.show && (
        <Alert className={alert?.type} textBeforeLink={alert?.message} close />
      )}
    </React.Fragment>
  );
};

export default App;
