import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetSubscriptionHistory } from '../../redux/hr-subscription/hook';


const SubscriptionHistory = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: subscription_history } = useGetSubscriptionHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const tableConfig = {
    headers: ['Date Created', 'Company Name' ,'Subscription Plan', 'Start Date', 'End Date', 'Billing Cycle', 'Amount'],
    keys: ['created', 'company_name', 'subscription_plan', 'start_date', 'end_date', 'billing_cycle', 'amount_paid'],
    mobileHeader: {
      left: {
        title: 'Company Created',
        key: 'created',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  return (

    <div className='flex flex-col bg-white'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Subscription History
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={subscription_history}
        tableConfig={tableConfig}
        // actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default SubscriptionHistory;

