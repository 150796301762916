import React, { useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import OverviewTab from '../../components/employee/overview-tab';
import TabView from '../../components/tabview';
import EmployeeSalariesTab from '../../components/employee/employee-salaries-tab';
import EmployeeGroupsTab from '../../components/employee/employee-groups-tab';
import EmployeeSalaryAdvanceTab from '../../components/employee/employee-salary-advance-tab';
import EmployeeReimbursementTab from '../../components/employee/employee-reimbursements-tab';
import { EmployeeBonusTab } from './../../components/employee/employee-bonus-tab';
import EmployeeSuspensionsTab from '../../components/employee/employee-suspensions';

const EmployeePage = () => {
  const [activeTab, setActiveTab] = useState(1);
  // const { data: getEmployeeSummary, isloading } = useGetEmployeeSummary();
  


  const EmployeeTabs = [
    {
      title: 'Employee overview',
      Content: OverviewTab,
    },
    {
      title: 'Employee groups',
      Content: EmployeeGroupsTab,
    },
    {
      title: 'Employee Salaries',
      Content: EmployeeSalariesTab,
    },
    {
      title: 'Salary Advance',
      Content: EmployeeSalaryAdvanceTab,
    },
    {
      title: 'Reimbursements',
      Content: EmployeeReimbursementTab,
    },
    {
      title: 'Bonus',
      Content: EmployeeBonusTab,
    },
    {
      title: 'Suspension',
      Content: EmployeeSuspensionsTab,
    },
  ];

  return (
    <DashboardLayout title='Employee'>
      <div className='flex flex-col'>
        
        <div className='mt-2'>
          <TabView
            Tabs={EmployeeTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EmployeePage;
