import React from 'react';
import DataTable from '../../datatable';
import { useState, useEffect } from 'react';
import { bonusesStatusConfig, PAGINATION_DEFAULT } from '../../../utils/constants';
import { useSingleGetEmployeeBonuses } from '../../../redux/employee/hook/bonuses';
import { useParams } from 'react-router-dom';
import AddBonus from '../../modal/employees/add-bonus';

export const SingleEmployeeBonusTab = () => {
  const [pagination, setPagination] = useState({
      limit: PAGINATION_DEFAULT.limit,
      page: PAGINATION_DEFAULT.page,
      search: '',
  });
  const { id } = useParams();

  useEffect( async () => {
  }, [id]);

  const { data: Bonuses } = useSingleGetEmployeeBonuses(id);

  const [showAddBonus, setShowAddBonus] = useState(false);
  const [bonusData, setBonusData] = useState();
  

  useEffect( async () => {
      // console.log('employee Salary', Bonuses);
  }, [Bonuses]);



  const tableConfig = {
    title: 'Bonus Management',
    headers: [
      'Date Created',
      'Purpose',
      'Month',
      'Amount',
      'Status',
    ],
    keys: ['created', 'purpose', 'month', 'amount', 'status'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'orange',
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        setBonusData(Bonuses.data[index])
        setShowAddBonus(true)
      },
    },
    // {
    //   name: 'Delete',
    //   color: 'red',
    //   action: () => {
        
    //   },
    // },
  ];

  return (
    <div>

      {showAddBonus && bonusData?
      <AddBonus
        isOpen={showAddBonus}
        closeModal={() => setShowAddBonus(false)}
        bonusData={bonusData}
        title='Edit Bonus'
        buttonText='UPDATE'
      />
      :
      null}
      <DataTable
        data={Bonuses}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={bonusesStatusConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        // computedActions={true}
      />
    </div>
  );
};
