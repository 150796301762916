import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { pensionApis } from './../../../api/payroll/statutory-compliance/pension';

export function useGetPensionHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['pension', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return pensionApis.getPension({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((pension) => {
          pension.status = parseFloat(pension.pension_status)
          if (pension.status === 2) {
            pension.status = 0
          }
          switch (pension.status) {
            case 0:
              pension.computedActions = [0];
              break;
            case 1:
              pension.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadPension() {
  return useMutation(
    (payload) => {
      return pensionApis.bulkPension(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pension');
        store.dispatch(setAlert(true, 'success', 'Upload Successful'));
      },
    },
  );
}

export function useRefPension(payload) {
  return useQuery(
    ['pension-by-ref', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return pensionApis.fetchPensionByRef(payload);
    },
    {
      select: (data) => {
        data.data.forEach((item) => {
          item.employeeName = item.firstName + " " + item.lastName
          item.totalPension = parseFloat(item.employeeContribution) + parseFloat(item.voluntaryContribution)
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}