import React, { useEffect, useState } from 'react';
import DataTable from '../datatable';
import { useHistory } from 'react-router-dom';
import { StatutoryConfig, PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetPensionHistory } from '../../redux/statutory-compliance/hook/pension';
import { useSelector } from 'react-redux';

const PensionTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const { company } = useSelector((state) => state.companySlice);

  const { data: Pensions } = useGetPensionHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const history = useHistory();

  const tableConfig = {
    title: 'Pension',
    headers: ['Date', 'Reference Number', 'Description', 'Month', 'Status'],
    keys: ['created', 'ref', 'desc', 'month', 'status'],
    mobileHeader: {
      left: {
        title: 'Month',
        key: 'month',
      },
      right: {
        title: 'Description',
        key: 'desc',
      },
    },
  };
  useEffect(() => {
    if (Pensions) {
      console.log('pen', Pensions);
    }
    
  }, [Pensions]);

  function Continue(id, type) {
    let index = Pensions.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === 'preview') {
        history.push(
          `/statutory-deductions/pensions/preview/${Pensions.data[index].ref}`,
        );
      } else if (type === 'view') {
        history.push(`/statutory-deductions/pensions/view/${Pensions.data[index].ref}`);
      }
    }
  }

  const statusConfig = [
    {
      label: 'In Progress',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Successful',
      color: 'green',
      value: 1,
    },
    
  ];

  const actionConfig = [
    {
      name: 'Pay Now',
      color: 'orange',
      action: (id) => {
        Continue(id, 'preview');
      },
    },
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        Continue(id, 'view');
      },
    },
  ];

  return (
    <div>
      <DataTable
        data={Pensions}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        searchData={true}
        computedActions={true} 
      />
    </div>
  );
};

export default PensionTab;
