import React from 'react';

function LoadingBar({loading}) {
    return (
      <>
            {loading ? (
               <div className='loader'>
      <div className='loaderBar'></div>
    </div>
             ) : null}
      </>
 
  );
}

export default LoadingBar;
