import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useGetAdvancedPayrollCheck, useGetAdvancedPayrollCheckGroup, useGetAdvancedPayrollCheckSelective, useRunAdvancedPayroll, useRunAdvancedPayrollGroup, useRunAdvancedPayrollSelective, useRunBasicPayroll } from '../../../redux/payroll/hook';
import Button from '../../button';
import WarningSVG from '../../../assets/svg/warning.svg';
import RunAdvancedPayroll from './run-advanced-payroll';
import { useHistory } from 'react-router-dom';

function AdvancedPayrollBreakdown({
  isOpen,
  closeModal,
  source = "default",
  sourceData,
  payloadData
}) {


  const [data, setData] = useState(null);
  const { refetch: getAdvancePayrollCheck,  data: advancedPayrollCheck } = useGetAdvancedPayrollCheck(); 
  const { refetch: getAdvancePayrollCheckGroup, data: advancedPayrollCheckGroup } = useGetAdvancedPayrollCheckGroup(sourceData);
  const { mutateAsync: getAdvancedPayrollCheckSelective } = useGetAdvancedPayrollCheckSelective();
  
  const { mutateAsync: runAdvancedPayroll, isLoading: loading } = useRunAdvancedPayroll();
  const { mutateAsync: runAdvancedPayrollGroup, isLoading: groupLoading } = useRunAdvancedPayrollGroup();
  const { mutateAsync: runAdvancedPayrollSelective, isLoading: selectiveLoading } = useRunAdvancedPayrollSelective();

  const history = useHistory();

  const submitForm = async () => {

    if (source === 'default') {
      await runAdvancedPayroll(payloadData).then((response) => {
        onAdvancedPayrollSuccess(response.data.ref);
      });
    } else if (source === 'group') {

      await runAdvancedPayrollGroup(payloadData).then((response) => {
        onAdvancedPayrollSuccess(response.data.ref);
      });
    } else if (source === 'selective') {
      await runAdvancedPayrollSelective(payloadData).then((response) => {
        history.push(`/payroll/advanced/preview/${response.data.ref}`);
        onAdvancedPayrollSuccess(response.data.ref);
      });
    }
  };


  function onAdvancedPayrollSuccess(ref) {
    history.push(`/payroll/advanced/preview/${ref}`);
    closeModal();
  }


  useEffect(() => { 

    if (isOpen && source === 'default') {
    
      getAdvancePayrollCheck();
    }
    else if (isOpen && source === 'selective') { 
      let payload = {
        employee_ids: sourceData
      }

      getAdvancedPayrollCheckSelective(payload).then((result) => {
        setData(result);
      })
    }
    else if (isOpen && source === 'group') { 
      getAdvancePayrollCheckGroup();
    }

    // if (!isOpen) {
    //   setData(null);
    // }
    
  }, [isOpen, source])


  useEffect(() => { 
    if (advancedPayrollCheck) {
      setData(advancedPayrollCheck);
         }
  }, [advancedPayrollCheck])

  useEffect(() => { 
    if (advancedPayrollCheckGroup) {
      setData(advancedPayrollCheckGroup);
         }
  }, [advancedPayrollCheckGroup])

  return (

      <Modal
      scrollable={true}
      className='!max-w-[700px] selection: h-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto  min-h-[600px] item'>
        <div className='flex justify-end pt-4 pr-4'>
          <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='' />
          </div>
        </div>
        <div className='flex flex-col mx-8'>
          <div>
            <span className='header-3'>Pay</span>
            <hr className='divider' />
          </div>

          {data ? (
            <div className='flex flex-col'>
                <div className='flex flex-col items-center'>
                  <span className='stat-card-header'>PAYROLL MONTH</span>
                  <span className='p4-medium  text-color-black'>{data.month}</span>
                </div>
                <hr className='divider' />
    
                <div className='flex flex-col items-center mt-[2%]'>
                  <span className='stat-card-header'>ACTIVE EMPLOYEES CHARGED</span>
                  <span className='p4-medium  text-color-black'>{data.total_salaries}</span>
                </div>
                <hr className='divider' />
                <div className='flex flex-col items-center mt-[2%]'>
                  <span className='stat-card-header'>FEES PER EMPLOYEE</span>
                <span className='p4-medium  text-color-black'>{data.advanced_payroll_fee}</span>
                </div>
                <hr className='divider' />
                <div className='flex flex-col items-center mt-[2%]'>
                  <span className='stat-card-header'>TOTAL FEES</span>
                <span className='p4-medium  text-color-black'>{data.total_payroll_fee}</span>
                </div>
              </div>
          ) : null}
    
          <hr className='divider' />
          <div className='flex mb-8'>
            <div className='flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-5 mt-2 bg-color-warning-light'>
              <div className='w-[70px] pr-2 flex flex-row items-center '>
                <img src={WarningSVG} alt='' srcset='' />
              </div>

              <div className='p4-regular'>
                <span className='p4-bold'>What Does this Fee Cover?</span>

                <li>Payroll Computation, payment and Payslip to Employees</li>
                <li>Pension Computation, Payment and Filling</li>
                <li>Tax Computation, Payment and Filling</li>
                <li>Other Statutory Computation, Payment and Filing</li>
              </div>
            </div>
          </div>

          <div className='w-full mb-[20px]'>
            <Button
              text='CONTINUE'
              type='submit'
              loading={loading || groupLoading || selectiveLoading}
              onClick={()=> submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AdvancedPayrollBreakdown;
