
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import { useSendFeatureEmail } from '../../../redux/email/hook';
import { Editor } from '@tinymce/tinymce-react';

function SendFeatureEmail ({ isOpen, closeModal, refresh, type, title }) {
    const {
        register,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const [subject, setSubject] = useState()
    const [body, setBody] = useState()
    const [closingRemark, setClosingRemark] = useState('Regards,')
    const [salutation, setSalutation] = useState('Hello ');
    
    const salutationOptions = [
        {
            label: 'Hello',
            value: 'Hello',
        },
        {
            label: 'Hi',
            value: 'Hi',
        },
        {
            label: 'Hey',
            value: 'Hey',
        },
        {
            label: 'Dear',
            value: 'Dear',
        },
        {
            label: 'Dear Mr.',
            value: 'Dear Mr.',
        },
        {
            label: 'Dear Mrs.',
            value: 'Dear Mrs.',
        },
    ];

    const [features, setFeatures] = useState([
        { name: '', description: '' }
    ]);
    
    const { id } = useParams();

    const addFeature = () => {
        setFeatures([
            ...features,
            ...[{ name: '', description: ''}],
        ]);
    };

    const removeFeature = (index) => {
        let data = [...features];
        if (index > -1) {
            // only splice array when item is found
            data.splice(index, 1); // 2nd parameter means remove one item only
            setFeatures([...data]);
        }
    };
    
    const { mutateAsync: sendFeatureEmail, isLoading: sendFeatureEmailLoading } =
    useSendFeatureEmail();

    
    const closingRemarkOptions = [
        {
            label: 'Kind regards',
            value: 'Kind regards,',
        },
        {
            label: 'Thank you',
            value: 'Thank you,',
        },
        {
            label: 'Looking forward to meeting you',
            value: 'Looking forward to meeting you,',
        },
        {
            label: 'Best',
            value: 'Best,',
        },
        {
            label: 'Sincerely',
            value: 'Sincerely,',
        },
        {
            label: 'Regards',
            value: 'Regards,',
        },
        {
            label: 'Cheers',
            value: 'Cheers,',
        },
    ];

    const submitForm = async () => {
        let payload = {
            salutation: salutation,
            subject,
            body: body.toString(),
            closing_remark: closingRemark,
            type,
            features
        }
        await sendFeatureEmail(payload).then(()=> {
            closeModal()
        });
    };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-8'>
          <div className='flex flex-row justify-between'>
            <span className='header-3'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
            <form className='form'>
                <FormInput
                    label='Subject'
                    name='subject'
                    type='text'
                    value={subject}
                    placeholder={'Email Subject'}
                    onInput={(e) => {
                        setSubject(e.target.value)
                    }}
                    inputRef={register(formValidation('text', true))}
                    error={errors.subject}
                    errorMessage={errors.subject && errors.subject.message}
                />
                <div className='flex md:flex-row flex-col w-full justify-between'>
                    <div className='w-full'>
                        <FormSelect
                            value={salutation}
                            options={salutationOptions}
                            onChange={(selected) => {
                            setSalutation(selected);
                            }}
                            label='salutation'
                        />
                    </div>
                </div>
                <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                    <div className='w-full'>
                        <span className='text-color-gray text-[15px] pb-2'>Body</span>
                        <Editor
                            apiKey={'0m0v3d8ckewvqyou34thh7zx8d07pdgxhaa8nehbn7xrkbsv'}
                            onEditorChange={(editor) => {
                                setBody(editor)
                            }}
                            value={body}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
                </div>
                <FormSelect
                    defaultValue={closingRemark}
                    options={closingRemarkOptions}
                    onChange={(selected) => {
                        setClosingRemark(selected);
                    }}
                    label='Closing Remark'
                />
                <hr className='divider' />
                <div className='w-full mt-3 pt-2 pb-2'>
                    {features?.map((item, index) => (
                        <>
                            {index !== 0 ? <hr className='divider -mt-1' /> : null}
                            <div className='flex flex-col duration-300'>
                                <div className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 pl-2 text-[13px]'>
                                    <div className='text-[14px] italic text-[#44AF69]'>
                                        Feature {index + 1}
                                    </div>
                                    <div
                                    className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 pl-2 text-[13px]'
                                    key={index}
                                    >
                                        <div className=' flex flex-col W-[70%] pl-2'>
                                            <div className='flex flex-row gap-3'>
                                                <div className='flex flex-row items-center !w-[100%]'>
                                                    <FormInput
                                                    label='Feature'
                                                    name={index + '_criteria_name'}
                                                    type='text'
                                                    value={item.name}
                                                    onInput={(e) => {
                                                        let newArray = Object.assign([], features);
                                                        newArray[index].name = e.target.value;
                                                        setFeatures(newArray);
                                                    }}
                                                    className='!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 md:min-w-[260px]'
                                                    />
                                                </div>
                                            </div>
                                            <div className='items-center w-full'>
                                                <FormTextArea
                                                    label={'Description'}
                                                    name={index + '_description'}
                                                    type='text'
                                                    defaultValue={item.description}
                                                    onInput={(e) => {
                                                    let newArray = Object.assign([], features);
                                                    newArray[index].description = e.target.value;
                                                    setFeatures(newArray);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-[10%] ml-3 italic cursor-pointer text-[13px] hover:underline text-rose-500 pr-2 font-bold'
                                            onClick={() => {
                                                removeFeature(index)
                                            }}>
                                            Remove
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                    <hr className='divider -mt-1' />
                    <div className='flex justify-left w-full mb-3'>
                        <span
                        className='p2-medium cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#44AF69]'
                        onClick={() => addFeature()}
                        >
                        Add New Feature
                        </span>
                    </div>
                </div>
                <div className='w-full mt-[20px]'>
                    <Button
                    text={'Send Product Update Email'}
                    type='button'
                    onClick={() => submitForm()}
                    loading={sendFeatureEmailLoading}
                    disabled={!isValid}
                    />
                </div>
            </form>
        </div>
      </div>
    </Modal>
  );
}

export default SendFeatureEmail;
