import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useRunBasicPayroll,
  useRunBasicPayrollGroup,
  useRunBasicPayrollSelective,
} from '../../../redux/payroll/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import { useHistory } from 'react-router-dom';

function RunBasicPayroll({
  isOpen,
  closeModal,
  source = 'default',
  sourceData,
  refresh,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();
  const { mutateAsync: runBasicPayroll, isLoading: payrollLoading } =
    useRunBasicPayroll();
  const { mutateAsync: runBasicPayrollGroup, isLoading: payrollGroupLoading } =
    useRunBasicPayrollGroup();
  const {
    mutateAsync: runBasicPayrollSelective,
    isLoading: payrollSelectiveLoading,
  } = useRunBasicPayrollSelective();

  const submitForm = async (data) => {

    const desc = DOMPurify.sanitize(data?.desc);
    const month = DOMPurify.sanitize(data?.month);
    
    let payload = {
      desc,
      month,
    };

    if (source === 'default') {
      await runBasicPayroll(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    } else if (source === 'group') {
      payload.group_id = sourceData;
      await runBasicPayrollGroup(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    } else if (source === 'selective') {
      payload.employee_ids = sourceData;
      await runBasicPayrollSelective(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    }
  };

  function onBasicPayrollSuccess(ref) {
    history.push(`/payroll/basic/preview/${ref}`);
    closeModal();
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8 mb-10'>
            <div>
              <span className='header-3'>Run Basic Payroll</span>
              <hr className='divider' />
            </div>

            <div className='mt-3'>


           <FormInput
                label='Select Month'
                name='month'
                type='date'
                inputRef={register(formValidation('text', true))}
                readOnly={
                  payrollLoading ||
                  payrollGroupLoading ||
                  payrollSelectiveLoading
                }
                error={errors.month}
                errorMessage={errors.month && errors.desc.month}
              />
            
              <FormInput
                label='Description'
                name='desc'
                type='text'
                inputRef={register(formValidation('text', true))}
                readOnly={
                  payrollLoading ||
                  payrollGroupLoading ||
                  payrollSelectiveLoading
                }
                error={errors.desc}
                errorMessage={errors.desc && errors.desc.message}
              />
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text='ADD'
                type='submit'
                disabled={!isValid}
                loading={
                  payrollLoading ||
                  payrollGroupLoading ||
                  payrollSelectiveLoading
                }
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default RunBasicPayroll;
