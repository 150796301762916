import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useEditSalary,
  useGetEmployeeSalary,
  useGetSalaryByEmployee,
} from '../../../redux/employee/hook/salaries';
import Button from '../../button';
import { useSelector } from 'react-redux';

import FormInput from '../../form-input';
import LoadingBar from '../../loader-bar';

const EmployeeCompensation = () => {
const { id } = useParams();
useEffect(async () => {}, [id]);


const { data: singleEmployeeSalary, isLoading } = useGetSalaryByEmployee(id);

const { mutateAsync: editSalary, isLoading: loading } = useEditSalary();

const [basicSalary, setBasicSalary] = useState();
const [housingAllowance, setHousingAllowance] = useState();
const [transportationAllowance, setTransportationAllowance] = useState();
const [leaveAllowance, setLeaveAllowance] = useState();
const [lunchAllowance, setLunchAllowance] = useState();
const [otherAllowance, setOtherAllowance] = useState();
const [benefitInKind, setBenefitInKind] = useState();
const [netPay, setNetPay] = useState(0);
const [utilityAllowance, setUtilityAllowance] = useState();
const [salaryId, setSalaryId] = useState();

  useEffect(async () => {
    setBasicSalary(singleEmployeeSalary?.basic_salary);
    setHousingAllowance(singleEmployeeSalary?.housing_allowance);
    setTransportationAllowance(singleEmployeeSalary?.transportation_allowance);
    setLeaveAllowance(singleEmployeeSalary?.leave_allowance);
    setLunchAllowance(singleEmployeeSalary?.lunch_allowance);
    setOtherAllowance(singleEmployeeSalary?.other_allowance);
    setBenefitInKind(singleEmployeeSalary?.benefit_in_kind);
    setNetPay(singleEmployeeSalary?.net_pay);
    setUtilityAllowance(singleEmployeeSalary?.utility_allowance);

    setSalaryId(singleEmployeeSalary?.id);
    // console.log('employee Salary', singleEmployeeSalary);
  }, [singleEmployeeSalary]);

  const history = useHistory()

  const updateCompensation = async () => {
    let payroll = {
      id: salaryId,
      body: {
        basic_salary: basicSalary,
        housing_allowance: housingAllowance,
        transportation_allowance: transportationAllowance,
        lunch_allowance: lunchAllowance,
        benefit_in_kind: benefitInKind,
        airtime_allowance: 0,
        data_allowance: 0,
        leave_allowance: leaveAllowance,
        utility_allowance: utilityAllowance,
        other_allowance: otherAllowance,
        net_pay: netPay,
      },
    };
    await editSalary(payroll);
  };

  return (
    <React.Fragment>
      <div className='md:px-14 px-4 pt-4 pb-8 box-shadow h-full w-full'>
        <span className='h4'>Compensation</span>
        <hr className='divider' />
        {isLoading?
        <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
            <LoadingBar loading={isLoading} />
        </div>
        :
        <>
          {singleEmployeeSalary ? (
            <div className={'flex flex-col mt-7 w-full md:w-[100%]'}>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Basic Salary'
                    name='basic'
                    type='number'
                    value={basicSalary}
                    onInput={(e) => setBasicSalary(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Housing allowance'
                    name='housing'
                    type='number'
                    value={housingAllowance}
                    onInput={(e) => setHousingAllowance(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Transport Allowance'
                    name='transportation_allowance'
                    type='number'
                    value={transportationAllowance}
                    onInput={(e) => setTransportationAllowance(e.target.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Leave Allowance'
                    name='leave_allowance'
                    type='number'
                    value={leaveAllowance}
                    onInput={(e) => setLeaveAllowance(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Lunch Allowance'
                    name='lunch_allowance'
                    type='number'
                    value={lunchAllowance}
                    onInput={(e) => setLunchAllowance(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Utility Allowance'
                    name='utility_allowance'
                    type='number'
                    value={utilityAllowance}
                    onInput={(e) => setUtilityAllowance(e.target.value)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Benefit In Kind'
                    name='benefit_in_kind'
                    type='number'
                    value={benefitInKind}
                    onInput={(e) => setBenefitInKind(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Other Allowance'
                    name='other_allowance'
                    type='number'
                    value={otherAllowance}
                    onInput={(e) => setOtherAllowance(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-[250px]'>
                  <FormInput
                    label='Monthly Net Pay'
                    name='net_pay'
                    type='number'
                    value={netPay}
                    onInput={(e) => setNetPay(e.target.value)}
                  />
                </div>
              </div>

              <div className='flex justify-end mt-4'>
                <div className='flex items-end h-full'>
                  <Button
                    text='UPDATE COMPENSATION'
                    type='submit'
                    loading={loading}
                    // disabled={false}
                    className='!px-7 !h-[42px] py-6'
                    textClass='!header-6'
                    onClick={() => updateCompensation()}
                  />
                </div>
              </div>
            </div>
          ) 
          : 
          <div className={'flex flex-row justify-center align-center mt-7 w-full md:w-full mb-2'}>
            <div className='w-full md:w-[220px] mt-3 md:mt-0 h-[56px]'>
              <Button
                text='Add Compensation'
                type='submit'
                loading={false}
                disabled={false}
                className='h-[56px]'
                onClick={() => history.push('/employees/onboarding/select-option?salary=true')}
              />
            </div>
          </div>}
        </>
        }
        
      </div>
    </React.Fragment>
  );
};

export default EmployeeCompensation;
