import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddEmployeeGroup } from '../../../redux/employee/hook';
import {
  useEditEmployeeGroup,
  useGetEmployeeGroup,
} from '../../../redux/employee/hook/groups';
import { PAGINATION_DEFAULT } from '../../../utils/constants';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import CollapsingIcon from '../../collapsing-icon';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormSwitch from '../../form-switch';
import FormTextArea from '../../form-textarea';

function AddEmployeeGroup({
  isOpen,
  closeModal,
  refresh,
  groupData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const [groupId, setGroupId] = useState(null);
  const [groupType, setGroupType] = useState();

  useEffect(() => {
    if (groupData) {
      setGroupId(groupData);
    }
  }, [groupData]);

  const { data: group } = useGetEmployeeGroup(
    groupId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  useEffect(() => {
    if (group) {
      setGroupType('edit');
      setFormData({
        name: group.name,
        description: group.description ? group.description : '',
        pension: group.setting.pension,
        calc_tax: group.setting.calc_tax,
        calc_nhf: group.setting.calc_nhf,
        calc_nstf: group.setting.calc_nsitf,
        calc_itf: group.setting.calc_itf,
        calc_nhis: group.setting.calc_nhis,
        basic_salary: group.setting.basic_salary,
        housing_allowance: group.setting.housing_allowance,
        transport_allowance: group.setting.transport_allowance,
        lunch_allowance: group.setting.lunch_allowance,
        leave_allowance: group.setting.leave_allowance,
        utility_allowance: group.setting.utility_allowance,
        benefit_in_kind: 0,
        other_allowance: group.setting.other_allowance,
        pension_calculator: group.setting.pension_calculator,
        employee_pension: group.setting.employee_pension,
        employer_pension: group.setting.employer_pension,
        nhf_percentage: group.setting.nhf_percentage,
        employee_nhis: group.setting.employee_nhis,
        employer_nhis: group.setting.employer_nhis,
        use_global_settings: group.setting.use_global_settings,
      });
    } else {
      setGroupType('add');
      setFormData({
        name: '',
        description: '',
        pension: true,
        calc_tax: true,
        calc_nhf: false,
        calc_nstf: true,
        calc_itf: true,
        calc_nhis: false,
        basic_salary: 50,
        housing_allowance: 0,
        transport_allowance: 15,
        lunch_allowance: 10,
        leave_allowance: 10,
        utility_allowance: 10,
        benefit_in_kind: 0,
        other_allowance: 5,
        pension_calculator: 'gross',
        employee_pension: 8,
        employer_pension: 10,
        nhf_percentage: 2.5,
        employee_nhis: 5,
        employer_nhis: 10,
        use_global_settings: false,
      });
    }
  }, [group]);

  const { mutateAsync: addGroup, isLoading: addGrouploading } =
    useAddEmployeeGroup();
  const { mutateAsync: editGroup, isLoading: editGrouploading } =
    useEditEmployeeGroup();

  const [totalAllowance, setTotalAllowance] = useState(0);
  const [dayCount, setDayCount] = useState('Calendar Days');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const history = useHistory();

  const [formData, setFormData] = useState({});

  const [groupSettings, setGroupSettings] = useState([
    {
      title: 'Use Company Salary Settings',
      name: 'use_global_settings',
    },
    {
      title: 'Tax (Personal Income Tax)',
      name: 'calc_tax',
    },
    {
      title: 'Pension ',
      name: 'pension',
    },
    {
      title: 'NHIS (National Health Insurance Scheme)',
      name: 'calc_nhis',
    },
    {
      title: 'NHF (National Housing Fund)',
      name: 'calc_nhf',
    },
    // {
    //   title: 'NSIFT (Nigerian Social Insurance Trust Fund)',
    //   name: 'nsift',
    // },
    // {
    //   title: 'ITF (Industrail Training Fund)',
    //   name: 'itf',
    // },
  ]);

  const [AdvanceSalarySettings, setAdvanceSalarySettings] = useState([
    {
      title: 'Basic (%)',
      name: 'basic_salary',
    },
    {
      title: 'Housing (%)',
      name: 'housing_allowance',
    },
    {
      title: 'Transportation (%)',
      name: 'transport_allowance',
    },
    {
      title: 'Benefit-in-kind (%)',
      name: 'benefit_in_kind',
    },
    {
      title: 'Leave allowance (%)',
      name: 'leave_allowance',
    },
    {
      title: 'Lunch allowance (%)',
      name: 'lunch_allowance',
    },
    {
      title: 'Utility allowance (%)',
      name: 'utility_allowance',
    },
    {
      title: 'Other allowance (%)',
      name: 'other_allowance',
    },
  ]);

  const [dayCountOptions, setDayCountOptions] = useState([
    { value: 'seven_days', label: 'Calendar Days' },
    { value: 'week_days', label: 'Working Days' },
  ]);

  const [pensionCalculatorOptions, setPensionCalculatorOptions] = useState([
    { value: 'bht', label: 'Basic Salary, Housing and Transport Allowances' },
    { value: 'gross', label: 'Gross Payment' },
  ]);

  const [pensionSettings, setPensionSettings] = useState([
    {
      title: 'Employer Pension (%)',
      name: 'employer_pension',
    },
    {
      title: 'Employee Pension (%)',
      name: 'employee_pension',
    },
    {
      title: 'Employer NHIS (%)',
      name: 'employer_nhis',
    },
    {
      title: 'Employee NHIS (%)',
      name: 'employee_nhis',
    },
    {
      title: 'NHF (%)',
      name: 'nhf_percentage',
    },
  ]);

  useEffect(() => {
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData]);

  const submitForm = async (data) => {
    const name = DOMPurify.sanitize(data?.name);
    const description = DOMPurify.sanitize(data?.description);
    let payload = {
      ...formData,
      ...{ name, description, days_count: dayCount },
    };

    if (groupType === 'add') {
      await addGroup(payload).then(() => {
        closeModal();
      });
    } else if (groupType === 'edit') {
      let editPayload = {
        id: groupId,
        body: payload,
      };
      await editGroup(editPayload).then(() => {
        closeModal();
      });
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>{title}</span>
              <hr className='divider' />
            </div>

            <div>
              <FormInput
                label='Group Name'
                name='name'
                type='text'
                inputRef={register(formValidation('text', true))}
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                readOnly={addGrouploading || editGrouploading}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
              />
              <FormTextArea
                label={'Group Description'}
                name='description'
                placeholder={'Group Description'}
                inputRef={register(formValidation('text', true))}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                readOnly={addGrouploading || editGrouploading}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
              />
            </div>

            <div>
              <span className='header-4'>Group Settings</span>
              <hr className='divider' />
            </div>

            <div className={'flex w-full flex-col'}>
              {groupSettings.map((item, index) => (
                <>
                  {!formData.use_global_settings ||
                  (formData.use_global_settings &&
                    item.name === 'use_global_settings') ? (
                    <div className='mb-[30px]'>
                      <FormSwitch
                        label={item.title}
                        name={item.name}
                        readOnly={addGrouploading || editGrouploading}
                        checked={formData[item.name]}
                        onClick={() =>
                          setFormData({
                            ...formData,
                            [item.name]: !formData[item.name],
                          })
                        }
                        value={formData[item.name]}
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </div>

            {!formData.use_global_settings ? (
              <>
                {/* Advanced Salary Settings */}

                <div
                  className='flex flex-row justify-between pb-3 items-center'
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                >
                  <span>Advance Payroll Settings</span>
                  <div className='pr-4'>
                    <CollapsingIcon
                      defaultPosition='left'
                      isOpen={showAdvancedSettings}
                    />
                  </div>
                </div>

                <div
                  className={classNames('flex flex-col', {
                    hidden: !showAdvancedSettings,
                  })}
                >
                  <div className='flex justify-end'>
                    <span
                      className={classNames(
                        {
                          'text-green-700': totalAllowance === 100,
                        },
                        {
                          'text-color-error': totalAllowance !== 100,
                        },
                      )}
                    >
                      Total Allowance ({totalAllowance}%)
                    </span>
                  </div>
                  <Collapse isOpen={showAdvancedSettings}>
                    <div className='mt-4 grid md:grid-cols-2 grid-cols-1 gap-4 w-full'>
                      {AdvanceSalarySettings.map((item) => (
                        <FormInput
                          label={item.title}
                          name={item.name}
                          type='text'
                          inputRef={register(
                            formValidation('non-empty', false),
                          )}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [item.name]: e.target.value,
                            })
                          }
                          readOnly={addGrouploading || editGrouploading}
                          error={errors[item.name]}
                          errorMessage={
                            errors[item.name] && errors[item.name].message
                          }
                          value={formData[item.name]}
                        />
                      ))}
                    </div>

                    <FormSelect
                      defaultValue={formData['pension_calculator']}
                      options={pensionCalculatorOptions}
                      onChange={(selected) => {
                        setFormData({
                          ...formData,
                          pension_calculator: selected,
                        });
                      }}
                      label='Pension Calculator'
                    />

                    <div className='mt-4 grid md:grid-cols-2 grid-cols-1 gap-4 w-full'>
                      {pensionSettings.map((item) => (
                        <FormInput
                          label={item.title}
                          name={item.name}
                          type='text'
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [item.name]: e.target.value,
                            })
                          }
                          readOnly={true}
                          value={formData[item.name]}
                        />
                      ))}
                    </div>
                  </Collapse>
                </div>

                <FormSelect
                  defaultValue={dayCount}
                  options={dayCountOptions}
                  onChange={(selected) => {
                    setDayCount(selected);
                  }}
                  label='Day Count'
                />
              </>
            ) : null}

            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  groupType === 'edit' ? false : !isValid ? true : false
                }
                loading={addGrouploading || editGrouploading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddEmployeeGroup;
