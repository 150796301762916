import React from 'react';
import DataTable from '../../datatable';
import { useState, useEffect } from 'react';
import { PAGINATION_DEFAULT } from '../../../utils/constants';
import { useSingleGetEmployeeReimbursements } from '../../../redux/employee/hook/reimbursements';
import { useParams } from 'react-router-dom';
import AddReImbursement from '../../modal/employees/add-reimbursement';

const SingleEmployeeReimbursementTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });
  const { id } = useParams();


    useEffect( async () => {
        // console.log(id);
    }, [id]);

  const { data: employeeReimbursements } = useSingleGetEmployeeReimbursements(id);
  
  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();

  const tableConfig = {
    title: 'Reimbursement Management',
    headers: [
      'Date Created',
      'Purpose',
      'Type',
      'Month',
      'Amount',
    ],
    keys: [
      'created',
      'expenses_description',
      'typeLabel',
      'month',
      'expenses_amount',
    ],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'green',
      action: (id) => {
        let index = employeeReimbursements.data.findIndex((item) => item.id === id);
        setReimbData(employeeReimbursements.data[index])
        setShowAddReImbursement(true)
      },
    },
  ];

  return (
    <div>
      {showAddReImbursement?
      <AddReImbursement
        isOpen={showAddReImbursement}
        closeModal={() => {
          setShowAddReImbursement(false)
        }}
        reimbData={reimbData}
        title='Edit Reimbursement'
        buttonText='UPDATE'
      />
      :
      null
      }
      <DataTable
        data={employeeReimbursements}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default SingleEmployeeReimbursementTab;
