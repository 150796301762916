import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetMobileWalletHistory } from '../../redux/wallet/hook';
import { useCheckTransactionStatus } from '../../redux/payroll/hook';

const MobileTransactionsPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    start_date: '',
    end_date: '',
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });

  const { data: walletHistory } = useGetMobileWalletHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.statusFilter,
  );

  const { mutateAsync: checkStatus, isLoading: checkLoading } = useCheckTransactionStatus();

  const tableConfig = {
    headers: ['Date', 'User Name', 'Account Name', 'Description', 'Fees', 'Status', 'Inflow', 'Outflow' ],
    keys: ['created', 'user_name', 'account_name', 'narration', 'charges', 'status', 'credit', 'debit'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Check Status',
      color: 'green',
      action: async (id) => {
        let index = walletHistory.data.findIndex((item) => item.id === id);
        await checkStatus(walletHistory.data[index].id)
      },
    },
  ];

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const statusConfig = [
    {
        label: 'Processing',
        color: 'orange',
        value: 0,
    },
    {
        label: 'Successful',
        color: 'green',
        value: 1,
    },
    {
        label: 'Failed',
        color: 'red',
        value: 2,
    },
    {
      label: 'Retried: Failed',
      color: 'red',
      value: 3,
    },
  ];

  const typeConfig = [
    {
        label: 'Deposit',
        color: 'green',
        value: 'wallet',
    },
    {
        label: 'Disbursements',
        color: 'orange',
        value: 'disburse',
    },
  ];

  

  return (
    <DashboardLayout title='Transactions'>
      <div className='flex flex-col bg-white'>
        <div className='flex flex-col'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Transactions History
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={walletHistory}
            tableConfig={tableConfig}
            noCheck={true}
            filterByStatus={false}
            filterByType={false}
            actionConfig={actionConfig}
            typeConfig={typeConfig}
            filterByDate={false}
            statusConfig={statusConfig}
            updatePagination={(data) => setPagination(data)}
            computedActions={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MobileTransactionsPage;
