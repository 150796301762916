import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddReimbursement, useEditReimbursement } from '../../../redux/employee/hook/reimbursements';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employee/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';

function AddReImbursement({ isOpen, closeModal, refresh, reimbData, title, buttonText }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync, isLoading: loading } = useAddReimbursement();
  const { mutateAsync: addReimbursement, isLoading: addReimbloading } = useAddReimbursement();
  const { mutateAsync: editReimbursement, isLoading: editReimbloading } = useEditReimbursement();

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState()
  const [purposeEdit, setPurposeEdit] = useState()
  const [reimbId, setReimbId] = useState()
  const [reimbType, setReimbType] = useState()
  const [type, setType] = useState(1);

  useEffect(() => {
    if (reimbData) {
      setReimbType('edit')
      console.log(reimbData)
      setMonth(reimbData.month?((reimbData.month).split('-')[1]).toString():'')
      setYear(reimbData.month?((reimbData.month).split('-')[0]).toString()+'-':'')
      setAmountEdit(parseFloat(reimbData.expenses_amount).toFixed(2))
      setPurposeEdit(reimbData.expenses_description)
      setEmployee(reimbData.employee)
      setEmployeeName(reimbData.first_name + " " + reimbData.last_name)
      setReimbId(reimbData.id)
      setType(reimbData.type)
      
      
    } else {
      setReimbType('add')
      setMonth('')
      setYear('')
    }
  }, [reimbData]);



  const submitForm = async (data) => {
    const expenses_amount = DOMPurify.sanitize(data?.amount);
    const expenses_description = DOMPurify.sanitize(data?.expenses_description);

    if (reimbType === 'add') {
      let payload = {
        employee,
        expenses_amount,
        expenses_description,
        month: year + month,
        type
      };
      await addReimbursement(payload).then(() => {
        closeModal();
      });
    } else if (reimbType === 'edit') {
      let editPayload = {
        id: reimbId,
        body: {
          employee,
          expenses_amount: amountEdit,
          expenses_description: purposeEdit,
          month: year + month,
          type
        }
      }
      await editReimbursement(editPayload).then(() => {
        closeModal();
      });
    }
  };

  const typeOptions = [
    {
      value: 1,
      label: 'One Time',
    },
    {
      value: 2,
      label: 'recurring',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>{title}</span>
              <hr className='divider' />
            </div>

            <div>
              <FormSelectEmployee
                label={'Employee'}
                name='employee'
                placeholder='Select Employee'
                value={employee}
                readOnly={reimbType==='edit'? true : false}
                empName={employeeName}
                setEmployee={setEmployee}
              />

              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  <FormSelect
                    defaultValue={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Select Month'
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    defaultValue={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Select Year'
                  />
                </div>
              </div>

              

              <FormInput
                label='Expenses Description'
                name='expenses_description'
                type='text'
                inputRef={register(formValidation('text', true))}
                readOnly={addReimbloading || editReimbloading}
                defaultValue={purposeEdit}
                onChange={(e) => {
                  setPurposeEdit(e.target.value);
                }}
                error={errors.expenses_description}
                errorMessage={
                  errors.expenses_description &&
                  errors.expenses_description.message
                }
              />
              <FormInput
                label='Expenses Amount'
                name='amount'
                type='number'
                inputRef={register(formValidation('number', true))}
                readOnly={addReimbloading || editReimbloading}
                defaultValue={amountEdit}
                onChange={(e) => {
                  setAmountEdit(e.target.value);
                }}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
              />

              <FormSelect
                defaultValue={type}
                options={typeOptions}
                onChange={(selected) => {
                  setType(selected);
                }}
                label='Payment Status'
              />
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'

                disabled={
                  reimbType==='edit'? false:
                  !isValid || !employee? true:
                  false
                }
                loading={addReimbloading || editReimbloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddReImbursement;
