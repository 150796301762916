import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { employeeGroups } from '../../../api/employees/groups';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetEmployeeGroups(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getEmployeeGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((group) => {
          if (group && group.description && group.description.length >= 30) {
            group.description = group.description.substring(0, 30) + '....';
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.addEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.editEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        queryClient.invalidateQueries('employee-group');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOnboardingEmployeeGroups(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-onboarding-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getEmployeeGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'Select Group' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useGetEmployeeGroup(id, limit, page, statusFilter, search) {
  return useQuery(
    ['employee-group', { id, limit, page, statusFilter, search }],
    () => {
      return employeeGroups.getEmployeeGroup({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.status = 1
        data?.employees?.data?.forEach((employee) => {
          employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
        });

        return data;
      },
      
    },
  );
}
export function useGetGroupEmployees(id, limit, page, statusFilter, search) {
  return useQuery(
    ['group-employees', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getGroupEmployees({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      
    },
  );
}

export function useGetGroupSettings(id, limit, page, statusFilter, search) {
  return useQuery(
    ['group-settings', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getGroupSettings({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.setting.id = data?.id
        return data?.setting;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      
    },
  );
}

export function useAddEmployeesToGroup() {
  return useMutation(
    (payload) => {
      return employeeGroups.addEmployeesToGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useRemoveEmployeesFromGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.removeEmployeesFromGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}