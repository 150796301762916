import { Axios } from '../axios';

const getEmployeeSalaryAdvance = async (payload) => {
  const { data } = await Axios.get(
    `/salary-advance?limit=${payload.limit}&page=${payload.page}`,
  );

  return data?.data;
};

const addSalaryAdvance = async (payload) => {
  const { data } = await Axios.post('/salary-advance', payload);
  return data;
};

const editSalaryAdvance = async (payload) => {
  const { data } = await Axios.put('/salary-advance/'+payload.id, payload.body);
  return data;
};

const bulkAddSalaryAdvance = async (payload) => {
  const { data } = await Axios.post('/salary-advance/bulk', payload);
  return data;
};

const getSingleEmployeeSalaryAdvances = async (payload) => {

  const { data } = await Axios.get(
    `/salary-advance/employee/` + payload,
  );
  return data?.data;
};

export const employeeSalaryAdvance = {
  getEmployeeSalaryAdvance,
  addSalaryAdvance,
  bulkAddSalaryAdvance,
  getSingleEmployeeSalaryAdvances,
  editSalaryAdvance
};
