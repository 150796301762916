import React, { useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import TabView from '../../components/tabview';
import PayeTaxTab from '../../components/statutory-complaince/paye-tax'; 
import PensionTab from '../../components/statutory-complaince/pension';
import NsitfTab from '../../components/statutory-complaince/nsitf';
import NhisTab from '../../components/statutory-complaince/nhis';
import NhfTab from '../../components/statutory-complaince/nhf';
import ItfTab from '../../components/statutory-complaince/itf';


const StatutoryPaymentPage = () => {
  const [activeTab, setActiveTab] = useState(1);

  const StatutoryTabs = [
    {
      title: 'Pension',
      Content: PensionTab,
    },
    {
      title: 'PAYE Tax',
      Content: PayeTaxTab,
    },
    {
      title: 'NHF',
      Content: NhfTab,
    },
    {
      title: 'NHIS',
      Content: NhisTab,
    },
    {
      title: 'NSITF',
      Content: NsitfTab,
    },
    {
      title: 'ITF',
      Content: ItfTab,
    },
  ];

  return (
    <DashboardLayout title='Statutory Compliance'>
        <div>
          <TabView
            Tabs={StatutoryTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
    </DashboardLayout>
  );
};

export default StatutoryPaymentPage;

