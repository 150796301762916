import { Axios } from '../axios';

const getAdvancedPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/advanced-payrolls?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getBasicPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/basic-payrolls?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const fetchBasicPayrollByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/basic-payrolls/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data.salaries;
};

const fetchAdvancedPayrollByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/advanced-payrolls/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data.salaries;
};

const runBasicPayroll = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run', payload);
  return data;
};

const runBasicPayrollGroup = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/group', payload);
  return data;
};

const runBasicPayrollSelective = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/selective', payload);
  return data;
};

const getPayrollHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let start_date =
    payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,
  );

  return data?.data;
};

const runBasicPayrollExcel = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/excel', payload);
  return data;
};

const advancedPayrollCheck = async () => {
  const { data } = await Axios.get('/advanced-payroll/check');
  return data?.data;
};


const advancedPayrollCheckGroup = async (payload) => {
  const { data } = await Axios.get('/advanced-payroll/check/group/'+ payload);
  return data?.data;
};

const advancedPayrollCheckSelective = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/selective', payload);
  return data?.data;
};


const runAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run', payload);
  return data;
};

const runAdvancedPayrollGroup = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run/group', payload);
  return data;
};

const runAdvancedPayrollSelective = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run/selective', payload);
  return data;
};

const disburseBasicPayroll = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/disburse', payload);
  return data;
};
const disburseAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/disburse', payload);
  return data;
};

const checkTransactionStatus = async (payload) => {
  const { data } = await Axios.get('/transactions/status/'+payload);
  return data?.data;
};

const recalculateAdvancedPayroll = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/${payload}/recalculate`);
  return data;
};

export const payroll = {
  getAdvancedPayrolls,
  getBasicPayrolls,
  runBasicPayrollGroup,
  runBasicPayrollSelective,
  fetchBasicPayrollByRef,
  getPayrollHistory,
  fetchAdvancedPayrollByRef,
  runBasicPayroll,
  runBasicPayrollExcel,
  advancedPayrollCheck,
  advancedPayrollCheckGroup,
  advancedPayrollCheckSelective,
  runAdvancedPayroll,
  runAdvancedPayrollGroup,
  runAdvancedPayrollSelective,
  disburseBasicPayroll,
  disburseAdvancedPayroll,
  checkTransactionStatus,
  recalculateAdvancedPayroll
};
