import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeSalaries } from './../../../api/employees/salaries';

export function useGetEmployeeSalaries(limit, page, statusFilter, search) {
  const formatter = new Intl.NumberFormat('en-NG');
  return useQuery(
    ['employee-salaries', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getEmployeeSalaries({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status);

          employee.gross_pay =
            parseFloat(employee.basic_salary ? employee.basic_salary : 0) +
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0,
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.housing_allowance ? employee.housing_allowance : 0,
            ) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0,
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0,
            ) +
            parseFloat(
              employee.transportation_allowance
                ? employee.transportation_allowance
                : 0,
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0,
            ) +
            parseFloat(employee.other_allowance ? employee.other_allowance : 0);

          employee.other_accum_allowance =
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0,
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0,
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0,
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0,
            ) +
            parseFloat(employee.other_allowance ? employee.other_allowance : 0);

          employee.other_accum_allowance = parseFloat(
            employee.other_accum_allowance,
          ).toFixed(2);
          employee.basic_salary = parseFloat(employee.basic_salary).toFixed(2);
          employee.transportation_allowance = parseFloat(
            employee.transportation_allowance,
          ).toFixed(2);
          employee.housing_allowance = parseFloat(
            employee.housing_allowance,
          ).toFixed(2);
          employee.gross_pay = parseFloat(employee.gross_pay).toFixed(2);

          //compute action items
          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1];
              break;
            case 1:
              employee.computedActions = [0, 1];
              break;
            case 2:
              employee.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddSalary() {
  return useMutation(
    (payload) => {
      return employeeSalaries.addSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salaries');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useGetEmployeeSalary(id) {
  return useQuery(
    ['salary', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getEmployeeSalary(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetSalaryByEmployee(id) {
  return useQuery(
    ['employee-salary', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getSalaryByEmployee(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditSalary() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.editSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salaries');
        queryClient.invalidateQueries('employee-salary');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
