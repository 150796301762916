import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { employeeSalaryAdvance } from '../../../api/employees/salary-advance';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetEmployeeSalaryAdvance(limit, page) {
  return useQuery(
    ['employee-salary-advances', { limit, page }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getEmployeeSalaryAdvance({
        limit,
        page,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.created = employee.created?.split('T')[0];
          employee.amount =  parseFloat(employee.amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddSalaryAdvance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.addSalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditSalaryAdvance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.editSalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddSalaryAdvance() {
  return useMutation(
    (payload) => {
      return employeeSalaryAdvance.bulkAddSalaryAdvance(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
    },
  );
}

export function useSingleGetEmployeeSalaryAdvances(id) {
  return useQuery(
    ['single-employee-salary-advanced', id ],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getSingleEmployeeSalaryAdvances(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
