import { companies } from '../../api';
import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';

export function useGetCompanies(limit, page, start_date, end_date, search) {
  return useQuery(
    ['companies', { limit, page, start_date, end_date,search }],
    () => {
      store.dispatch(setIsLoading(true));
      return companies.getCompanies({ limit, page, start_date, end_date,search });
    },
    {
      select: (data) => {
        data?.data?.forEach((company) => {
          company.created = company.created.split('T').join(' ').split('.')[0]
          company.computedActions = [0]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useChargeCompany() {
  return useMutation(
    (payload) => {
      return companies.chargeCompany(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );

}

export function useRefundCompanies() {
  return useMutation(
    () => {
      return companies.refundCompanies();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );

}

export function usePayPensions() {
  return useMutation(
    () => {
      return companies.payPensions();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Pensions paid successfully'));
      },
    },
  );

}
export function usePayTaxes() {
  return useMutation(
    () => {
      return companies.payTaxes();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Taxes paid successfully'));
      },
    },
  );

}


export function useGetSelectiveCompany(limit, page, search) {
  return useQuery(
    ['selective-companies', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return companies.getCompanies({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.company_name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}




