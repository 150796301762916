import React from "react";
import Sidebar from "../sidebar";
import { OffcanvasBody, Offcanvas } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProfileMenu from "../profile-menu";
import MenuSVG from "../../assets/svg/menu.svg";
import { toggleSidebar } from "../../redux/components/components-slice";

const DashboardLayout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector((state) => state.componentsSlice);
  const sidebarWidth = "255px";

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      <div>
        <Offcanvas
          toggle={() => handleSidebarToggle()}
          isOpen={isSidebarOpen}
          style={{ width: sidebarWidth }}
        >
          <OffcanvasBody className="!p-0">
            <Sidebar />
          </OffcanvasBody>
        </Offcanvas>
      </div>

      <div className="flex flex-row max-h-screen">
        <div
          className="hidden lg:flex !min-w-[255px] h-screen inset-0"
        >
          <Sidebar />
        </div>
        <div className="w-full pb-[50px] flex flex-col bg-secondary-2-light overflow-auto">
          <div className="!opacity-100">
            <div className="ml-0 md:ml-1 bg-white h-[65px] w-full flex items-center justify-between pl-4 md:pl:0 md:px-[30px]">
              <div className="flex flex-row">
                <button onClick={() => handleSidebarToggle()}>
                  <img src={MenuSVG} alt="menu" className="flex lg:hidden" />
                </button>
                <span className="hidden md:block header-4 text-secondary-2 ml-3">
                  {title}
                </span>
              </div>
              <div className="flex flex-row -mr-3">
                <div className="px-[40px] w-[270px] pt-2"></div>
                <div className="hidden md:flex">
                  <ProfileMenu />
                </div>
              </div>
            </div>
            {/* Page Component */}
            <div className="w-full h-full p-8">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
