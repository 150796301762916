import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import { useBulkAddReimbursement } from '../../../redux/employee/hook/reimbursements';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormFileInput from '../../form-file-input';
import CloseSVG from '../../../assets/svg/close.svg';
import FormSelect from '../../form-select';
import FormOtpInput from '../../form-otp-input';

function TaxOtpVerification({ isOpen, closeModal, refresh }) {


  const [verificationType, setVerificationType] = useState('email');

  const [otp, setOtp] = useState('');

  // const { mutateAsync, isLoading } = useBulkAddReimbursement();

  // async function submitForm(data) {
  //   const formData = new FormData();
  //   formData.append('file', data?.file[0]);

  //   await mutateAsync(formData).then(() => {
  //     closeModal();
  //   });
  // }

  const verificationTypeOptions = [
    {
      label: 'Phone number',
      value: 'mobile',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='flex justify-end p-3'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='' />
            </div>
        </div>
        <div className='flex flex-col mx-8'>
          <div>
            <span className='header-3'>Tax Payer Confirmation</span>
            <hr className='divider'/>
          </div>
          <div className='flex justify-center mt-3'>
            <div className='w-[100%] text-center'>
              <span className='header-4-regular text-secondary-2'>
                An OTP has been sent to your email with your tax authority.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='p-8 flex flex-col px-[60px]'>
        <div className='flex w-[100%] justify-center'>
          <FormOtpInput
            value={otp}
            valueLength={6}
            onChange={(value) => setOtp(value)}
            label='Enter OTP'
          />
        </div>

        <div className='w-full mt-[40px]'>
          <Button
            text='CONTINUE'
            type='submit'
            // loading={isLoading}
            onClick={() => closeModal()}
          />
        </div>
      </div>
    </Modal>
  );
}

export default TaxOtpVerification;
