import { Axios } from './axios';

const getUsers = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let start_date =
    payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  const { data } = await Axios.get(`/users?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}`);
  return data?.data;
};

const activateDeactivateUser = async (payload) => {
  const { data } = await Axios.get(
    `/users/${payload}/activation`
  );
  return data;
}

export const user = {
  getUsers,
  activateDeactivateUser
};
