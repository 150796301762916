import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { itfApis } from './../../../api/payroll/statutory-compliance/itf';

export function useGetItfHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['itf', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return itfApis.getITF({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((itf) => {
          itf.status = parseFloat(itf.itf_status)
          if (itf.status === 2) {
            itf.status = 0
          }
          switch (itf.status) {
            case 0:
              itf.computedActions = [0];
              break;
            case 1:
              itf.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadItf() {
  return useMutation(
    (payload) => {
      return itfApis.bulkITF(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('itf');
        store.dispatch(setAlert(true, 'success', 'Upload Successful'));
      },
    },
  );
}

export function useRefITF(payload) {
  return useQuery(
    ['itf-by-ref', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return itfApis.fetchITFByRef(payload);
    },
    {
      select: (data) => {
        // data.data.salaries.data.forEach((item) => {
        //   item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
        //   item.net_pay = item.net_pay.toFixed(2);
        //   item.total = (
        //     parseFloat(item.trans_cost) + parseFloat(item.net_pay)
        //   ).toFixed(2);
        // });

        // return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}