import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { taxApis } from './../../../api/payroll/statutory-compliance/tax';

export function useGetTaxHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['tax', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return taxApis.getTaxes({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((tax) => {
          tax.status = parseFloat(tax.tax_status)
          if (tax.status === 2) {
            tax.status = 0
          }
          switch (tax.status) {
            case 0:
              tax.computedActions = [0];
              break;
            case 1:
              tax.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadTax() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.bulkTaxes(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateTaxOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.generateTaxOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefTax(payload) {
  return useQuery(
    ['tax-by-ref', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return taxApis.fetchTaxByRef(payload);
    },
    {
      select: (data) => {
        // data.data.salaries.data.forEach((item) => {
        //   item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
        //   item.net_pay = item.net_pay.toFixed(2);
        //   item.total = (
        //     parseFloat(item.trans_cost) + parseFloat(item.net_pay)
        //   ).toFixed(2);
        // });

        // return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
