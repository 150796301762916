import React from 'react';
import { useState } from 'react';
import AddAdminInfo from '../modal/settings/add-admin-info';
import Button from '../button';
import DataTable from '../datatable';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useDeleteAdmin, useGetAdmins } from '../../redux/admin/hook';

const AdminManagement = () => {

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: admins } = useGetAdmins(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const tableConfig = {
    //title: 'Basic Payroll Summary',
    headers: [
      'Created',
      'First Name',
      'Last Name',
      'Email',
      'Mobile',
    ],
    keys: ['created', 'first_name','last_name', 'email', 'mobile'],
    mobileHeader: {
      left: {
        title: 'First Name',
        key: 'first_name',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  const {mutateAsync: deleteAdmin} = useDeleteAdmin();

  const actionConfig = [
    {
      name: 'Remove Admin',
      color: 'green',
      action: (id) => {
        let index = admins.data.findIndex((item) => item.id === id);
        deleteAdmin(admins.data[index].id)
      },
    },
  ];

  const [showAdminInfo, setShowAdminInfo] = useState(false);

  return (
    <React.Fragment>
      {showAdminInfo ? (
        <AddAdminInfo
          isOpen={showAdminInfo}
          closeModal={() => setShowAdminInfo(false)}
        />
      ) 
      : 
      null}
      <div className='flex flex-col bg-white w-full box-shadow h-full min-h-fit'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Admins
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
            <div className='md:w-fit w-[100%]'>
              <Button
                text='Add New Admin'
                type='button'
                className='!h-[42px] px-3'
                textClass='!header-5'
                onClick={() => {setShowAdminInfo(true)}}
              />
            </div>
          </div>   
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={admins}
          tableConfig={tableConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          noCheck={true}
          actionConfig={actionConfig}
          computedActions={true}
        />
        
      </div>
    </React.Fragment>
  );
};

export default AdminManagement;
