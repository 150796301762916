import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddPeoplePlan, useUpdatePeoplePlan } from '../../../redux/hr-subscription/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormTextArea from '../../form-textarea';
import FormSwitch from '../../form-switch';

function AddPeoplePlan({ isOpen, closeModal, title, buttonText, planData }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  
  const { mutateAsync: addPeoplePlan, isLoading: addPlanLoading } = useAddPeoplePlan();
  const { mutateAsync: updatePeoplePlan, isLoading: updatePlanLoading } = useUpdatePeoplePlan();

  const [accepts_extra_employees, setCanAddEmployees] = useState(false)

  const submitForm = async (data) => {
    const package_name = DOMPurify.sanitize(data?.package_name);
    const description = DOMPurify.sanitize(data?.description);
    const min_employees = DOMPurify.sanitize(data?.min_employees);
    const max_employees = DOMPurify.sanitize(data?.max_employees);

    let payload = {
        package_name,
        description,
        min_employees,
        max_employees,
        accepts_extra_employees: accepts_extra_employees ? 1 : 0
    };
    if (!planData) {
        addPeoplePlan(payload).then(() => {
            closeModal();
        });
    } else {
        let editPayload = {
            id: planData.id,
            data: payload,
        }
        updatePeoplePlan(editPayload).then(() => {
            closeModal();
        });
    }
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-8'>
              <div className='flex flex-row justify-between'>
                <span className='header-3'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
              <div className='flex flex-col md:flex-col w-full justify-between'>
                <FormInput
                    defaultValue={planData?.package_name}
                    label='Package Name'
                    name='package_name'
                    placeholder={`Enter Package Name`}
                    type='text'
                    inputRef={register(formValidation('text', true))}
                    error={errors.package_name}
                    errorMessage={
                        errors.package_name && errors.package_name.message
                    }
                />
                <FormTextArea
                    defaultValue={planData?.description}
                    label='Description'
                    name='description'
                    placeholder={`Enter Subscription Plan Description`}
                    type='text'
                    inputRef={register(formValidation('text', true))}
                    error={errors.description}
                    errorMessage={errors.description && errors.description.message}
                />
                <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:w-1/2 pr-2'>
                        <FormInput
                            defaultValue={planData?.min_employees}
                            label='Minimum Employees'
                            name='min_employees'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            error={errors.min_employees}
                            errorMessage={errors.min_employees && errors.min_employees.message}
                        />
                    </div>

                    <div className='w-full md:w-1/2 pl-2'>
                        <FormInput
                            defaultValue={planData?.max_employees}
                            label='Maximum Employees'
                            name='max_employees'
                            type='number'
                            inputRef={register(formValidation('number'))}
                            error={errors.max_employees}
                            errorMessage={errors.max_employees && errors.max_employees.message}
                        />
                    </div>
                </div>
                <div className={'flex w-full flex-col mt-2'}>
                    <FormSwitch
                        label={'Accept Extra Employees?'}
                        name={accepts_extra_employees}
                        checked={accepts_extra_employees}
                        onClick={() =>
                            setCanAddEmployees(!accepts_extra_employees)
                        }
                        value={accepts_extra_employees}
                    />
                  </div>
              </div>

              <div className='w-full mt-2'>
                <Button text={buttonText} type='submit' disabled={!isValid} loading={addPlanLoading || updatePlanLoading}/>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddPeoplePlan;
