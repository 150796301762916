import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useGetAdmins } from '../../../redux/admin/hook';
import { useAddBonus } from '../../../redux/employee/hook/bonuses';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormDatalist from '../../form-datalist';
import FormInput from '../../form-input';
import TaxOtpVerification from './tax-otp-verification';


function CompanyTaxIdVerification({ isOpen, closeModal, refresh, taxRef, taxId  }) {

    // const {
    //     register,
    //     handleSubmit,
    //     formState: { isValid },
    //     errors,
    // } = useForm({
    //     mode: 'onChange',
    // });

  const { data: admins } = useGetAdmins();
  

  const [showCompanyOtpVerification, setShowCompanyOtpVerification] = useState(false)


//   useEffect(() => { 
//     console.log(admins, 'admins');

//   }, [admins])

//     const submitForm = async (data) => {
//         // await mutateAsync().then(() => {
//         // closeModal();
//         // });
//     };

  
  return (
    <>
    {showCompanyOtpVerification
      ?
      <TaxOtpVerification 
        isOpen={showCompanyOtpVerification}
        closeModal={() => setShowCompanyOtpVerification(false)}
      />
      :
      null
    }
    <Modal
        scrollable={true}
        className='!max-w-[500px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
        <div className='flex flex-col overflow-auto'>
            <div className='flex justify-end'>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
                </div>
            </div>
            <div className='flex flex-col mx-8'>
                <div>
                    <span className='header-3'>Company Tax Information</span>
                    <hr className='divider'/>
                </div>
                <div className='flex flex-col md:flex-col w-full justify-between'>
                <FormInput
                    label='Enter Your Company Tax ID'
                    name='tax_id'
                    type='text'
                />
                </div>

                <div className='w-full mt-[10px] pb-[20px]'>
                <Button
                    text='GENERATE OTP'
                    type='submit'
                    // disabled={!isValid}
                    // loading={loading}
                    onClick={() => {
                        setShowCompanyOtpVerification(true)
                    }}
                />
                </div>
            </div>
        </div>
    </Modal>
    </>
    
  );
}

export default CompanyTaxIdVerification;

