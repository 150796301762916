import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { hr_subscription } from '../../api/hr-subscription';
import { queryClient } from '../..';

export function useGetPeoplePlans(limit, page, search) {
  return useQuery(
    ['people-plans', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return hr_subscription.getPeoplePlans({ limit, page, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((company) => {
          company.created = company.created.split('T').join(' ').split('.')[0]
          company.computedActions = [0,1]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetPeoplePlansForPayments(limit, page, search) {
    return useQuery(
      ['set-people-plans', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getPeoplePlans({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          let newData = [];
          newData.push({ value: '', label: 'People Plan Selected' });
          data?.data?.forEach((item) => {
            newData.push({ value: item.id, label: item.package_name });
          });
          return newData;
        },
  
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
  }

export function useAddPeoplePlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.addPeoplePlan(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('people-plans');
            store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useUpdatePeoplePlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.updatePeoplePlan(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('people-plans');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useDeletePeoplePlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.removePeoplePlan(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('people-plans');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useGetPeoplePaymentPlans(limit, page, search) {
    return useQuery(
      ['people-payment-plans', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getPeoplePaymentPlans({ limit, page, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((company) => {
            company.created = company.created.split('T').join(' ').split('.')[0]
            company.computedActions = [0,1]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddPeoplePaymentPlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.addPeoplePaymentPlans(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('people-payment-plans');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useUpdatePeoplePaymentPlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.updatePeoplePaymentPlans(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('people-payment-plans');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useDeletePeoplePaymentPlan() {
    return useMutation(
      (payload) => {
        return hr_subscription.removePeoplePaymentPlans(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('people-payment-plans');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useGetCurrentPeoplePlans(limit, page, search) {
    return useQuery(
      ['current-people-plans', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getCurrentPeoplePlans({ limit, page, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((company) => {
            company.created = company.created.split('T').join(' ').split('.')[0]
            company.computedActions = [0]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetSubscriptionHistory(limit, page, search) {
    return useQuery(
      ['subscrption-history', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getSubscriptionHistory({ limit, page, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((company) => {
            company.created = company.created.split('T').join(' ').split('.')[0]
            company.computedActions = [0]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}


