import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from '../datatable';
import { StatutoryConfig, PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetTaxHistory } from '../../redux/statutory-compliance/hook/tax';
import { useSelector } from 'react-redux';
import CompanyTaxIdVerification from '../modal/statutory-compliance/company-tax-verification';
import TaxOtpVerification from '../modal/statutory-compliance/tax-otp-verification';

const PayeTaxTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    
  });

  const { company } = useSelector((state) => state.companySlice);
  const [showCompanyTaxIdVerification, setShowCompanyTaxIdVerification] = useState(false)
  
  const [taxRef, setTaxRef] = useState('')
  const [taxId, setTaxId] = useState('')

  const { data: Taxes } = useGetTaxHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,

  );

  useEffect(() => {
    if (Taxes) {
      console.log(Taxes, 'paye');
    }
  }, [Taxes]);
  useEffect(() => {
    if (company) {
      console.log(company, 'company');
    }
  }, [company]);

  const history = useHistory();

  function Continue(id, type) {
    let index = Taxes.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === 'preview') {
        if (company.tax_id && company.tax_token) {
          history.push(
            `/statutory-deductions/taxes/preview/${Taxes.data[index].ref}`,
          );
        } else  {
          setTaxId(company.tax_id)
          setTaxRef(Taxes.data[index].ref)
          setShowCompanyTaxIdVerification(true)
        }
      } else if (type === 'view') {
        history.push(`/tax/advanced/view/${Taxes.data[index].ref}`);
      }
    }
  }

  const tableConfig = {
    title: 'PAYE Tax',
    headers: ['Date', 'Reference Number', 'Description', 'Month', 'Status'],
    keys: ['created', 'ref', 'desc', 'month', 'status'],
    mobileHeader: {
      left: {
        title: 'Date',
        key: 'created',
      },
      right: {
        title: 'Description',
        key: 'desc',
      },
    },
  };

  const statusConfig = [
    {
      label: 'In Progress',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Successful',
      color: 'green',
      value: 1,
    },
    
  ];

  const actionConfig = [
    {
      name: 'Pay Now',
      color: 'orange',
      action: (id) => {
        Continue(id, 'preview');
      },
    },
    // {
    //   name: 'View',
    //   color: 'green',
    //   action: (id) => {
    //     Continue(id, 'view');
    //   },
    // },
  ];

  return (
    <div>
      {showCompanyTaxIdVerification
      ?
      <CompanyTaxIdVerification 
        isOpen={showCompanyTaxIdVerification}
        closeModal={() => setShowCompanyTaxIdVerification(false)}
        taxRef={taxRef}
        taxId={taxId}
      />
      :
      null
      }

      
      
      <DataTable
        data={Taxes}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={StatutoryConfig}
        searchData={true}
        computedActions={true} 
      />
    </div>
  );
};
export default PayeTaxTab;