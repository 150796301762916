import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeReimbursement } from './../../../api/employees/reimbursements';

export function useGetEmployeeReimbursements(limit, page, search) {
  return useQuery(
    ['employee-reimbursements', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getEmployeeReimbursements({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.expenses_amount =  parseFloat(employee.expenses_amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = employee.paid_once? parseInt(employee.paid_once) : 2
          employee.created = employee.created?.split('T')[0];

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
          }

          // if (employee.paid_once == '1') {
          //   employee.computedActions = [0];
          // } else if (employee.paid_once == '2') {
            employee.computedActions = [0, 1];
          // }
        });

        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.AddReImbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.editReImbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.bulkAddReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeReimbursements(id) {
  return useQuery(
    ['single-employee-reimbursements', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getSingleEmployeeReimbursements({ id });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.expenses_amount =  parseFloat(employee.expenses_amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = employee.paid_once? parseInt(employee.paid_once) : 2

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
          }

          // if (employee.paid_once == '1') {
          //   employee.computedActions = [0];
          // } else if (employee.paid_once == '2') {
            employee.computedActions = [0, 1];
          // }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
