import React, { useEffect } from 'react';
import { useState } from 'react';
import PayrollPlans from './payroll-plans';
import PeoplePlans from './people-plans';
import TabView from '../tabview';
import SubscriptionHistory from './subscription-history';
import PeoplePaymentPlans from './subscription-payment-plan';
import CurrentPeoplePlans from './current-subscriptions';

const PlansAndBillings = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [activeTab, setActiveTab] = useState(1);

  const EmployeeTabs = [
    {
      title: 'Payroll Plans',
      Content: PayrollPlans,
    },
    {
      title: 'People Plans',
      Content: PeoplePlans,
    },
    {
      title: 'People Payment Plans',
      Content: PeoplePaymentPlans,
    },
    {
      title: 'Current HR Subscriptions',
      Content: CurrentPeoplePlans,
    },
    {
      title: 'Subscription History',
      Content: SubscriptionHistory,
    },
  ];

  return (
    <React.Fragment>
      <div className='flex flex-col bg-white w-full box-shadow pl-8 py-3 h-fit min-h-fit'>
        <span className='header-4'>Plans and Settings</span>
        <span className='p4 text-color-gray pt-1'>
          Choose the plan that is right for your business
        </span>
        <hr className='divider' />
        <div className=' pr-8'>
          <TabView
            Tabs={EmployeeTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlansAndBillings;
