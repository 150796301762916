import { store } from '..';
import { setIsLoading } from '../components/components-slice';
import { useQuery } from '@tanstack/react-query';
import { mobile_user } from '../../api/mobile-user';

export function useGetMobileUsers(limit, page, search) {
  return useQuery(
    ['mobile-users', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return mobile_user.getMobileUsers({ limit, page, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((user) => {
          user.created = user.created.split('T').join(' ').split('.')[0]
          user.computedActions = [0, 1]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

