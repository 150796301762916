import React, { useState } from "react";
import FormInput from "./form-input";
import SearchSVG from "../assets/svg/search.svg";
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DropDownSVG from "../assets/svg/chevron-down.svg";
import classNames from "classnames";
import { useEffect } from "react";
import TableActionIconSVG from "../assets/svg/table-action-icon.svg";
import FormSelect from "./form-select";
import LoadingBar from "./loader-bar";
import { useSelector } from "react-redux";
import { isLoading, setAlert } from "../redux/components/components-slice";
import { PAGINATION_DEFAULT } from "../utils/constants";
import { StatusText } from "./fragments/status-text";
import FormDateRangePicker from "./form-date-range-picker";
import moment from "moment";
import RefreshSVG from "../assets/svg/refresh.svg";
import { useCheckBulkTransactionStatus } from "../redux/payroll/hook";
import { useForm } from "react-hook-form";
import Button from "./button";
import EmptyTableSVG from "../assets/svg/empty-table.svg";
import { store } from "../redux";

const DataTable = ({
  tableConfig,
  statusConfig,
  typeConfig,
  actionConfig,
  data,
  bulkActionConfig,
  filterByDate,
  updatePagination,
  computedActions,
  filterByStatus,
  filterByType = false,
  searchData,
  refresh = false,
  noCheck = false,
  attendance = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const loading = useSelector(isLoading);

  const [mobileCollapseView, setMobileCollapseView] = useState([]);
  const [limit, setLimit] = useState(PAGINATION_DEFAULT.limit);
  const [page, setPage] = useState(PAGINATION_DEFAULT.page);
  const [gotoPage, setGotoPage] = useState(page);
  const [search, setSearch] = useState("");
  const [source, setSource] = useState("");

  const [preSearch, setPreSearch] = useState("");

  const [source_id, setSource_id] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [start_date, setStartDate] = useState(
    attendance
      ? moment().format("YYYY-MM-DD")
      : moment().subtract(3, "month").format("YYYY-MM-DD")
  );
  const [end_date, setEndDate] = useState(
    attendance
      ? moment().add(3, "month").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );
  const [selectedBulkAction, setSelectBulkAction] = useState("none");
  const [bulkOptions, setBuckOptions] = useState([]);
  const [statusFilter, setStatusFilter] = useState(
    PAGINATION_DEFAULT.statusFilter
  );
  const [type, setType] = useState("");

  const { mutateAsync: refreshTransactionStatus, isLoading: refreshLoading } =
    useCheckBulkTransactionStatus();

  useEffect(() => {
    if (bulkActionConfig) {
      setBuckOptions([
        {
          label: "Bulk Action",
          value: "none",
        },
        ...bulkActionConfig,
      ]);
    }
  }, [bulkActionConfig]);

  const pageLimitOptions = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];

  const paginationBtnClass =
    "w-[30px] h-[30px] rounded border border-secondary-2 mx-1 pr-2 pl-2 min-w-fit";

  useEffect(() => {
    paginationFn();
  }, [
    limit,
    page,
    statusFilter,
    search,
    start_date,
    end_date,
    type,
    source,
    source_id,
  ]);

  const toggleMobileCollapseView = (index) => {
    setMobileCollapseView((current) =>
      current.map((obj, i) => {
        if (index === i) {
          obj = !mobileCollapseView[index];
        }
        return obj;
      })
    );
  };

  useEffect(() => {
    //Set Mobile Collapse State Set
    if (data && data.data && data.data.length > 0) {
      let mobileCollapseViewList = [];

      data.data.forEach((element) => {
        mobileCollapseViewList.push(false);
      });
      setMobileCollapseView(mobileCollapseViewList);
    }
  }, [data]);

  const ActionItem = ({ item, id, sentData }) => {
    return (
      <div
        className="py-2 hover:bg-secondary-2-extralight px-3 pb-1 cursor-pointer"
        onClick={() => {
          item.action(id);
        }}
      >
        <span
          className={classNames(
            {
              "text-color-green-medium": item.color === "green",
            },
            {
              "text-color-orange": item.color === "orange",
            },
            {
              "text-color-error": item.color === "red",
            }
          )}
        >
          {item.name}
        </span>
      </div>
    );
  };

  const Action = ({ data }) => {
    return (
      <>
        {data && computedActions ? (
          <UncontrolledDropdown direction="auto">
            <DropdownToggle className="bg-transparent border-0">
              <img src={TableActionIconSVG} alt="" className="min-w-[38px]" />
            </DropdownToggle>

            <DropdownMenu
              container="body"
              className="!w-[150px] !p-0 !text-[14px]"
              style={{ zIndex: 1090 }}
              width="50px"
            >
              <div className="flex flex-col">
                {actionConfig.map((item, index) => (
                  <>
                    {computedActions ? (
                      <>
                        {data.computedActions ? (
                          <>
                            {data.computedActions.includes(index) ? (
                              <ActionItem item={item} id={data.id} />
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <ActionItem item={item} id={data.id} />
                    )}
                  </>
                ))}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
      </>
    );
  };

  const selectAllItems = () => {
    let selectAll = [];

    if (data && data.data) {
      data.data.forEach((item) => {
        selectAll.push(item.id.toString());
      });
    }

    setSelectedItems(selectAll);
  };

  function paginationFn() {
    if (updatePagination && limit && page) {
      updatePagination({
        source_id,
        source,
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
        type,
      });
    }
  }

  async function bulkAction() {
    if (selectedItems.length === 0) {
      return;
    }

    let data = selectedItems.join();

    var index = bulkOptions.findIndex((p) => p.value == selectedBulkAction);

    if (index !== -1) {
      bulkOptions[index].action(data);
      //reset bulk items
      setSelectedItems([]);
      setSelectBulkAction("none");
    }
  }

  return (
    <React.Fragment>
      {tableConfig ? (
        <div className="bg-white w-full min-h-[160px] flex flex-col">
          <div className="flex flex-col w-[100%] flex-wrap gap-1 md:justify-start justify-center items-center md:flex-row lg:flex-row  pr-4 md:justify-space-evenly items-left md:items-start">
            {/* {tableConfig.title ? (
              <div className="flex flex-row justify-start pl-4 pr-2 md:flex h-fit p3-medium items-center min-w-fit">
                <span className="header-4">{tableConfig.title}</span>
              </div>
            ) : null} */}
            {searchData ? (
              <div className={"flex flex-row items-center pl-4 md:pl-[18px]"}>
                <FormInput
                  name="search"
                  placeholder="Search"
                  type="text"
                  value={preSearch}
                  search={true}
                  onChange={(event) => setPreSearch(event.target.value)}
                  className={
                    "!h-[35px] bg-color-off-white border-0 sm:w-[250px] -mb-[10px] rounded-br-none rounded-tr-none"
                  }
                />
                <button
                  onClick={() => {
                    setSearch(preSearch);
                  }}
                  className={
                    "flex bg-secondary-2 h-[35px] w-[42px] rounded-tr rounded-br rounded-bl-none rounded-tl-none justify-center items-center"
                  }
                >
                  <img src={SearchSVG} alt="" />
                </button>
              </div>
            ) : null}
            {filterByStatus ? (
              <div className="flex flex-row items-center pl-2 md:pl-[18px] -mt-[14px]">
                <div className="min-w-[60px]">
                  <span className="text-[12px] font-bold">Filter By: </span>
                </div>
                <FormSelect
                  value={statusFilter}
                  options={[
                    {
                      value: -1,
                      label: "Status",
                    },
                    ...statusConfig,
                  ]}
                  onChange={(selected) => {
                    setStatusFilter(selected);
                  }}
                  type="default"
                  extraClass="!max-h-[35px] w-fit !min-w-[120px] !min-w-[100px]"
                />
              </div>
            ) : null}
            {filterByType ? (
              <div className="flex flex-row items-center pl-2 md:pl-[18px] -mt-[14px]">
                <div className="mb-2 min-w-[60px] pt-3">
                  <span className="text-[12px] font-bold">Filter By: </span>
                </div>

                <FormSelect
                  value={type}
                  options={[
                    {
                      value: "",
                      label: "Type",
                    },
                    ...typeConfig,
                  ]}
                  onChange={(selected) => {
                    setType(selected);
                  }}
                  type="default"
                  extraClass="!max-h-[35px] !w-full !min-w-[150px]"
                />
              </div>
            ) : null}
            {refresh ? (
              <div
                className="flex flex-row items-center justify-center text-secondary-2 pl-2 md:pl-[10px] p4-bold cursor-pointer hover:underline pt-[10px] duration-500"
                onClick={() => {
                  refreshTransactionStatus();
                }}
              >
                <img
                  src={RefreshSVG}
                  alt={"Refresh"}
                  className={
                    refreshLoading
                      ? "rounded-[50%] h-[35px] w-[35px] pl-[10px] pr-[10px] animate-spin"
                      : "rounded-[50%] h-[35px] w-[35px] pl-[10px] pr-[10px] animate-none"
                  }
                />{" "}
                Refresh
              </div>
            ) : null}
            {bulkActionConfig ? (
              <div className="flex flex-row items-center pl-4">
                <FormSelect
                  value={selectedBulkAction}
                  options={bulkOptions}
                  onChange={(selected) => {
                    setSelectBulkAction(selected);
                  }}
                  type="default"
                  extraClass="!max-h-[35px] !w-[150px] rounded-br-none rounded-tr-none"
                />
                <button
                  className={
                    "h-[35px] bg-secondary-2 px-3 rounded-tr rounded-br rounded-bl-none rounded-tl-none cursor-pointer -mt-3"
                  }
                  onClick={() => bulkAction()}
                  disabled={selectedBulkAction === "none" || loading}
                >
                  <span className="text-white p4">Apply</span>
                </button>
              </div>
            ) : null}
            {filterByDate ? (
              <div className="flex flex-row !w-fit md:justify-end px-3 justify-start items-center p4-medium ">
                <FormDateRangePicker
                  start={start_date}
                  end={end_date}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  extraClass="!h-[35px] border-0 !min-w-fit"
                />
              </div>
            ) : null}
          </div>
          {loading ? <LoadingBar loading={loading} /> : null}
          {/* Desktop Table View */}
          <div className="hidden sm:flex w-full overflow-auto">
            {loading ? (
              <div
                className={
                  "flex flex-row w-[100%] items-center justify-center h-[200px] animate-pulse p1-bold text-primary-2"
                }
              >
                Loading...
              </div>
            ) : (
              <>
                {data && data.data && data.data.length > 0 ? (
                  <table className="w-full">
                    <thead className="bg-secondary-2-extralight p3-bold">
                      <tr>
                        <th className="h-[50px] text-center max-w-[30px]">
                          <div className="flex items-center justify-center pl-4 pr-2">
                            {noCheck ? null : (
                              <FormInput
                                name="select-all"
                                type="checkbox"
                                className="flex items-center self-center mt-3"
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    selectAllItems();
                                  } else {
                                    setSelectedItems([]);
                                  }
                                }}
                              />
                            )}
                          </div>
                        </th>
                        {tableConfig.headers.map((item) => (
                          <th className=" p4-bold text-color-black-3 min-w-[70px]">
                            {item}
                          </th>
                        ))}
                        {computedActions ? (
                          <th className="p3-bold text-color-black-3 min-w-[60px]"></th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.map((dataRow) => (
                        <tr className="hover:bg-secondary-2-extralight">
                          <td className="text-center max-w-[150px]">
                            <div className="flex items-center justify-center pl-4 pr-4 pt-1 ">
                              {noCheck ? null : (
                                <FormInput
                                  name="select"
                                  checked={selectedItems.includes(
                                    dataRow["id"].toString()
                                  )}
                                  onChange={() => {
                                    if (
                                      selectedItems.includes(
                                        dataRow["id"].toString()
                                      )
                                    ) {
                                      setSelectedItems((selectedItems) =>
                                        selectedItems.filter(
                                          (e) => e !== dataRow["id"].toString()
                                        )
                                      );
                                    } else {
                                      setSelectedItems([
                                        ...selectedItems,
                                        dataRow["id"].toString(),
                                      ]);
                                    }
                                  }}
                                  type="checkbox"
                                  className="flex items-center self-center mt-3"
                                />
                              )}
                            </div>
                          </td>

                          {tableConfig.keys.map((key, i) => (
                            <>
                              <td height={"50px"}>
                                {key === "status" ? (
                                  <StatusText
                                    code={dataRow[key]}
                                    statusConfig={statusConfig}
                                  />
                                ) : (
                                  <span className="p5 flex flex-col">
                                    {" "}
                                    {dataRow[key]}
                                  </span>
                                )}
                              </td>
                            </>
                          ))}
                          {dataRow.computedActions ? (
                            <td>
                              <Action data={dataRow} />
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="flex items-center justify-center w-[100%]">
                    <img
                      src={EmptyTableSVG}
                      alt="No Data Fetched"
                      width={"40%"}
                      height={"100px"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {/* Mobile list View */}
          <div className="flex sm:hidden flex-col">
            {data && data.data && data.data.length > 0 ? (
              <div className="h-[60px] flex flex-row bg-secondary-2-extralight px-3 items-center">
                <div className="flex flex-row items-center  w-[50%]">
                  {noCheck ? null : (
                    <div className="flex flex-row items-center">
                      <div className="flex items-center pl-4 pr-4">
                        <FormInput
                          name="select-all"
                          type="checkbox"
                          className="flex self-center !mt-[19px]"
                          onChange={(event) => {
                            if (event.target.checked) {
                              selectAllItems();
                            } else {
                              setSelectedItems([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <span className="flex p4-medium text-color-black-3">
                      {tableConfig.mobileHeader.left.title}
                    </span>
                  </div>
                </div>
                <div className="ml-2">
                  <span className="flex p4-medium text-color-black-3">
                    {tableConfig.mobileHeader.right.title}
                  </span>
                </div>
              </div>
            ) : null}
            {data && data.data ? (
              <>
                {/* Mobile View */}
                <div className="flex flex-col px-3 ">
                  {data.data.map((dataRow, index) => (
                    <>
                      <div
                        onClick={() => toggleMobileCollapseView(index)}
                        className="h-[72px] flex justify-start items-center "
                      >
                        {noCheck ? null : (
                          <div className="flex flex-row items-center">
                            <div className="flex pl-4 pr-4">
                              <FormInput
                                name="select"
                                type="checkbox"
                                checked={selectedItems.includes(
                                  dataRow["id"].toString()
                                )}
                                onChange={() => {
                                  if (
                                    selectedItems.includes(
                                      dataRow["id"].toString()
                                    )
                                  ) {
                                    setSelectedItems((selectedItems) =>
                                      selectedItems.filter(
                                        (e) => e !== dataRow["id"].toString()
                                      )
                                    );
                                  } else {
                                    setSelectedItems([
                                      ...selectedItems,
                                      dataRow["id"].toString(),
                                    ]);
                                  }
                                }}
                                className="flex items-center self-center !mt-[19px]"
                              />
                            </div>
                          </div>
                        )}

                        <button className="flex flex-row items-center w-full justify-start">
                          <div className="w-1/2 text-left">
                            <span className="p4-medium text-color-black-3">
                              {dataRow[tableConfig.mobileHeader.left.key]}
                            </span>
                          </div>

                          <div className="flex flex-row items-center justify-between  w-1/2">
                            {tableConfig.mobileHeader.right.key === "status" ? (
                              <StatusText
                                code={
                                  dataRow[tableConfig.mobileHeader.right.key]
                                }
                                statusConfig={statusConfig}
                              />
                            ) : (
                              <span className="p4-medium text-color-black-3 pr-3">
                                {dataRow[tableConfig.mobileHeader.right.key]}
                              </span>
                            )}

                            <img src={DropDownSVG} alt="" width={"12px"} />
                          </div>
                        </button>
                      </div>
                      <div
                        className={`duration-500 ${
                          mobileCollapseView[index] ? "flex" : "hidden"
                        }`}
                      >
                        <div className="flex flex-col w-full">
                          {tableConfig.keys.map((key, index) => (
                            <div className="flex flex-row justify-between py-3">
                              <span className="p3 text-color-black-3 capitalize font-extrabold">
                                {tableConfig.headers[index]}:
                              </span>

                              {key === "status" ? (
                                <StatusText
                                  code={dataRow[key]}
                                  statusConfig={statusConfig}
                                />
                              ) : (
                                <span className="p4"> {dataRow[key]}</span>
                              )}
                            </div>
                          ))}
                          {computedActions ? (
                            <div className="flex flex-row justify-between">
                              <span className="p4 text-color-black-3 capitalize mt-3">
                                Action
                              </span>
                              <Action data={dataRow} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <div className="flex items-center text-center justify-center w-[100%] h-[200px] p-3 p4-bold">
                {loading ? (
                  <div className="flex flex-row w-[100%] items-center justify-center animate-pulse p1-bold text-primary-2">
                    Loading...
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-[100%]">
                    <img
                      src={EmptyTableSVG}
                      alt="No Data Fetched"
                      width={"80%"}
                      height={"200px"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {data && data.current_page ? (
            <>
              {/* Pagination */}
              <div className="flex flex-row justify-center md:justify-end mb-4 md:mb-8 md:pr-6 items-center">
                <button
                  className={classNames(paginationBtnClass, {
                    "bg-gray-100": data.current_page <= 1,
                  })}
                  onClick={() => setPage(page - 1)}
                  disabled={data.current_page <= 1}
                >
                  <div className="ml-[9px]">
                    <img
                      src={DropDownSVG}
                      alt=""
                      className="rotate-90"
                      width={"10px"}
                    />
                  </div>
                </button>
                {data.last_page < 4 ? (
                  <>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === 1,
                        hidden: data.last_page < 1,
                      })}
                      onClick={() => setPage(1)}
                      disabled={data.current_page === 1}
                    >
                      <span className="p4-bold">1</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === 2,
                        hidden: data.last_page < 2,
                      })}
                      onClick={() => setPage(2)}
                      disabled={data.current_page === 2}
                    >
                      <span className="p4-bold"> 2</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === 3,
                        hidden: data.last_page < 3,
                      })}
                      onClick={() => setPage(3)}
                      disabled={data.current_page === 3}
                    >
                      <span className="p4-bold"> 3</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === 4,
                        hidden: data.last_page < 4,
                      })}
                      onClick={() => setPage(4)}
                      disabled={data.current_page === 4}
                    >
                      <span className="p4-bold"> 4</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-white-100": data.current_page === 1,
                        hidden:
                          data.current_page < parseInt(data.last_page) - 1 ||
                          data.current_page <= 2,
                      })}
                      onClick={() => setPage(1)}
                      disabled={data.current_page === 1}
                    >
                      <span className="p4-bold">1</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-white-100": data.current_page - 1,
                        hidden:
                          data.current_page < parseInt(data.last_page) - 1 ||
                          data.current_page <= 2,
                      })}
                      onClick={() => setPage(1)}
                      disabled={data.current_page === 2}
                    >
                      <span className="p4-bold"> 2</span>
                    </button>

                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-white-100": data.current_page - 1,
                        hidden:
                          data.current_page >= data.last_page - 1 ||
                          data.current_page - 1 < 1,
                      })}
                      onClick={() => setPage(data.current_page - 1)}
                      // disabled={data.current_page === page}
                    >
                      <span className="p4-bold"> {data.current_page - 1}</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page,
                        hidden:
                          data.current_page >= data.last_page - 1 ||
                          data.current_page === data.last_page,
                      })}
                      // onClick={() => setPage(data.current_page)}
                      disabled={true}
                    >
                      <span className="p4-bold"> {data.current_page}</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-white-100": data.current_page + 1,
                        hidden:
                          data.current_page + 1 >= data.last_page - 1 ||
                          data.current_page === data.last_page,
                      })}
                      onClick={() => setPage(data.current_page + 1)}
                      disabled={false}
                    >
                      <span className="p4-bold"> {data.current_page + 1}</span>
                    </button>

                    <button
                      className={classNames(paginationBtnClass, {})}
                      disabled={true}
                    >
                      <span className="p4-bold"> ...</span>
                    </button>
                    {/* Last 2 Pages */}
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === data.last_page - 1,
                      })}
                      onClick={() => setPage(data.last_page - 1)}
                      disabled={data.current_page === data.last_page - 1}
                    >
                      <span className="p4-bold"> {data.last_page - 1}</span>
                    </button>
                    <button
                      className={classNames(paginationBtnClass, {
                        "bg-gray-100": data.current_page === data.last_page,
                        hidden: data.last_page === 1,
                      })}
                      onClick={() => setPage(data.last_page)}
                      disabled={data.current_page === data.last_page}
                    >
                      <span className="p4-bold">{data.last_page}</span>
                    </button>
                  </>
                )}
                {/* Select Limit Dropdown */}
                <button
                  className={classNames(paginationBtnClass, {
                    "bg-gray-100": data.current_page === data.last_page,
                  })}
                  onClick={() => setPage(data.current_page + 1)}
                  disabled={data.current_page === data.last_page}
                >
                  <div className="ml-[9px]">
                    <img
                      src={DropDownSVG}
                      alt=""
                      className="-rotate-90"
                      width={"10px"}
                    />
                  </div>
                </button>

                <div className="hidden md:flex items-center flex-row pl-4 mt-3">
                  <span className="text-color-gray text-[14px] h-[35px]">
                    View
                  </span>
                  <div className="flex justify-center items-center">
                    <FormSelect
                      value={limit}
                      options={pageLimitOptions}
                      onChange={(selected) => {
                        setPage(1);
                        setLimit(selected);
                      }}
                      className="w-[60px] h-[35px]"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-[60px] flex md:justify-end justify-center align-center mr-3 md:-mt-8  mb-3">
                <span className="ml-1 mt-3 text-color-gray">Go To Page</span>
                <div className="flex justify-center ml-2 mt-[1px]">
                  <FormInput
                    defaultValue={gotoPage}
                    onInput={(e) => {
                      if (e.target.value > data.last_page) {
                        setGotoPage(data.last_page);
                      } else if (e.target.value < 1) {
                        setGotoPage(1);
                      } else {
                        setGotoPage(e.target.value);
                      }
                    }}
                    className="w-[60px] !max-h-[35px] pl-2 rounded-tr-none rounded-br-none "
                  />
                </div>
                <Button
                  text={"Go"}
                  type="button"
                  theme={"primary"}
                  disabled={false}
                  className="max-w-[40px] !h-[37px] p-2 mt-2.5 p4-medium -ml-1 rounded-tl-none border-1 border-slate-300 border-r-[0px]  rounded-bl-none mr-3 bg-slate-300"
                  onClick={() => setPage(gotoPage)}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DataTable;
