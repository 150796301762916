/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../components/layouts/dashboard';
import FormSelect from '../../components/form-select';
import EmployeeSVG from '../../assets/svg/employee-green.svg';
import PayrollChart from '../../components/dashboard/payroll-chart';
import { Card } from '../../components/card';
import { useGetDashboard } from '../../redux/dashboard/hook';
import LoadingBar from '../../components/loader-bar';
import AddAdminInfo from '../../components/modal/settings/add-admin-info';

const Dashboard = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);
  const { data: dashboard, isLoading } = useGetDashboard();
  const [dashboardCards, setDashboardCards] = useState([]);
  const [payrollStat, setPayrollStats] = useState(null);
  const [showAdminInfo, setShowAdminInfo] = useState(false);

  useEffect(() => {
    if (dashboard) {
      updateDashboardCards();
      updateStats();
    }
  }, [dashboard]);


  const [sortOption, setSortOption] = useState('Last 6 months');

  const sortOptions = [{ value: 'Last 6 months', label: 'Last 6 months' }];
  function updateDashboardCards() {
    const DashboardCards = [
      {
        title: 'TOTAL DISBURSEMENTS',
        value: `₦${dashboard?.outflows.toLocaleString()}`,
        icon: EmployeeSVG,
      },
      {
        title: 'TOTAL DEPOSITS',
        value: `₦${dashboard?.inflows.toLocaleString()}`,
        icon: EmployeeSVG,
      },
      {
        title: 'COMPANY WALLET BALANCE',
        value: `₦${dashboard?.walletBalance.toLocaleString()}`,
        icon: EmployeeSVG,
      },
      {
        title: 'COMPANIES',
        value: dashboard?.company_count,
        icon: EmployeeSVG,
      },
      {
        title: 'EMPLOYEES',
        value: dashboard?.employees,
        icon: EmployeeSVG,
      },
      {
        title: 'WEB USERS',
        value: dashboard?.web_users,
        icon: EmployeeSVG,
      },
      {
        title: 'MOBILE USERS',
        value: dashboard?.mobile_users,
        icon: EmployeeSVG,
      },
    ];

    setDashboardCards(DashboardCards);
  }

  function updateStats() {
    let data = {
      data: {
        labels: ['', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Mar'],
        datasets: [
          {
            label: 'Amount',
            data: [],
            fill: true,
            borderWidth: 2,
            backgroundColor: 'rgb(236,243,251)',
            borderColor: 'rgb(0, 25, 132)',
            tension: 0.5,
            pointRadius: 0.5,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 0,
            pointBorderColor: 'rgb(0, 25, 132)',
            pointHoverBorderColor: 'rgb(239, 160, 11)',
          },
        ],
      },
      options: {
        options: {
          // maintainAspectRatio: false,
          // responsive: true,

          plugins: {
            tooltip: {
              backgroundColor: 'rgb(236,243,251)',
              borderColor: 'rgb(236,243,251)',
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 500000,
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '₦' + parseInt(value).toLocaleString();
              },
            },
          },
        },
      },
    };

    dashboard.lastSixMonths.reverse();

    let amounts = [];
    let months = [];

    dashboard.lastSixMonths.forEach((element) => {
      amounts.push(element.amount);
      months.push(element.month);
    });

    data.data.labels = months;
    data.data.datasets[0].data = amounts;

    setPayrollStats(data);
  }

  return (
    <DashboardLayout title='Dashboard'>
      {showAdminInfo ? (
        <AddAdminInfo
          isOpen={showAdminInfo}
          closeModal={() => setShowAdminInfo(false)}
        />
      ) 
      : 
      null}
      <div className='px-[30px] flex flex-col h-full'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className='flex flex-col'>
            <span className='header-4'> Welcome {user.first_name} {user.last_name},</span>
            <span className='p3 mt-2'>
              Welcome to the Ropayments Inc. Admin Dashboard
            </span>
          </div>
        </div>
        {isLoading?
        <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[15px]'>
          <LoadingBar loading={isLoading} />
        </div>
        :
        <>
         <div className='mt-8 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 w-full'>
            {dashboardCards.map((item) => (
              <Card title={item.title} subtitle={item.value} icon={item.icon} />
            ))}
          </div>
          <div className='bg-color-white flex  px-4 py-[21px] flex-col mt-[25px] overflow-hidden'>
            <div className='flex flex-col lg:flex-row '>
              {/* Payroll Stat */}
              <div className='w-[100%] flex flex-col pr-3'>
                <div className='flex flex-row justify-between items-center'>
                  <span className='p2'>Transaction Stats</span>
                  <div className='flex flex-row items-center'>

                    <div className='flex items-center h-[27px] pt-2'>
                      <FormSelect
                        defaultValue={sortOption}
                        options={sortOptions}
                        onChange={(selected) => {
                          setSortOption(selected);
                        }}
                        type='default'
                        extraClass='h-[27px] w-[140px] text-[12px]'
                      />
                    </div>
                  </div>
                </div>
                <hr className='divider' />

                <div className='mt-5 h-full'>
                  {payrollStat ? <PayrollChart data={payrollStat} /> : null}
                </div>
              </div>
            </div>
          </div>
        </>}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
