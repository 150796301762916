import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const GuestRoute = ({ component: Component, path }) => {
  const { user } = useSelector((state) => state.userSlice);

  if (user) {
    return <Redirect to='/dashboard' />;
  }
  return <Route component={Component} path={path} />;
};

export default GuestRoute;
