import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT, subscriptionStatusConfig } from '../../utils/constants';
import { useGetCurrentPeoplePlans } from '../../redux/hr-subscription/hook';


const CurrentPeoplePlans = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: current_plan } = useGetCurrentPeoplePlans(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  useEffect(() => {

    if(current_plan) {
      console.log(current_plan)
    }
    
  }, [current_plan]);

  const tableConfig = {
    headers: ['Date Created', 'Subscription Plan', 'Billing Cycle', 'Start Date', 'End Date', 'Auto Renew Date', 'Status' ],
    keys: ['created', 'subscription_plan', 'billing_cycle', 'start_date', 'end_date', 'auto_renew_date', 'status'],
    mobileHeader: {
      left: {
        title: 'Company Created',
        key: 'created',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  return (

    <div className='flex flex-col bg-white'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Current People Plans
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={current_plan}
        tableConfig={tableConfig}
        noCheck={true}
        statusConfig={subscriptionStatusConfig}
        // actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        // computedActions={true}
      />
    </div>
  );
};

export default CurrentPeoplePlans;

