import React from 'react';
import Info from './info';

const FormSelect = ({
  type,
  name,
  label,
  defaultValue,
  extraClass,
  selectRef,
  readOnly,
  options,
  error,
  onChange,
  errorMessage,
  infos,
  ...rest
}) => {
  const selectedThemeClass =
    type === 'default'
      ? 'w-full !pl-[15px] text-[13px] h-[42px]'
      : ' bg-color-off-white !pl-[20px] h-[42px]';

  return (
    <div className='pb-[20px] pt-3'>
      <div className='flex flex-col'>
        {label && (
          <label className='text-[13px] leading-[10px] font-normal text-color-gray'>
            {label}
          </label>
        )}
        <select
          name={name}
          className={`select-input rounded-md ${selectedThemeClass} ${extraClass} border-[0.5px] !border-color-gray mt-2 text-color-black`}
          defaultValue={defaultValue}
          ref={selectRef}
          readOnly={readOnly}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          {...rest}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {error && <span>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormSelect;
