import React, { useEffect } from 'react';
import { Card } from '../../components/card';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';

import { useState } from 'react';
import { PAGINATION_DEFAULT, TicketStatusConfig } from '../../utils/constants';
import { useGettickets } from '../../redux/user/hook';
import Button from '../../components/button';
import { useGetSupportTickets, useMarkTicketResolved } from '../../redux/contact-us/hook';
import { useHistory } from 'react-router-dom';
import AddSupportTicket from '../../components/modal/contact-us/add-ticket';

const TicketsPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    start_date: '',
    end_date: '',
    search:''
  });

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const { data: supportTickets } = useGetSupportTickets(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.type,
  );

  const [showAddTicket, setShowAddTicket] = useState(false);

  const { mutateAsync: markResolved } = useMarkTicketResolved();
  
  const tableConfig = {
    // title: 'Transactions History',
    headers: ['Date', 'Company Name', 'User Mobile', 'UserEmail', 'Subject', 'Message', 'Status', ],
    keys: ['created', 'company_name','mobile', 'email', 'subject', 'message', 'status'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Subject',
        key: 'subject',
      },
    },
  };
  const history = useHistory();

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = supportTickets.data.findIndex((item) => item.id === id);
        history.push('/tickets/view/' + supportTickets.data[index].id);
      }
    },
    {
        name: 'Mark Ticket',
        color: 'green',
        action: (id) => {
          let index = supportTickets.data.findIndex((item) => item.id === id);
          markResolved(supportTickets.data[index].id);
        }
      },
  ];

  return (
    <DashboardLayout title='Customer Support Tickets'>
      {showAddTicket ? (
      <AddSupportTicket
        isOpen={showAddTicket}
        closeModal={() => setShowAddTicket(false)}
      />
      ) : null}
      <div className='flex flex-col'>
        <div className='flex flex-col bg-white'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Customer Support Tickets
            </div>
            <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='CREATE SUPPORT TICKET'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowAddTicket(true)}}
                />
              </div>
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={supportTickets}
            tableConfig={tableConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
            filterByDate={dateConfig}
            actionConfig={actionConfig}
            statusConfig={TicketStatusConfig}
            noCheck={true}
            computedActions={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TicketsPage;
