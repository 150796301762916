import React, { useEffect } from 'react';

import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useGetEmployeeGroups } from '../../redux/employee/hook/groups';
import AddEmployeeGroup from '../modal/employees/add-employee-group';

const EmployeeGroupsTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: employeeGroups } = useGetEmployeeGroups(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const [showAddEmployeeGroup, setShowAddEmployeeGroup] = useState(false);
  const [groupData, setGroupData] = useState();

  const history = useHistory();

  const tableConfig = {
    title: 'Group Management',
    headers: ['Date Created', 'Group name', 'Members', 'Descriptions'],
    keys: ['created', 'name', 'members', 'description'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Group name',
        key: 'name',
      },
    },
  };

  // useEffect(() => {
  //   console.log(employeeGroups, '= employe groups');
  // }, [employeeGroups]);

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => history.push('/employee/group/view/' + id),
    },
    {
      name: 'Edit',
      color: 'orange',
      action: (id) => {
        let index = employeeGroups.data.findIndex((item) => item.id === id);
        setGroupData(employeeGroups.data[index].id)
        setShowAddEmployeeGroup(true)
      },
    },
  ];

  // const bulkActionConfig = [
  //   {
  //     label: 'Basic Payroll',
  //     value: 'basic-payroll',
  //     action: () => {},
  //   },
  //   {
  //     label: 'Advanced Payroll',
  //     value: 'basic-payroll',
  //     action: () => {},
  //   },
  //   {
  //     label: 'Delete',
  //     value: 'active',
  //     action: () => {},
  //   },
  // ];

  return (
    <div>
      {showAddEmployeeGroup?
        <AddEmployeeGroup
          isOpen={showAddEmployeeGroup}
          closeModal={() => setShowAddEmployeeGroup(false)}
          groupData={groupData}
          title='Edit Group'
          buttonText='UPDATE'
        />
      :
      null}
      <DataTable
        data={employeeGroups}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
      />
    </div>
  );
};

export default EmployeeGroupsTab;
