import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import Button from '../../components/button';
import { useDeletePeoplePlan, useGetPeoplePlans } from '../../redux/hr-subscription/hook';
import AddPeoplePlan from '../modal/settings/add-people-plan';

const PeoplePlans = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: people_plans } = useGetPeoplePlans(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const [showAddPeoplePlans, setShowAddPeoplePlans] = useState(false);
  const [buttonText, setButtonText] = useState('Add Payment Plan');
  const [type, setButtonType] = useState('add');
  const [planData, setPlanData] = useState(null);
  const [title, setTitle] = useState('Add People Plan');
  const { mutateAsync: removePlan} = useDeletePeoplePlan();

  const tableConfig = {
    headers: ['Date Created', 'Package Name', 'Description', 'Max Teams', 'Max Departments','Max Branches' ],
    keys: ['created', 'package_name', 'description', 'max_teams', 'max_departments', 'max_branches'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Package Name',
        key: 'package_name',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Update Plan',
      color: 'orange',
      action: async (id) => {
        let index = people_plans.data.findIndex((item) => item.id === id);
        setPlanData(people_plans.data[index])
        setButtonText('Update Plan')
        setTitle('Update People Plan')
        setButtonType('edit')
        setShowAddPeoplePlans(true)
      },
    },
    {
      name: 'Remove Plan',
      color: 'red',
      action: async (id) => {
        let index = people_plans.data.findIndex((item) => item.id === id);
        await removePlan(people_plans.data[index].id)
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white'>
      {showAddPeoplePlans ? (
        <AddPeoplePlan
          isOpen={showAddPeoplePlans}
          buttonText={buttonText}
          type={type}
          title={title}
          planData={planData}
          closeModal={() => setShowAddPeoplePlans(false)}
        />
      ) 
      : 
      null}
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          People Plans
        </div>
        <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
          <div className='md:w-fit w-[100%]'>
            <Button
              text='New People Plan'
              type='submit'
              className='!h-[42px] px-3'
              textClass='!header-5'
              onClick={() => {
                setTitle('Add People Plan')
                setPlanData(null)
                setButtonText('Add Plan')
                setButtonType('add')
                setShowAddPeoplePlans(true)
              }}
            />
          </div>
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={people_plans}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default PeoplePlans;

