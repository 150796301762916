import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import Button from '../../components/button';
import { useDeletePeoplePaymentPlan, useGetPeoplePaymentPlans } from '../../redux/hr-subscription/hook';
import AddPeoplePaymentPlan from '../modal/settings/add-people-payment-plan';


const PeoplePaymentPlans = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: people_payment_plans } = useGetPeoplePaymentPlans(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const [showAddPeoplePaymentPlans, setShowAddPeoplePaymentPlans] = useState(false);
  const [buttonText, setButtonText] = useState('Add Plan');
  const [type, setButtonType] = useState('add');
  const [paymentData, setPaymentData] = useState('add');
  const [title, setTitle] = useState('Add Payment Plan');
  const { mutateAsync: removePaymentPlan} = useDeletePeoplePaymentPlan();

  const tableConfig = {
    headers: ['Date Created', 'Subscription Name', 'Billing Cycle', 'Base', 'Employee', ],
    keys: ['created', 'subscription_name', 'billing_cycle', 'base_price', 'employee_price'],
    mobileHeader: {
      left: {
        title: 'Company Created',
        key: 'created',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  const actionConfig = [
    {
        name: 'Update Plan',
        color: 'orange',
        action: async (id) => {
          let index = people_payment_plans.data.findIndex((item) => item.id === id);
          setPaymentData(people_payment_plans.data[index])
          setTitle('Update Payment Plan')
          setButtonText('Update Payment Plan')
          setButtonType('edit')
          setShowAddPeoplePaymentPlans(true)
        },
    },
    {
      name: 'Remove Plan',
      color: 'red',
      action: async (id) => {
        let index = people_payment_plans.data.findIndex((item) => item.id === id);
        await removePaymentPlan(people_payment_plans.data[index].id)
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white'>
        {showAddPeoplePaymentPlans ? (
        <AddPeoplePaymentPlan
            title={title}
            buttonText={buttonText}
            type={type}
            paymentData={paymentData}
            isOpen={showAddPeoplePaymentPlans}
            closeModal={() => setShowAddPeoplePaymentPlans(false)}
        />
        ) 
        : 
        null}
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          People Payment Plans
        </div>
        <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
          <div className='md:w-fit w-[100%]'>
            <Button
                text='New Payment Plan'
                type='submit'
                className='!h-[42px] px-3'
                textClass='!header-5'
                onClick={() => {
                    setTitle('Add Payment Plan')
                    setPaymentData(null)
                    setButtonText('Add Payment Plan')
                    setButtonType('add')
                    setShowAddPeoplePaymentPlans(true)
                }}
            />
          </div>
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={people_payment_plans}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default PeoplePaymentPlans;

