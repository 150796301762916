import { useMutation } from '@tanstack/react-query';
import { store } from '..';
import { setAlert } from '../components/components-slice';
import { emails } from '../../api/emails';

export function useSendCompanyEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSingleCompanyEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendCompanySelectEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSelectCompanyEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendCompanyBulkEmail() {
  return useMutation(
    (payload) => {
      return emails.sendBulkCompanyEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendEmployeeEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSingleEmployeeEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendEmployeeSelectEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSelectEmployeeEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendEmployeeBulkEmail() {
  return useMutation(
    (payload) => {
      return emails.sendBulkEmployeeEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendUserEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSingleUserEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendUserSelectEmail() {
  return useMutation(
    (payload) => {
      return emails.sendSelectUserEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendUserBulkEmail() {
  return useMutation(
    (payload) => {
      return emails.sendBulkUserEmail(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
      },
    },
  );
}

export function useSendUserNotification() {
  return useMutation(
    (payload) => {
      return emails.sendSingleUserNotification(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Notification Sent Successfuly'));
      },
    },
  );
}

export function useSendUserSelectNotification() {
  return useMutation(
    (payload) => {
      return emails.sendSelectUserNotification(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Notification Sent Successfuly'));
      },
    },
  );
}

export function useSendUserBulkNotification() {
  return useMutation(
    (payload) => {
      return emails.sendBulkUserNotification(payload);
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Notification Sent Successfuly'));
      },
    },
  );
}

export function useSendFeatureEmail() {
    return useMutation(
      (payload) => {
        return emails.sendFeatureEmail(payload);
      },
      {
        onSuccess: () => {
          store.dispatch(setAlert(true, 'success', 'Email Sent Successfuly'));
        },
      },
    );
  }
