import React, { useState, useEffect } from 'react';
import DataTable from '../../datatable';
import { PAGINATION_DEFAULT } from '../../../utils/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useSingleGetEmployeeSuspensions } from '../../../redux/employee/hook/suspensions';
import AddSuspension from '../../modal/employees/add-suspension';

const SingleEmployeeSuspensionsTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });

  const { id } = useParams();

  const { data: employeeSuspensions } = useSingleGetEmployeeSuspensions(id);

  const [showAddSuspension, setShowAddSuspension] = useState(false);
  const [suspData, setSuspData] = useState();

  // useEffect(() => {
  //   if (employeeSuspensions) {
  //     console.log('employee', employeeSuspensions);
  //   }
  // }, [employeeSuspensions]);

  const tableConfig = {
    title: 'Suspension Management',
    headers: [
      'Date Created',
      'Purpose',
      'Start Date',
      'End Date',
      'Payment Status',
    ],
    keys: [
      'created',
      'purpose',
      'start_date',
      'end_date',
      'payment_status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Payment Status',
        key: 'payment_status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'green',
      action: (id) => {
        let index = employeeSuspensions.data.findIndex((item) => item.id === id);
        setSuspData(employeeSuspensions.data[index])
        setShowAddSuspension(true)
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: 'Activate',
      value: 'active',
      action: () => {},
    },
  ];

  const statusConfig = [
    {
      label: 'Inactive',
      value: 0,
      color: 'orange',
    },
    {
      label: 'Active',
      value: 1,
      color: 'green',
    },
    {
      label: 'Pending',
      value: 2,
      color: 'orange',
    },
    {
      label: 'Deleted',
      value: 3,
      color: 'red',
    },
  ];

  return (
    <div>
      {showAddSuspension?
        <AddSuspension
          isOpen={showAddSuspension}
          closeModal={() => {
            setShowAddSuspension(false)
          }}
          suspData={suspData}
          title='Edit Suspension'
          buttonText='UPDATE'
        />
      :
      null}
      <DataTable
          data={employeeSuspensions}
          tableConfig={tableConfig}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          bulkActionConfig={bulkActionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          // computedActions={true}
          searchData={false}
      />  
    </div>
  );
};

export default SingleEmployeeSuspensionsTab;
