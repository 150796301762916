import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';

import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useActivateDeactivateUser, useGetUsers } from '../../redux/user/hook';
import Button from '../../components/button';
import SendEmail from '../../components/modal/emails/send-email';
import SendFeatureEmail from '../../components/modal/emails/send-feature-email';
import SendSingleEmail from '../../components/modal/emails/send-single-email';

const UsersPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendSingleEmail, setShowSendSingleEmail] = useState(false);
  const [showSendFeatureEmail, setShowSendFeatureEmail] = useState(false);
  const [userId, setUserId] = useState(false);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    start_date: '',
    end_date: '',
    search:''
  });

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const { data: users } = useGetUsers(
    pagination?.limit,
    pagination?.page,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.search,
  );
  const { mutateAsync: changeStatus } = useActivateDeactivateUser();

  const tableConfig = {
    headers: ['Date Created', 'First Name', 'Last Name', 'Company', 'Email', 'Mobile', 'Last Login', 'Status'],
    keys: ['created', 'first_name', 'last_name', 'company_name', 'email', 'mobile', 'last_login', 'status'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'First Name',
        key: 'first_name',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Send Email',
      color: 'green',
      action: (id) => {
        let index = users.data.findIndex((item) => item.id === id);
        setUserId(users.data[index].id)
        setShowSendSingleEmail(true)
      },
    },
    {
      name: 'Chnage Status',
      color: 'orange',
      action: (id) => {
        let index = users.data.findIndex((item) => item.id === id);
        changeStatus(users.data[index].id)
      },
    },
  ];

  //employees
  const userStatusConfig = [
    {
      label: 'Inactive',
      value: 0,
      color: 'red',
    },
    {
      label: 'Active',
      value: 1,
      color: 'green',
    },
  ];

  return (
    <DashboardLayout title='Users'>
      {showSendSingleEmail ?
        <SendSingleEmail
            title={'Send Email To Web User'}
            isOpen={showSendSingleEmail}
            closeModal={() => setShowSendSingleEmail(false)}
            type={'web'}
            id={userId}
            buttonText='Send Email'
        />
      : null}
      {showSendEmail ?
        <SendEmail
            title={'Send Email To Web Users'}
            isOpen={showSendEmail}
            closeModal={() => setShowSendEmail(false)}
            type={'web'}
            buttonText='Send Email'
        />
      : null}
      {showSendFeatureEmail ?
        <SendFeatureEmail
            title={'Send Feature Email To Web Users'}
            isOpen={showSendFeatureEmail}
            closeModal={() => setShowSendFeatureEmail(false)}
            type={'web'}
            buttonText='Send Email'
        />
      : null}
      <div className='flex flex-col'>
        <div className='flex flex-col bg-white'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Users
            </div>
            <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Email'
                  type='submit'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowSendEmail(true)}}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Feature Email'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {setShowSendFeatureEmail(true)}}
                />
              </div>
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={users}
            tableConfig={tableConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
            filterByDate={dateConfig}
            actionConfig={actionConfig}
            noCheck={true}
            statusConfig={userStatusConfig}
            computedActions={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UsersPage;
