import classNames from 'classnames';
import React from 'react';

export const StatusText = ({ code, statusConfig, className }) => {
  return (
    <>
      {code !== undefined ? (
        <span
          className={classNames(
            className,
            'p5',
            {
              'text-color-green-medium py-1 px-3 bg-color-green-light rounded':
                statusConfig[code].color === 'green',
            },
            {
              'text-color-orange py-1 px-3 bg-color-orange-light rounded':
                statusConfig[code].color === 'orange',
            },
            {
              'text-color-error py-1 px-3 bg-color-error-light rounded':
                statusConfig[code].color === 'red',
            },
          )}
        >
          {statusConfig[code].label}
        </span>
      ) : null}
    </>
  );
};
