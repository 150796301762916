import React, { useEffect } from 'react';
import { useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { AdvancedPayrollTab } from '../../components/payroll/advanced-payroll-tab';
import { BasicPayrollTab } from '../../components/payroll/basic-payroll-tab';
import PayrollHistoryTab from '../../components/payroll/payroll-history-tab';
import TabView from '../../components/tabview';
import { useLocation } from 'react-router-dom';

const PayrollPage = () => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setActiveTab(location.state.tab)
    }
  }, [location.state])

  const PayrollTabs = [
    {
      title: 'Advanced Payroll Summary',
      Content: AdvancedPayrollTab,
    },
    {
      title: 'Basic Payroll Summary',
      Content: BasicPayrollTab,
    },
    {
      title: 'Transaction History',
      Content: PayrollHistoryTab,
    },
  ];

  return (
    <DashboardLayout title='Payroll Management'>
      <div className='flex flex-col'>

        <div>
          <TabView
            Tabs={PayrollTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PayrollPage;
