import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { payrollHistoryStatusConfig, PAGINATION_DEFAULT } from '../../utils/constants';
import { useCheckTransactionStatus, useGetPayrollHistory } from '../../redux/payroll/hook/index';

const PayrollHistoryTab = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
  });

  const { data: payrollHistory } = useGetPayrollHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
  );

  const { mutateAsync: checkTransactionStatus, isLoading: loading } =
    useCheckTransactionStatus();


  useEffect(() => {
    console.log('Payroll history =', payrollHistory);
  }, [payrollHistory]);


  const tableConfig = {
    headers: ['Date Created', 'Name', 'Ref', 'Fee', 'Status' , 'Amount' ],
    keys: ['created', 'name', 'ref', 'fees', 'status', 'amount'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const statusConfig = [
    {
        label: 'Processing',
        color: 'orange',
        value: 0,
    },
    {
        label: 'Successful',
        color: 'green',
        value: 1,
    },
    {
        label: 'Failed',
        color: 'red',
        value: 2,
    },
    {
      label: 'Retried: Failed',
      color: 'red',
      value: 3,
    },
  ];
  const actionConfig = [
    {
      name: 'Check Status',
      color: 'green',
      action: async (id) => {
        let index = payrollHistory.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(payrollHistory.data[index].id);
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white'>
      <div className='flex flex-col'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Payroll Transactions History
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
            data={payrollHistory}
            tableConfig={tableConfig}
            filterByDate={dateConfig}
            filterByStatus={payrollHistoryStatusConfig}
            statusConfig={statusConfig}
            actionConfig={actionConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
            computedActions={true}
        />
      </div>
    </div>
  );
};

export default PayrollHistoryTab;
