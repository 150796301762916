import { Axios } from "../axios";

const getRecriuitments = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let start_date =
    payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  const { data } = await Axios.get(`/recruitments?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}`);
  return data?.data;
};

export const recruitment = {
    getRecriuitments
};