import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import AdvancedPayrollBreakdown from './advanced-payroll-breakdown';

function RunAdvancedPayroll({ isOpen, closeModal, source = 'default', sourceData}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });


  const [month, setMonth] = useState('01');
  const [year, setYear] = useState('2020-');
  const [setting, setSetting] = useState('global');
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);
  const [payloadData, setPayloadData] = useState(null);


  const submitForm = async (data) => {
    const desc = DOMPurify.sanitize(data?.desc);
    const payment_date = DOMPurify.sanitize(data?.payment_date);

    let payload = {
      desc,
      month: year + month,
      setting,
      payment_date
    };


    if (source === 'default') {
      onAdvancedPayrollSuccess(payload);
    } else if (source === 'group') {
      payload.group_id = sourceData;
      onAdvancedPayrollSuccess(payload)
    } else if (source === 'selective') {
      payload.employee_ids = sourceData;
      onAdvancedPayrollSuccess(payload)
    }
  };


  function onAdvancedPayrollSuccess(payload) {
     setPayloadData(payload)
    setShowAdvancedPayroll(true);
    closeModal();
  }

  const payrollOptions = [
    { label: 'Global settings', value: 'global' },
    { label: 'Group settings', value: 'group' },
  ];

  return (<>
        {showAdvancedPayroll ? (
            <AdvancedPayrollBreakdown
            isOpen={showAdvancedPayroll}
            source={source}
            sourceData={sourceData}
            payloadData={payloadData}
            closeModal={() => setShowAdvancedPayroll(false)}
       />
      ) : null}
      <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>Run Advanced Payroll</span>
              <hr className='divider' />
            </div>

            <div>
         
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  <FormSelect
                    defaultValue={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Payroll Month'
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    defaultValue={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Year'
                  />
                </div>
              </div>
             
              <FormInput
                label='Select Payment Date'
                name='payment_date'
                type='date'
                inputRef={register(formValidation('text', true))}
                error={errors.payment_date}
                errorMessage={errors.payment_date && errors.payment_date.message}
              />

              <FormInput
                label='Descripton'
                name='desc'
                type='text'
                inputRef={register(formValidation('text', true))}
                error={errors.desc}
                errorMessage={errors.desc && errors.desc.message}
              />

              <FormSelect
                defaultValue={setting}
                options={payrollOptions}
                onChange={(selected) => {
                  setSetting(selected);
                }}
                label='Payroll Setting'
              />
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text='CONTINUE'
                type='submit'
                disabled={!isValid}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </>

  );
}

export default RunAdvancedPayroll;
