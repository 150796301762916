import { Axios } from './axios';

const sendSingleCompanyEmail = async (payload) => {
    const { data } = await Axios.post(`/send-single-email/company/${payload.id}`, payload.data ,
    {
    timeout: 0
    });
    return data;
};

const sendBulkCompanyEmail = async (payload) => {
    const { data } = await Axios.post('/send-bulk-email/company', payload,
    {
    timeout: 0
    });
    return data;
};

const sendSelectCompanyEmail = async (payload) => {
    const { data } = await Axios.post('/send-select-email/company', payload,
    {
    timeout: 0
    });
    return data;
};

const sendSingleEmployeeEmail = async (payload) => {
    const { data } = await Axios.post(`/send-single-email/employee/${payload.id}`, payload.data ,
    {
    timeout: 0
    });
    return data;
};

const sendBulkEmployeeEmail = async (payload) => {
    const { data } = await Axios.post('/send-bulk-email/employee', payload,
    {
    timeout: 0
    });
    return data;
};

const sendSelectEmployeeEmail = async (payload) => {
    const { data } = await Axios.post('/send-select-email/employee', payload,
    {
    timeout: 0
    });
    return data;
};

const sendSingleUserEmail = async (payload) => {
    const { data } = await Axios.post(`/send-single-email/user/${payload.id}`, payload.data,
    {
    timeout: 0
    });
    return data;
};
  
const sendBulkUserEmail = async (payload) => {
    const { data } = await Axios.post('/send-bulk-email/user', payload,
    {
    timeout: 0
    });
    return data;
};
  
const sendSelectUserEmail = async (payload) => {
    const { data } = await Axios.post('/send-selected-email/user', payload,
    {
    timeout: 0
    });
    return data;
};

const sendSingleUserNotification = async (payload) => {
    const { data } = await Axios.post(`/send-single-notification/user/${payload.id}`, payload.data,
    {
    timeout: 0
    });
    return data;
};
  
const sendBulkUserNotification = async (payload) => {
    const { data } = await Axios.post('/send-bulk-notification/user', payload,
    {
    timeout: 0
    });
    return data;
};
  
const sendSelectUserNotification = async (payload) => {
    const { data } = await Axios.post('/send-selected-notification/user', payload,
    {
    timeout: 0
    });
    return data;
};

const sendFeatureEmail = async (payload) => {
    const { data } = await Axios.post('/send-feature-update', payload,
    {
    timeout: 0
    });
    return data;
};

export const emails = {
    sendSingleCompanyEmail,
    sendBulkCompanyEmail,
    sendSelectCompanyEmail,
    sendSingleEmployeeEmail,
    sendBulkEmployeeEmail,
    sendSelectEmployeeEmail,
    sendSingleUserEmail,
    sendBulkUserEmail,
    sendSelectUserEmail,
    sendSingleUserNotification,
    sendBulkUserNotification,
    sendSelectUserNotification,
    sendFeatureEmail,
  };