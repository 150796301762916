import React, { useEffect } from 'react';
import {
  useActivateOrDeactivateEmployee,
  useBulkActivateEmployees,
  useBulkDeactivateEmployees,
  useGetEmployees,
} from '../../redux/employee/hook';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useGetEmployeeSuspensions } from './../../redux/employee/hook/suspensions';
import AddSuspension from '../modal/employees/add-suspension';

const EmployeeSuspensionsTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const [showAddSuspension, setShowAddSuspension] = useState(false);
  const [suspData, setSuspData] = useState();

  const { data: employees } = useGetEmployeeSuspensions(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const tableConfig = {
    title: 'Suspension Management',
    headers: [
      'Date Created',
      'Employee Name',
      'Purpose',
      'Start Date',
      'End Date',
      'Payment Status',
    ],
    keys: [
      'created', 
      'employeeName',
      'purpose',
      'start_date',
      'end_date',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Payment Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'green',
      action: (id) => {
        let index = employees.data.findIndex((item) => item.id === id);
        setSuspData(employees.data[index])
        setShowAddSuspension(true)
      },
    },
    // {
    //   name: 'Edit',
    //   color: 'orange',
    //   action: () => {},
    // },
  ];

  const bulkActionConfig = [
    // {
    //   label: 'Activate',
    //   value: 'active',
    //   action: () => {},
    // },
  ];

  const statusConfig = [
    {
      label: 'Without Pay',
      value: 0,
      color: 'orange',
    },
    {
      label: 'With Pay',
      value: 1,
      color: 'green',
    },
  ];

  return (
    <div>
      {showAddSuspension?
        <AddSuspension
          isOpen={showAddSuspension}
          closeModal={() => {
            setShowAddSuspension(false)
          }}
          suspData={suspData}
          title='Edit Suspension'
          buttonText='UPDATE'
        />
      :
      null}
      <DataTable
        data={employees}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        // filterByStatus={true}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default EmployeeSuspensionsTab;
