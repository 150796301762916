import React from 'react';
import classNames from 'classnames';

export const Card = ({ title, subtitle, icon, className }) => {
  return (
    <div
      className={`h-[90px] p-3 flex flex-col justify-center bg-white rounded-[5px] border border-[#dbdce3] ${className}`}
    >
      <div className='flex flex-row justify-between'>
        <div className='flex-col'>
          <span className='font-medium text-[12px] leading-[12px] text-color-gray flex flex-col'>
            {title}
          </span>
          <div className='mt-3'>
            <span className='p1 mt-[11px]'>{subtitle}</span>
          </div>
        </div>

        <div
          className={classNames(
            'flex justify-center items-center min-w-[40px]',
          )}
        >
          <img src={icon} alt='' />
        </div>
      </div>
    </div>
  );
};
