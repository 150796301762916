import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import WarningSVG from '../../assets/svg/warning.svg';

const PayrollPlans = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  
  return (
    <div className='flex flex-col md:flex-row mt-10 h-full'>
          <div className='flex flex-col max-w-[500px] w-full '>
            {/* Basic Plan */}
            <div
              onClick={() => setSelectedPlanIndex(0)}
              className={classNames(
                'max-w-[500px] h-[120px] flex flex-row justify-between items-center rounded px-4 cursor-pointer',
                {
                  'bg-secondary-2 text-color-white cursor-default': selectedPlanIndex === 0,
                },
                {
                  'text-color-black border border-secondary-2':
                    selectedPlanIndex !== 0,
                },
              )}
            >
              <div className='flex flex-row w-full max-w-[250px] text-inherit'>
                <div className='flex flex-col text-inherit'>
                  <span className=' p4-bold text-inherit'> BASIC PAYROLL PLAN</span>
                  <span className=' p5 mt-1 text-inherit'>
                    Pay Your Employee(s) Take Home (No Computation or Statutory
                    Compliances).{' '}
                  </span>
                </div>
              </div>
              <div className='flex flex-col text-end text-inherit'>
                <span className='header-5 text-inherit'>
                  N44.99
                  <span className='header-5-regular text-inherit'>
                    /Employee
                  </span>
                </span>
                {selectedPlanIndex === 0?
                null:
                <span className='text-inherit p4-medium-2'>View Plan</span>
                }
              </div>
            </div>

            {/* Advanced Plan */}
            <div
              onClick={() => setSelectedPlanIndex(1)}
              className={classNames(
                'mt-6 mb-6 max-w-[500px] h-[120px] flex flex-row justify-between items-center rounded px-4 cursor-pointer',
                {
                  'bg-secondary-2 text-color-white cursor-default': selectedPlanIndex === 1,
                },
                {
                  'text-color-black border border-secondary-2':
                    selectedPlanIndex !== 1,
                },
              )}
            >
              <div className='flex flex-row w-full max-w-[250px] text-inherit'>
                <div className='flex flex-col text-inherit'>
                  <span className=' p4-bold text-inherit'> ADVANCED PAYROLL PLAN</span>
                  <span className=' p5 mt-1 text-inherit'>
                  Compute and Pay Your Employee(s) And Other Statutory
                  Complainces.
                  </span>
                </div>
              </div>
              <div className='flex flex-col text-end text-inherit'>
                <span className='header-5 text-inherit'>
                  N500
                  <span className='header-5-regular text-inherit'>
                    /Employee
                  </span>
                </span>
                {selectedPlanIndex === 1?
                null:
                <span className='text-inherit p4-medium-2'>View Plan</span>
                }
              </div>
            </div>
          </div>
          {selectedPlanIndex === 1
          ?
          <div className='w-[100%] md:ml-4'>          
            <div className='border border-color-line rounded p-4 flex flex-col'>
              <div className='w-full md:w-[100%]'>
                <div className='flex flex-col'>
                  <span className='header-4 text-color-gray'>Plan Name</span>
                  <span className='mt-1 text-color-black p4'>Advanced Plan</span>
                </div>
                <div className='flex flex-col mt-4'>
                  <span className='header-4 text-color-gray'>
                    Transaction Fees
                  </span>
                  <span className='mt-1 text-color-black p4'>
                    N500.00/Employee/Cycle
                  </span>
                </div>
                <div className='flex mb-8 mt-3'>
                  <div className='flex flex-row px-1 w-[100%] border-secondary-4 border-[1.2px] rounded-lg p-5 pt-3 pb-3 mt-2 bg-color-warning-light'>
                    <div className='w-[70px] pr-2 flex flex-row items-center '>
                      <img src={WarningSVG} alt='' srcset='' />
                    </div>
                    
                    <div className='p4-regular'>
                      <span className='p4-bold'>
                          What Does this Fee Cover?
                      </span>
                      
                      <li>
                        Payroll Computation, payment and Payslip to Employees
                      </li>
                      <li>
                          Pension Computation, Payment and Filling
                      </li>
                      <li>
                          Tax Computation, Payment and Filling
                      </li>
                      <li>
                          Other Statutory Computation, Payment and Filing
                      </li>
                      <li>
                          Free to use Payroll platform
                      </li>
                    </div>
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className='w-[100%] md:ml-4'>          
            <div className='border border-color-line rounded p-4 flex flex-col'>
              <div className='w-full md:w-[100%]'>
                <div className='flex flex-col'>
                  <span className='header-5 text-color-gray'>Plan Name</span>
                  <span className='mt-1 text-color-black p4'>Basic Plan</span>
                </div>
                <div className='flex flex-col mt-4'>
                  <span className='header-5 text-color-gray'>
                    Transaction Fees
                  </span>
                  <span className='mt-1 text-color-black p4'>
                    N50.00/Employee/Cycle
                  </span>
                </div>
                <div className='flex mb-8 mt-3'>
                  <div className='flex flex-row px-1 w-[100%] border-secondary-4 border-[1.2px] rounded-lg p-5 pt-3 pb-3 mt-2 bg-color-warning-light'>
                    <div className='w-[70px] pr-2 flex flex-row items-center '>
                      <img src={WarningSVG} alt='' srcset='' />
                    </div>
                    
                    <div className='p4-regular'>
                      <span className='p4-bold'>
                          What Does this Fee Cover?
                      </span>
                      
                      <li>
                        Payroll Payment To Employees
                      </li>
                      <li>
                          Free to use Payroll platform
                      </li>
                    </div>
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          

        </div>
  );
};

export default PayrollPlans;
