const { Axios } = require('./axios');

const getPeoplePlans = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    // let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get(`/subscription-packages?limit=${payload.limit}&page=${payload.page}${search}`);
    return data?.data;
};

const addPeoplePlan = async (payload) => {
    const { data } = await Axios.post(
      '/subscription-packages', payload
    );
    return data;
};

const updatePeoplePlan = async (payload) => {
    const { data } = await Axios.put(
      '/subscription-packages/' + payload.id, payload.data
    );
    return data;
};

const removePeoplePlan = async (payload) => {
    const { data } = await Axios.delete(
      '/subscription-packages/' + payload,
    );
    return data;
};

const getPeoplePaymentPlans = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get(`/subscription-package/plans?limit=${payload.limit}&page=${payload.page}${search}`);
    return data?.data;
};

const addPeoplePaymentPlans = async (payload) => {
    const { data } = await Axios.post(
      '/subscription-packages/plans', payload
    );
    return data;
};

const updatePeoplePaymentPlans = async (payload) => {
    const { data } = await Axios.put(
      '/subscription-packages/plans/' + payload.id, payload.data
    );
    return data;
};

const removePeoplePaymentPlans = async (payload) => {
    const { data } = await Axios.delete(
      '/subscription-packages/plans/' + payload,
    );
    return data;
};

const getCurrentPeoplePlans = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    // let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get(`/subscription-package/current_subscription?limit=${payload.limit}&page=${payload.page}${search}`);
    return data?.data;
};

const getSubscriptionHistory = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    // let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get(`/subscription-package/history?limit=${payload.limit}&page=${payload.page}${search}`);
    return data?.data;
};

//Check error

export const hr_subscription = {
    getPeoplePlans,
    addPeoplePlan,
    updatePeoplePlan,
    removePeoplePlan,
    getPeoplePaymentPlans,
    addPeoplePaymentPlans,
    updatePeoplePaymentPlans,
    removePeoplePaymentPlans,
    getCurrentPeoplePlans,
    getSubscriptionHistory,
  };