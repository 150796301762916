import React from 'react';
import DataTable from '../../datatable';
import { useState, useEffect } from 'react';
import {
  PAGINATION_DEFAULT,
  salaryAdvanceStatusConfig,
} from '../../../utils/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useSingleGetEmployeeSalaryAdvances } from '../../../redux/employee/hook/salary-advance';
import AddSalaryAdvance from '../../modal/employees/add-salary-advance';


const SingleEmployeeSalaryAdvanceTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });


  const { id } = useParams();

  useEffect(async () => {
    // console.log(id);
  }, [id]);

  const { data: employeeSalaryAdvances } =
    useSingleGetEmployeeSalaryAdvances(id);

  useEffect(() => {
    // console.log('employee Advances', employeeSalaryAdvances);
  }, [employeeSalaryAdvances]);

  const [saladData, setSaladData] = useState();
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const tableConfig = {
    title: 'Salary Advance',
    headers: [
      'Date Created',
      'Purpose',
      'Month',
      'Amount',
    ],
    keys: ['created', 'purpose', 'month', 'amount'],
    mobileHeader: {
      left: {
        title: 'Date Added',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Edit/View',
      color: 'green',
      action: (id) => {
        let index = employeeSalaryAdvances.data.findIndex((item) => item.id === id);
        setSaladData(employeeSalaryAdvances.data[index])
        setShowAddSalaryAdvance(true)
      },
    },
  ];
  

  return (
    <div>
      {showAddSalaryAdvance && saladData?
        <AddSalaryAdvance
          isOpen={showAddSalaryAdvance}
          closeModal={() => setShowAddSalaryAdvance(false)}
          saladData={saladData}
          title='Edit Salary Advance'
          buttonText="UPDATE"
        />
      :
      null} 
      <DataTable
        data={employeeSalaryAdvances}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={salaryAdvanceStatusConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
      />
    </div>
  );
};

export default SingleEmployeeSalaryAdvanceTab;
