import React from 'react';
import { useEffect } from 'react';
import Info from './info';

const FormSwitch = ({
  id,
  name,
  label,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  checked,
  classNames='',
  ...rest
  
}) => {
  return (
    <div className={`pb-[20px] flex flex-col ${classNames}`}>
      <div className='flex flex-row justify-between items-center'>
        <div>
          <span>{label}</span>
        </div>
        <label className='switch'>
          <input type='checkbox' checked={checked} ref={inputRef} {...rest} />
          <span className='toggle'></span>
        </label>
      </div>

      {error && <span>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormSwitch;
