import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import moment from 'moment';
import { referrals } from '../../api/referrals';

//     generateReferralCode,
//     getReferral,
//     updateReferral,
//     updateReferralGroup,
//     generateReferralSettings,
//     activateReferral

export function useCheckReferral() {
  return useMutation(
    () => {
      return referrals.checkReferral();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Referral checked successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetReferralUsage(limit, page) {
    return useQuery(
      ['referral_usage', { limit, page }],
      () => {
        store.dispatch(setIsLoading(true));
        return referrals.getReferralUsage({ limit, page });
      },
      {
        select: (data) => {
          data?.data?.forEach((ticket) => {
            ticket.created = moment(ticket.created).format('MMMM DD, YYYY');
            ticket.computedActions = [0];
          });
  
          return data;
        },
        onSettled: () => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetReferralData() {
    return useQuery(
      ['referral_info', {}],
      () => {
        store.dispatch(setIsLoading(true));
        return referrals.getReferralData();
      },
      {
        onSettled: () => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGenerateReferral() {
    return useMutation(
      (payload) => {
        return referrals.generateReferralCode(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('referral_info');
          store.dispatch(setAlert(true, 'success', 'Referral information generated successfully'));
        },
        onSettled: (response, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGenerateReferralSettings() {
  return useMutation(
    (payload) => {
      return referrals.generateReferralSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('referral_info');
        store.dispatch(setAlert(true, 'success', 'Referral settings created successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
