import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddBonus, useEditBonus } from '../../../redux/employee/hook/bonuses';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employee/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';

function AddBonus({ isOpen, closeModal, refresh, bonusData, title, buttonText }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addBonus, isLoading: addBonusloading } = useAddBonus();
  const { mutateAsync: editBonus, isLoading: editBonusloading } = useEditBonus();
  const [month, setMonth] = useState('01');
  const [year, setYear] = useState('2020-');
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState()
  const [purposeEdit, setPurposeEdit] = useState()
  const [bonusId, setBonusId] = useState()
  const [bonusType, setBonusType] = useState()

  useEffect(() => {
    if (bonusData) {
      setMonth(((bonusData.month).split('-')[1]).toString())
      setYear(((bonusData.month).split('-')[0]).toString()+'-')
      setAmountEdit(parseFloat(bonusData.amount).toFixed(2))
      setPurposeEdit(bonusData.purpose)
      setEmployee(bonusData.employee)
      setEmployeeName(bonusData.first_name + " " + bonusData.last_name)
      setBonusId(bonusData.id)
      setBonusType('edit')
      
    } else {
      setBonusType('add')
      setMonth('01')
      setYear('2022-')
    }
  }, [bonusData]);

  const submitForm = async (data) => {
    const amount = DOMPurify.sanitize(data?.amount);
    const purpose = DOMPurify.sanitize(data?.purpose);

    if (bonusType === 'add') {
      let payload = {
        employee,
        amount,
        purpose,
        month: year + month,
      };
      await addBonus(payload).then(() => {
        closeModal();
      });
    } else if (bonusType === 'edit') {
      let editPayload = {
        id: bonusId,
        body: {
          employee,
          amount: amountEdit,
          purpose: purposeEdit,
          month: year + month,
          status: 2
        }
      }
      await editBonus(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>{title}</span>
              <hr className='divider' />
            </div>

            <div>
              <FormSelectEmployee
                label={'Employee'}
                name='employee'
                placeholder='Select Employee'
                value={employee}
                readOnly={bonusType==='edit'? true : false}
                empName={employeeName}
                setEmployee={setEmployee}
              />

              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  <FormSelect
                    defaultValue={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Select Month'
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    defaultValue={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Select Year'
                  />
                </div>
              </div>
              <FormInput
                label='Purpose'
                name='purpose'
                type='text'
                readOnly={addBonusloading || editBonusloading}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
                defaultValue={purposeEdit}
                inputRef={register(formValidation('text', true))}
                onChange={(e) => {
                  setPurposeEdit(e.target.value);
                }}
              />

              <FormInput
                label='Amount'
                name='amount'
                type='number'
                inputRef={register(formValidation('number', true))}
                readOnly={addBonusloading || editBonusloading}
                defaultValue={amountEdit}
                onChange={(e) => {
                  setAmountEdit(e.target.value);
                }}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
              />
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={!isValid || !employee}
                loading={addBonusloading || editBonusloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddBonus;
