import { Axios } from '../axios';

const getEmployeeSuspensions = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/suspensions?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const AddSuspension = async (payload) => {
  const { data } = await Axios.post('/suspensions', payload);
  return data;
};

const editSuspension = async (payload) => {
  const { data } = await Axios.put('/suspensions/'+payload.id, payload.body);
  return data;
};

const getSingleEmployeeSuspensions = async (payload) => {
  const { data } = await Axios.get(
    `/suspensions/employee/` + payload.id,
  );
  return data?.data;
};

export const EmployeeSuspensions = {
  getEmployeeSuspensions,
  AddSuspension,
  getSingleEmployeeSuspensions,
  editSuspension
};
