import { Axios } from '../../axios';

const getITF = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/itf?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkITF = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/itf/bulk', payload);
  return data;
};

const fetchITFByRef = async (payload) => {
  const { data } = await Axios.get('/statutory-payments/itf/by-ref/' + payload);
  return data;
};

export const itfApis = {
    getITF,
    bulkITF,
    fetchITFByRef
};