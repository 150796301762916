export const APP_NAME = 'Ropay';
export const PAGINATION_DEFAULT = {
  limit: 10,
  page: 1,
  statusFilter: -1,
  type: '',
};

//employees
export const employeeStatusConfig = [
  {
    label: 'Inactive',
    value: 0,
    color: 'red',
  },
  {
    label: 'Active',
    value: 1,
    color: 'green',
  },
  {
    label: 'Pending',
    value: 2,
    color: 'orange',
  },
  {
    label: 'Deleted',
    value: 3,
    color: 'red',
  },
  {
    label: 'Awaiting Activation',
    value: 4,
    color: 'orange',
  },
  {
    label: 'Pre Registered',
    value: 5,
    color: 'orange',
  },
];

export const TicketStatusConfig = [
  {
      label: 'Pending',
      color: 'red',
      value: 0,
  },
  {
      label: 'Resolved',
      color: 'green',
      value: 1,
  },
  {
      label: 'In Progress',
      color: 'orange',
      value: 2,
  },
];

export const subscriptionStatusConfig = [
  {
    label: 'Expired',
    value: 0,
    color: 'red',
  },
  {
    label: 'Active',
    value: 1,
    color: 'green',
  },
  {
    label: 'Cancelled',
    value: 2,
    color: 'orange',
  },
];

//Leaves
export const leaveStatusConfig = [
  {
    label: 'Pending',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Approved',
    value: 1,
    color: 'green',
  },
  {
    label: 'Declined',
    value: 2,
    color: 'red',
  },
];

//Recruitment Status
export const recruitmentStatusConfig = [
  {
    label: 'Application Closed',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Application Open',
    value: 1,
    color: 'green',
  },
];

//employees
export const recruitmentApplicationStatusConfig = [
  {
    label: 'Not Reviewed',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Accepted',
    value: 1,
    color: 'green',
  },
  {
    label: 'Rejected',
    value: 2,
    color: 'red',
  },
  {
    label: 'Pending Acceptance',
    value: 3,
    color: 'orange',
  },
];

//Branch
export const branchStatusConfig = [
  {
    label: 'Inactive',
    value: 0,
    color: 'red',
  },
  {
    label: 'Active',
    value: 1,
    color: 'green',
  },
  {
    label: 'Closed',
    value: 2,
    color: 'orange',
  },
];

//Attendance
export const attendanceStatusConfig = [
  {
    label: 'Absent',
    value: 0,
    color: 'red',
  },
  {
    label: 'Present',
    value: 1,
    color: 'green',
  },
];

//Attendance Punctuality
export const attendancePunctualityStatusConfig = [
  {
    label: '=',
    value: 0,
    color: 'red',
  },
  {
    label: 'On Time',
    value: 1,
    color: 'green',
  },
  {
    label: 'Early',
    value: 2,
    color: 'green',
  },
  {
    label: 'Late',
    value: 3,
    color: 'orange',
  },
];

//employees
export const vendorStatusConfig = [
  {
    label: 'Inactive',
    value: 0,
    color: 'red',
  },
  {
    label: 'Active',
    value: 1,
    color: 'green',
  },
  {
    label: 'Pending',
    value: 2,
    color: 'orange',
  },
  {
    label: 'Deleted',
    value: 3,
    color: 'red',
  },
];

export const reImbursementStatusConfig = [
  {
    label: 'Pending',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Paid',
    value: 1,
    color: 'green',
  },
  {
    label: 'Declined',
    value: 2,
    color: 'green',
  },
];

export const reimbursementApplicationStatusConfig = [
  {
    label: 'Pending',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Approved',
    value: 1,
    color: 'green',
  },
  {
    label: 'Declined',
    value: 2,
    color: 'red',
  },
  {
    label: 'Payment Initiated',
    value: 3,
    color: 'orange',
  },
];

export const bonusesStatusConfig = [
  {
    label: 'Not Applied',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Applied',
    value: 1,
    color: 'green',
  },
  {
    label: 'Not Applied',
    value: 2,
    color: 'orange',
  },
];

export const salaryAdvanceStatusConfig = [
  {
    label: 'Not Deducted',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Deducted',
    value: 1,
    color: 'green',
  },
];

export const salaryAdvanceApplicationStatusConfig = [
  {
    label: 'Pending',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Approved',
    value: 1,
    color: 'green',
  },
  {
    label: 'Declined',
    value: 2,
    color: 'red',
  },
  {
    label: 'Payment Initiated',
    value: 3,
    color: 'orange',
  },
];


export const basicStatusConfig = [
  {
    label: 'In Progress',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Completed',
    value: 1,
    color: 'green',
  },
];

export const advancedStatusConfig = [
  {
    label: 'In Progress',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Completed',
    value: 1,
    color: 'green',
  },
];

export const suspensionStatusConfig = [
  {
    label: 'Not Applied',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Applied',
    value: 1,
    color: 'green',
  },
  {
    label: 'Not Applied',
    value: 2,
    color: 'orange',
  },
];
export const payrollHistoryStatusConfig = [
  {
    label: 'Pending',
    value: 0,
    color: 'orange',
  },
  {
    label: 'Successful',
    value: 1,
    color: 'green',
  },
  {
    label: 'Failed',
    value: 2,
    color: 'red',
  },
];

export const StatutoryConfig = [
  {
    label: 'Paid',
    value: 1,
    color: 'green',
  },
  {
    label: 'Not Paid',
    value: 2,
    color: 'orange',
  },
];

export const airtimeStatusConfig = [
  {
    label: 'Paid',
    value: 1,
    color: 'green',
  },
  {
    label: 'Not Paid',
    value: 2,
    color: 'orange',
  },
  
];
