import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from '../datatable';
import { StatutoryConfig, PAGINATION_DEFAULT } from '../../utils/constants';

import { useGetNhfHistory } from '../../redux/statutory-compliance/hook/nhf';

export const NhfTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: Nhf } = useGetNhfHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  useEffect(() => {
    if (Nhf) {
      console.log(Nhf, 'nhf');
    }
  }, [Nhf]);

  const history = useHistory();

  function Continue(id, type) {
    let index = Nhf.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === 'preview') {
        history.push(
          `/payroll/advanced/preview/${Nhf.data[index].ref}`,
        );
      } else if (type === 'view') {
        history.push(`/payroll/advanced/view/${Nhf.data[index].ref}`);
      }
    }
  }

  const tableConfig = {
    title: 'National Housing Fund (NHF)',
    headers: ['Date', 'Reference Number', 'Description', 'Month', 'Status'],
    keys: ['created', 'ref', 'desc', 'month', 'status'],
    mobileHeader: {
      left: {
        title: 'Date',
        key: 'created',
      },
      right: {
        title: 'Description',
        key: 'desc',
      },
    },
  };

  const statusConfig = [
    {
      label: 'In Progress',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Successful',
      color: 'green',
      value: 1,
    },
    
  ];

  const actionConfig = [
    {
      name: 'Pay Now',
      color: 'orange',
      action: (id) => {
        Continue(id, 'preview');
      },
    },
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        Continue(id, 'view');
      },
    },
  ];

  return (
    <div>
      <DataTable
        data={Nhf}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={StatutoryConfig}
        searchData={true}
        computedActions={true} 
      />
    </div>
  );
};

export default NhfTab;
