import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Button from "../../components/button";
import AuthLayout from "../../components/layouts/auth";
import TickSVG from '../../assets/svg/tick.svg';

const CheckEmailPassword = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.userSlice);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (user) return <Redirect to="/" />;

  return (
    <AuthLayout>
      <React.Fragment>
        <form className="form">
          <div className='flex flex-col w-full text-center justify-center pb-[25px]'>
            
            <div className='w-[100%] flex justify-center pb-[15px]'>
                <div
                className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-secondary-2`}
                >
                <img
                    src={TickSVG}
                    alt='tick'
                    style={{ width: '60px', height: '60px' }}
                />
                </div>
            </div>
            <span className='header-4-regular md:header-2 text-secondary-2 pb-1'>
                Email Sent
            </span>
            
            <span className='p4 md:header-4-regular text-color-gray'>
             An email has been sent to you, please use the link in your email to reset your password.
            </span>
          </div>
          <Button
            text="Go back to login page"
            type="submit"
            onClick={() => history.push(`/`)}
          />
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default CheckEmailPassword;
