import { Axios } from '../../axios';

const getTaxes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/taxes?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkTaxes = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/taxes/bulk', payload);
  return data;
};

const generateTaxOtp = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/taxes/generate-otp', payload);
  return data;
};

const fetchTaxByRef = async (payload) => {
  const { data } = await Axios.get('/statutory-payments/taxes/by-ref/' + payload);
  return data;
};

export const taxApis = {
    getTaxes,
    bulkTaxes,
    fetchTaxByRef,
    generateTaxOtp
};