import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddSalaryAdvance, useEditSalaryAdvance } from '../../../redux/employee/hook/salary-advance';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employee/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';

function AddSalaryAdvance({ isOpen, closeModal, refresh, saladData, title, buttonText }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [data, setData] = useState();

  const { mutateAsync: addSalaryAdvance, isLoading: addSalaryAdvanceloading } = useAddSalaryAdvance();
  const { mutateAsync: editSalaryAdvamnce, isLoading: editSalaryAdvanceloading } = useEditSalaryAdvance();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState()
  const [purposeEdit, setPurposeEdit] = useState()
  const [salaryId, setSalaryId] = useState()
  const [saladType, setSaladType] = useState()

  useEffect(() => {
    if (saladData) {
      console.log('single', saladData)
      setMonth(((saladData.month).split('-')[1]).toString())
      setYear(((saladData.month).split('-')[0]).toString()+'-')
      setAmountEdit(parseFloat(saladData.amount).toFixed(2))
      setPurposeEdit(saladData.purpose)
      setEmployee(saladData.employee)
      setEmployeeName(saladData.first_name + " " + saladData.last_name)
      setSalaryId(saladData.id)
      setSaladType('edit')
      
    } else {
      setSaladType('add')
      setMonth('01')
      setYear('2022-')
    }
  }, [saladData]);

  const submitForm = async (data) => {
    const amount = DOMPurify.sanitize(data?.amount);
    const purpose = DOMPurify.sanitize(data?.purpose);

    if (saladType === 'add') {
      let payload = {
        employee,
        amount,
        purpose,
        month: year + month,
      };
      await addSalaryAdvance(payload).then(() => {
        closeModal();
      });
    } else if (saladType === 'edit') {
      let editPayload = {
        id: salaryId,
        body: {
          employee,
          amount: amountEdit,
          purpose: purposeEdit,
          month: year + month,
          status: 2
        }
      }
      await editSalaryAdvamnce(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>{title}</span>
              <hr className='divider' />
            </div>
            <div>

              <FormSelectEmployee
                label={'Employee'}
                name='employee'
                placeholder='Select Employee'
                value={employee}
                readOnly={saladType==='edit'? true : false}
                empName={employeeName}
                setEmployee={setEmployee}
              />
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormSelect
                    value={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Select Month'
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    value={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Select Year'
                  />
                </div>
              </div>
              <FormInput
                label='Purpose'
                name='purpose'
                type='text'
                defaultValue={purposeEdit}
                inputRef={register(formValidation('text', true))}
                readOnly={addSalaryAdvanceloading || editSalaryAdvanceloading}
                onChange={(e) => {
                  setPurposeEdit(e.target.value);
                }}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
              />
              <FormInput
                label='Amount'
                name='amount'
                type='number'
                defaultValue={amountEdit}
                inputRef={register(formValidation('number', true))}
                readOnly={addSalaryAdvanceloading || editSalaryAdvanceloading}
                onChange={(e) => {
                  setAmountEdit(e.target.value);
                }}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
              />
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  saladType==='edit'? false:
                  !isValid || !employee? true:
                  false
                }
                loading={addSalaryAdvanceloading || editSalaryAdvanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddSalaryAdvance;
