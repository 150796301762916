import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { nsitfApis } from './../../../api/payroll/statutory-compliance/nsitf';

export function useGetNsitfHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['nsitf', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return nsitfApis.getNSITF({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((nsitf) => {
          nsitf.status = parseFloat(nsitf.nsitf_status)
          if (nsitf.status === 2) {
            nsitf.status = 0
          }
          switch (nsitf.status) {
            case 0:
              nsitf.computedActions = [0];
              break;
            case 1:
              nsitf.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadNsitf() {
  return useMutation(
    (payload) => {
      return nsitfApis.bulkNSITF(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('nsitf');
        store.dispatch(setAlert(true, 'success', 'Upload Successful'));
      },
    },
  );
}

export function useRefNSITF(payload) {
  return useQuery(
    ['nsitf-by-ref', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return nsitfApis.fetchNSITFByRef(payload);
    },
    {
      select: (data) => {
        // data.data.salaries.data.forEach((item) => {
        //   item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
        //   item.net_pay = item.net_pay.toFixed(2);
        //   item.total = (
        //     parseFloat(item.trans_cost) + parseFloat(item.net_pay)
        //   ).toFixed(2);
        // });

        // return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}