import React from 'react';
import Dashboard from '../pages/dashboard';
import SettingsPage from '../pages/settings';
import ProtectedRoute from './protected';
import Logout from '../pages/logout';
import EmployeePage from '../pages/employees';
import UsersPage from '../pages/users';
import CompaniesPage from '../pages/companies';
import PayrollPage from '../pages/payroll-management';
import TransactionsPage from '../pages/transactions';
import ViewEmployeePage from '../pages/employees/view-employee';
import StatutoryPaymentPage from '../pages/statutory-payments';
import MobileUsersPage from '../pages/mobile-users';
import TicketsPage from '../pages/tickets';
import ViewTicketPage from '../pages/tickets/view-ticket';
import RecruitmentPage from '../pages/recruitment';
import MobileTransactionsPage from '../pages/mobile-transactions';

export const AdminRoutes = () => {
  const NonSectionRoutes = [
    <ProtectedRoute exact path='/dashboard' component={Dashboard} />,
    <ProtectedRoute exact path='/mobile-users' component={MobileUsersPage} />,
    <ProtectedRoute exact path='/users' component={UsersPage} />,
    <ProtectedRoute exact path='/recruitments' component={RecruitmentPage} />,
    <ProtectedRoute exact path='/companies' component={CompaniesPage} />,
    <ProtectedRoute exact path='/employees' component={EmployeePage} />,
    <ProtectedRoute exact path='/employee/view/:id' component={ViewEmployeePage} />,
    <ProtectedRoute exact path='/payroll' component={PayrollPage} />,
    <ProtectedRoute exact path='/statutory-compliance' component={StatutoryPaymentPage} />,
    <ProtectedRoute exact path='/transactions' component={TransactionsPage} />,
    <ProtectedRoute exact path='/mobile-transactions' component={MobileTransactionsPage} />,
    <ProtectedRoute exact path='/settings' component={SettingsPage} />,
    <ProtectedRoute exact path='/tickets' component={TicketsPage} />,
    <ProtectedRoute exact path='/tickets/view/:id' component={ViewTicketPage} />,
    <ProtectedRoute exact path='/logout' component={Logout} />,
  ];
  return [...NonSectionRoutes];
};
