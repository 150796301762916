import React from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { bonusesStatusConfig, PAGINATION_DEFAULT } from '../../utils/constants';

import { useGetEmployeeBonuses } from './../../redux/employee/hook/bonuses';
import AddBonus from '../modal/employees/add-bonus';

export const EmployeeBonusTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: Bonuses } = useGetEmployeeBonuses(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const [showAddBonus, setShowAddBonus] = useState(false);
  const [bonusData, setBonusData] = useState();

  const tableConfig = {
    title: 'Bonus Management',
    headers: [
      'Date Created',
      'Employee Name',
      'Phone Number',
      'Purpose',
      'Month',
      'Amount',
      'Status',
    ],
    keys: ['created', 'employeeName', 'phone', 'purpose', 'month', 'amount', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'orange',
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        setBonusData(Bonuses.data[index])
        setShowAddBonus(true)
      },
    },
    {
      name: 'Delete',
      color: 'red',
      action: () => {
        
      },
    },
  ];

  return (
    <div>
      {showAddBonus && bonusData?
        <AddBonus
          isOpen={showAddBonus}
          closeModal={() => setShowAddBonus(false)}
          bonusData={bonusData}
          title='Edit Bonus'
          buttonText='UPDATE'
        />
      :
      null} 
      <DataTable
        data={Bonuses}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={bonusesStatusConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};
