import { Axios } from '../axios';

const getEmployeeGroups = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/employee-groups?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getEmployeeGroup = async (payload) => {
  const { data } = await Axios.get('/employee-groups/' + payload.id);
  return data?.data;
};

const getGroupEmployees = async (payload) => {
  const { data } = await Axios.get('/employee-groups/' + payload.id);
  return data?.data?.employees;
};

const getGroupSettings = async (payload) => {
  const { data } = await Axios.get('/employee-groups/' + payload.id);
  return data?.data;
};

const addEmployeeGroup = async (payload) => {
  const { data } = await Axios.post('/employee-groups', payload);
  return data;
};

const editEmployeeGroup = async (payload) => {
  const { data } = await Axios.put('/employee-groups/'+payload.id, payload.body);
  return data;
};

const addEmployeesToGroup = async (payload) => {
  const { data } = await Axios.post('/employee-groups/add-employee', payload);
  return data;
};

const removeEmployeesFromGroup = async (payload) => {
  const { data } = await Axios.post(
    '/employee-groups/remove-employee',
    payload,
  );
  return data;
};

export const employeeGroups = {
  getEmployeeGroups,
  getEmployeeGroup,
  addEmployeesToGroup,
  removeEmployeesFromGroup,
  addEmployeeGroup,
  editEmployeeGroup,
  getGroupEmployees,
  getGroupSettings
};
