import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useChargeCompany, useGetCompanies, usePayPensions, usePayTaxes, useRefundCompanies } from '../../redux/company/hook';
import Button from '../../components/button';
import SendEmail from '../../components/modal/emails/send-email';


const CompaniesPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const { mutateAsync: payPension, isLoading: pensionLoading } = usePayPensions();
  const { mutateAsync: payTax, isLoading: taxLoading } = usePayTaxes();

  const { mutateAsync: refundCompanies, isLoading: refundLoading } = useRefundCompanies();
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    start_date: '',
    end_date: '',
    search: '',
  });

  const { data: companies } = useGetCompanies(
    pagination?.limit,
    pagination?.page,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.search,
  );

  useEffect(() => {

    if(companies) {
      console.log(companies)
    }
    
  }, [companies]);

  const [showSendEmail, setShowSendEmail] = useState(false);
  const { mutateAsync: chargeCompany} = useChargeCompany();

  const tableConfig = {
    headers: ['Date Created', 'Name', 'Email', 'Mobile', 'Balance', ],
    keys: ['created', 'company_name', 'email', 'mobile', 'total_balance'],
    mobileHeader: {
      left: {
        title: 'Company Created',
        key: 'created',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Collect Fees',
      color: 'red',
      action: async (id) => {
        let index = companies.data.findIndex((item) => item.id === id);
        await chargeCompany(companies.data[index].id)
      },
    },
  ];

  return (
    <DashboardLayout title='Companies'>
      <div className='flex flex-col'>
        {showSendEmail ?
          <SendEmail
            title={'Send Email To Companies'}
            isOpen={showSendEmail}
            closeModal={() => setShowSendEmail(false)}
            type={'company'}
            buttonText='Send Email'
          />
        : null}
        <div className='flex flex-col bg-white'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Companies
            </div>
            <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Email'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {
                    setShowSendEmail(true)
                  }}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Pay Pensions'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {payPension(true)}}
                  loading={pensionLoading}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Pay Tax'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {payTax()}}
                  loading={taxLoading}
                />
              </div>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Refund Companies'
                  type='button'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  textClass={'!text-[13px]'}
                  onClick={() => {refundCompanies()}}
                  loading={refundLoading}
                />
              </div>
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={companies}
            tableConfig={tableConfig}
            actionConfig={actionConfig}
            updatePagination={(data) => setPagination(data)}
            filterByDate={dateConfig}
            searchData={true}
            computedActions={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CompaniesPage;
