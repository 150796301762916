import { admin, user } from '../../api';
import { store } from '..';
import { clearToken, clearUser, setToken, setUser } from './user-slice';
import { Axios } from '../../api/axios';
import { queryClient } from '../..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';

  

export function useLogin() {
  return useMutation(
    (payload) => {
      return admin.login(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Admin Logged in successfully'));
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function useGetAdmins(limit, page, search) {
  return useQuery(
    ['admins', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return admin.getAdmins({ limit, page, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((admin) => {
          admin.created = admin.created.split('T')[0]
          admin.computedActions = [0]
        });
        return data;
      },
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Admins Fetched Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetAdmin(id) {
  return useQuery(
    ['admin', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return admin.getAdmin(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetUser(id) {
  return useQuery(
    ['admin-profile', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return admin.profile(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddAdmin() {
  return useMutation(
    (payload) => {
      return admin.addAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('admins');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useUpdateAdmin() {
  return useMutation(
    (payload) => {
      return admin.updateAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function useDeleteAdmin() {
  return useMutation(
    (payload) => {
      return admin.removeAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function useActivateAdmin() {
  return useMutation(
    (payload) => {
      return admin.activateAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function useResendActivationLink() {
  return useMutation(
    (payload) => {
      return admin.resendActivationLiink(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFetchAdminByToken(id) {
  return useQuery(
    ['admin', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return admin.fetchAdminByToken(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSetAdminPassword() {
  return useMutation(
    (payload) => {
      return admin.adminPasswordSet(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function useForgotPassword() {
  return useMutation(
    (payload) => {
      return admin.forgotPassword(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export function usePasswordReset() {
  return useMutation(
    (payload) => {
      return admin.passwordReset(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
        store.dispatch(setUser(response.data));
      },
    },
  );
}

export const logout = async () => {
  store.dispatch(clearUser());
  store.dispatch(clearToken());
  queryClient.invalidateQueries();
  delete Axios.defaults.headers.common['Authorization'];
};
