import { Axios } from '../axios';

const getEmployeeBonuses = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/bonuses?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const addBonus = async (payload) => {
  const { data } = await Axios.post('bonuses', payload);
  return data;
};
const editBonus = async (payload) => {
  const { data } = await Axios.put('bonuses/'+payload.id, payload.body);
  return data;
};

const bulkAddBonus = async (payload) => {
  const { data } = await Axios.post('/bonuses/bulk', payload);
  return data;
};

const getSingleEmployeeBonuses = async (payload) => {
  const { data } = await Axios.get(
    `/bonuses/employee/` + payload.id,
  );

  return data?.data;
};

export const employeeBonuses = {
  getEmployeeBonuses,
  addBonus,
  bulkAddBonus,
  getSingleEmployeeBonuses,
  editBonus,
};
