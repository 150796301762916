import React from 'react';
import Info from './info';

const FormTextArea = ({
  id,
  name,
  label,
  type,
  placeholder,
  accept,
  defaultValue,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  className,
  ...rest
}) => {
  return (
    <div className='pb-[20px] flex flex-col'>
      <div className='flex flex-col'>
        {label && (
          <label className='text-[18px] leading-[23px] font-normal text-color-gray'>
            {label}
          </label>
        )}

        <textarea
          name={name}
          type={type}
          placeholder={placeholder}
          accept={accept}
          className={`bg-color-off-white rounded-md p-[20px] border-[0.5px] border-color-gray mt-[10px] text-color-black ${className}`}
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          readOnly={readOnly ? true : false}
          {...rest}
        >
          {defaultValue}
        </textarea>
      </div>
      {error && <span className='text-error'>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormTextArea;
