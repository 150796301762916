import { Axios } from '../axios';

const getEmployeeSalaries = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/salaries?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const addSalary = async (payload) => {
  const { data } = await Axios.post('/salaries', payload);
  return data;
};

const editSalary = async (payload) => {
  const { data } = await Axios.put('/salaries/' + payload.id, payload.body);
  return data;
};

const getEmployeeSalary = async (payload) => {
  const { data } = await Axios.get('/salaries/' + payload);
  return data?.data;
};

const getSalaryByEmployee = async (payload) => {
  const { data } = await Axios.get('/salaries/by-employee/' + payload);
  return data?.data;
};

export const employeeSalaries = {
  getEmployeeSalaries,
  addSalary,
  getEmployeeSalary,
  editSalary,
  getSalaryByEmployee,
};
