import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useGetEmployeeSalaries } from '../../redux/employee/hook/salaries';
import RunBasicPayroll from '../modal/payroll/run-basic-payroll';
import RunAdvancedPayroll from '../modal/payroll/run-advanced-payroll';

const EmployeeSalariesTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: employeeSalaries } = useGetEmployeeSalaries(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );
  // const { mutateAsync: bulkActivate } = useBulkActivateEmployees();
  // const { mutateAsync: bulkDeactivate } = useBulkDeactivateEmployees();
  // const { mutateAsync: ActivateOrDeactivate } =
  //   useActivateOrDeactivateEmployee();
  const [showBasicPayrollOptions, setShowBasicPayrollOptions] = useState(false);
  const [showAdvancedPayrollOptions, setShowAdvancedPayrollOptions] =
    useState(false);
  const [payrollData, setPayrollData] = useState('');

  // useEffect(() => {
  //   // console.log('employee salaries', employeeSalaries);
  // }, [employeeSalaries]);

  const history = useHistory();

  const statusConfig = [
    {
      label: 'Inactive',
      value: 0,
      color: 'orange',
    },
    {
      label: 'Active',
      value: 1,
      color: 'green',
    },
    {
      label: 'Pending',
      value: 2,
      color: 'orange',
    },
    {
      label: 'Deleted',
      value: 3,
      color: 'red',
    },
  ];

  const tableConfig = {
    title: 'Salary Management',
    headers: [
      'Date Created',
      'Employee Name',
      'Mobile',
      'Basic',
      'Transportation',
      'Housing',
      'Others',
      'Gross pay',
    ],
    keys: [
      'created', 
      'employeeName',
      'phone',
      'basic_salary',
      'transportation_allowance',
      'housing_allowance',
      'other_accum_allowance',
      'gross_pay',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Mobile',
        key: 'phone',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);

        if (index != -1) {
          history.push(
            '/employee/view/' + employeeSalaries.data[index].employee,
          );
        }
      },
    },
    // {
    //   name: 'Edit',
    //   color: 'orange',
    //   action: () => {},
    // },
  ];

  function openBasicPayroll(data) {
    let newData = data.split(',');
    let result = [];

    newData.forEach((id) => {
      let index = employeeSalaries.data.findIndex((item) => item.id == id);
      if (index != -1) {
        result.push(employeeSalaries.data[index].employee);
      }
    });

    setPayrollData(result.join(','));
    setShowBasicPayrollOptions(true);
  }

  function openAdvancedPayroll(data) {
    let newData = data.split(',');
    let result = [];

    newData.forEach((id) => {
      let index = employeeSalaries.data.findIndex((item) => item.id == id);
      if (index != -1) {
        result.push(employeeSalaries.data[index].employee);
      }
    });

    setPayrollData(result.join(','));
    setShowAdvancedPayrollOptions(true);
  }

  const bulkActionConfig = [
    {
      label: 'Basic Payroll',
      value: 'basic-payroll',
      action: (data) => openBasicPayroll(data),
    },
    {
      label: 'Advanced Payroll',
      value: 'advanced-payroll',
      action: (data) => openAdvancedPayroll(data),
    },
    {
      label: 'Delete',
      value: 'delete',
      action: () => {},
    },
  ];

  return (
    <div>
      <RunBasicPayroll
        isOpen={showBasicPayrollOptions}
        source='selective'
        sourceData={payrollData}
        closeModal={() => setShowBasicPayrollOptions(false)}
      />

      <RunAdvancedPayroll
        isOpen={showAdvancedPayrollOptions}
        source='selective'
        sourceData={payrollData}
        closeModal={() => setShowAdvancedPayrollOptions(false)}
      />

      <DataTable
        data={employeeSalaries}
        tableConfig={tableConfig}
        // statusConfig={statusConfig}
        actionConfig={actionConfig}
        bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
      />
    </div>
  );
};

export default EmployeeSalariesTab;
