import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '..';
import { queryClient } from '../..';
import { walletInformation } from '../../api/wallet';
import { setAlert, setIsLoading } from '../components/components-slice';

export function useGetWalletHistory(limit, page, start_date, end_date, statusFilter, search, type ) {
  return useQuery(
    ['wallet-history', { limit, page, start_date, end_date, statusFilter, search, type }],
    () => {
      store.dispatch(setIsLoading(true));
      return walletInformation.getWalletHistory({
        limit,
        page,
        start_date,
        end_date,
        statusFilter, 
        search, 
        type
      });
    },

    {
      select(data) {
        data?.data?.forEach((data) => {
          data.created = data.created.split('T').join(' ').split('.')[0]
          if (data.type === 'wallet') {
            data.credit = data.amount
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.credit = ''
            data.debit = data.amount
          }
          if (data.status == 0) {
            data.computedActions = [0]
          }

        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetMobileWalletHistory(limit, page, start_date, end_date, statusFilter, search, type ) {
  return useQuery(
    ['wallet-history', { limit, page, start_date, end_date, statusFilter, search, type }],
    () => {
      store.dispatch(setIsLoading(true));
      return walletInformation.getMobileWalletHistory({
        limit,
        page,
        start_date,
        end_date,
        statusFilter, 
        search, 
        type
      });
    },

    {
      select(data) {
        data?.data?.forEach((data) => {
          data.user_name = data.user.first_name + ' ' + data.user.last_name
          data.created = data.created.split('T').join(' ').split('.')[0]
          if (data.type === 'credit') {
            data.credit = data.amount
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.credit = ''
            data.debit = data.amount
          }
          if (data.status == 0) {
            data.computedActions = [0]
          }

        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}




