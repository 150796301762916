import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { employees } from '../../../api';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setEmployee } from '../reducer';



export function useGetEmployeeSummary() {
  return useQuery(
    ['employee-summary'],
    () => {
      return employees.getEmployeeSummary();
    }
  );
}

export function useGetEmployees(limit, page, statusFilter, search) {
  return useQuery(
    ['employees', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.created = employee.created?.split('T')[0];
          employee.computedActions = [0, 1, 2, 3];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetEmployee(id) {
  return useQuery(
    ['employee', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployee(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInviteSingleEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.inviteSingleEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInviteBulkEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.inviteBulkEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.createSingleEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateInvitedEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateInvitedEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateBulkEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.createBulkEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.addEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useNetPayReverse() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.netPayReverse(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useNetPayReverseBulk() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.netPayReverseBulk(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGrossPayReverse() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.grossPayReverse(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGrossPayReverseBulk() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.grossPayReverseBulk(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkActivateEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.bulkActivateEmployees(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateOrDeactivateEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.activateOrDeactivateEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkDeactivateEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.bulkDeactivateEmployees(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.generateEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.confirmEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
