
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import calendarIcon from "../assets/svg/calendar-icon.svg"
import calendarArrow from "../assets/svg/date-arrow-right.svg"

function FormDateRangePicker({ start, end, setStartDate, setEndDate, extraClass, label, className, ...rest }) {
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month")
      ],
      "Last Year": [
        moment()
          .subtract(1, "year")
          .startOf("year"),
        moment()
          .subtract(1, "year")
          .endOf("year")
      ]
    };

    const onApplyClick = (event, picker) => {
      setStartDate(moment(picker.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(picker.endDate).format('YYYY-MM-DD'));
    };

    return (
        <div className={`flex flex-row `}>
          <DateRangePicker
          initialSettings={{
            ranges: ranges,
            alwaysShowCalendars: false,
          }}
          onApply={onApplyClick}
          onCallback={onApplyClick}
          >
            <button
                className={`flex flex-row md:gap-4 content-center justify-between gap-8 w-full bg-color-off-white rounded-md !min-w-fit h-[35px] mb-3 pr-2 pl-2 pt-[12px] mt-[10px] !border-color-gray border-[0.01rem]`}
                {...rest}
            >
                <div className="flex flex-row text-color-gray">
                  <div className="flex flex-row text-color-gray">
                    {start}
                  </div>
                  <div>
                    <img src={calendarArrow} alt={'darr'} className={'flex justify-end pr-2 pl-2'} />
                  </div>
                  <div className="flex flex-row text-color-gray">
                    {end}
                  </div>  
                </div>
                <img src={calendarIcon} alt={'Refresh'} className={'flex justify-end text-color-gray'} />
            </button>
          </DateRangePicker>
        </div>
    );
}

export default FormDateRangePicker;