import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useSendCompanyEmail, useSendEmployeeEmail, useSendUserEmail } from '../../../redux/email/hook';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import { Editor } from '@tinymce/tinymce-react';

function SendSingleEmail ({ isOpen, closeModal, refresh, type, id, title }) {
  const {
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

    const [subject, setSubject] = useState()
    const [body, setBody] = useState()
    const [closingRemark, setClosingRemark] = useState('Regards,')
    const [salutation, setSalutation] = useState('Hello ');
    
    const { mutateAsync: sendCompanyEmail, isLoading: sendCompanyEmailLoading } =
        useSendCompanyEmail();
    const { mutateAsync: sendUserEmail, isLoading: sendUserEmailLoading } =
        useSendUserEmail();
    const { mutateAsync: sendEmployeeEmail, isLoading: sendEmployeeEmailLoading } =
        useSendEmployeeEmail();

    const salutationOptions = [
        {
            label: 'Hello',
            value: 'Hello',
        },
        {
            label: 'Hi',
            value: 'Hi',
        },
        {
            label: 'Hey',
            value: 'Hey',
        },
        {
            label: 'Dear',
            value: 'Dear',
        },
        {
            label: 'Dear Mr.',
            value: 'Dear Mr.',
        },
        {
            label: 'Dear Mrs.',
            value: 'Dear Mrs.',
        },
    ];

    const closingRemarkOptions = [
        {
            label: 'Kind regards',
            value: 'Kind regards,',
        },
        {
            label: 'Thank you',
            value: 'Thank you,',
        },
        {
            label: 'Looking forward to meeting you',
            value: 'Looking forward to meeting you,',
        },
        {
            label: 'Best',
            value: 'Best,',
        },
        {
            label: 'Sincerely',
            value: 'Sincerely,',
        },
        {
            label: 'Regards',
            value: 'Regards,',
        },
        {
            label: 'Cheers',
            value: 'Cheers,',
        },
    ];

    const submitForm = async (data) => {
        if (type === 'company') {
            let payload = {
                id: id,
                data: {
                    salutation: salutation,
                    subject,
                    body: body.toString(),
                    closing_remark: closingRemark
                } 
            }
            await sendCompanyEmail(payload).then(()=> {
                closeModal()
            });
        } else if (type === 'employee') {
            let payload = {
                id: id,
                data: {
                    salutation: salutation,
                    subject,
                    body: body.toString(),
                    closing_remark: closingRemark
                } 
            }
            await sendEmployeeEmail(payload).then(()=> {
                closeModal()
            });
        } else {
            let payload = {
                id: id,
                data: {
                    salutation: salutation,
                    subject,
                    body: body.toString(),
                    closing_remark: closingRemark,
                    type
                } 
            }
            await sendUserEmail(payload).then(()=> {
                closeModal()
            });
        }
    };

    return (
        <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
        <div className='flex flex-col pb-4 overflow-auto'>
            <div className='pl-8 pr-8 pt-8'>
            <div className='flex flex-row justify-between'>
                <span className='header-3'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
                </div>
            </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
                <form className='form' >
                    <FormInput
                        label='Subject'
                        name='subject'
                        type='text'
                        value={subject}
                        placeholder={'Email Subject'}
                        onInput={(e) => {
                            setSubject(e.target.value)
                        }}
                        error={errors.subject}
                        errorMessage={errors.subject && errors.subject.message}
                    />
                    <div className='flex md:flex-row flex-col w-full justify-between'>
                        <div className='w-full'>
                            <FormSelect
                                value={salutation}
                                options={salutationOptions}
                                onChange={(selected) => {
                                setSalutation(selected);
                                }}
                                label='salutation'
                            />
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                        <div className='w-full'>
                            <span className='text-color-gray text-[15px] pb-2'>Body</span>
                            <Editor
                                apiKey={'0m0v3d8ckewvqyou34thh7zx8d07pdgxhaa8nehbn7xrkbsv'}
                                onEditorChange={(editor) => {
                                setBody(editor)
                                }}
                                value={body}
                                init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                    <FormSelect
                        defaultValue={closingRemark}
                        options={closingRemarkOptions}
                        onChange={(selected) => {
                            setClosingRemark(selected);
                        }}
                        label='Closing Remark'
                    />
                    <div className='w-full mt-[20px]'>
                        <Button
                        text={'Send Email'}
                        type='button'
                        onClick={() => submitForm()}
                        loading={sendCompanyEmailLoading || sendUserEmailLoading || sendEmployeeEmailLoading}
                        disabled={!isValid}
                        />
                    </div>
                </form>
            </div>
        </div>
        </Modal>
    );
}

export default SendSingleEmail;
