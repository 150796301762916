import { Axios } from '../../axios';

const getNHIS = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/nhis?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkNHIS = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/nhis/bulk', payload);
  return data;
};

const fetchNHISByRef = async (payload) => {
  const { data } = await Axios.get('/statutory-payments/nhis/by-ref/' + payload);
  return data;
};

export const nhisApis = {
    getNHIS,
    bulkNHIS,
    fetchNHISByRef,
};