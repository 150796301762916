import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeBonuses } from './../../../api/employees/bonuses';

export function useGetEmployeeBonuses(limit, page, search) {
  return useQuery(
    ['employee-bonuses', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.getEmployeeBonuses({
        limit,
        page,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((employee) => {
          employee.amount =  parseFloat(employee.amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.created = employee.created?.split('T')[0];
          employee.computedActions = [0, 1, 2];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddBonus() {
  return useMutation(
    (payload) => {
      return employeeBonuses.addBonus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
    },
  );
}

export function useEditBonus() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.editBonus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, response.status, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddBonus() {
  return useMutation(
    (payload) => {
      return employeeBonuses.bulkAddBonus(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
    },
  );
}

export function useSingleGetEmployeeBonuses(id) {
  return useQuery(
    ['single-employee-bonuses', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.getSingleEmployeeBonuses({ id });
    },
    {
      select(data) {
        data?.data?.forEach((employee) => {
          employee.amount =  employee.amount? parseFloat(employee.amount).toFixed(2): 0;
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;

          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1, 2];
              break;
            case 1:
              employee.computedActions = [0];
              break;
            case 2:
              employee.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
