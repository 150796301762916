import DOMPurify from 'dompurify';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddAdmin } from '../../../redux/admin/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';

function AddAdminInfo({ isOpen, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  
  const { mutateAsync: addAdmin, isLoading: addAdminLoading } = useAddAdmin();
  
  const submitForm = async (data) => {
    const firstname = DOMPurify.sanitize(data?.first_name);
    const lastname = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);

    let payload = {
      first_name: firstname,
      last_name: lastname,
      email,
      username: email,
      mobile,
    };
    addAdmin(payload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-8'>
              <div className='flex flex-row justify-between'>
                <span className='header-3'>Add New Admin</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
              <div className='flex flex-col md:flex-col w-full justify-between'>
                <FormInput
                  label='First Name'
                  name='first_name'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.first_name}
                  errorMessage={
                    errors.first_name && errors.first_name.message
                  }
                />
                <FormInput
                  label=' Last Name'
                  name='last_name'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.last_name}
                  errorMessage={errors.last_name && errors.last_name.message}
                />
                <FormInput
                  label='Email'
                  name='email'
                  type='email'
                  inputRef={register(formValidation('email', true))}
                  error={errors.email}
                  errorMessage={errors.email && errors.email.message}
                />

                <FormInput
                  label='Phone Number'
                  name='mobile'
                  type='text'
                  inputRef={register(formValidation('text'))}
                  error={errors.mobile}
                  errorMessage={errors.mobile && errors.mobile.message}
                />
              </div>

              <div className='w-full mt-[20px]'>
                <Button text='SUBMIT' type='submit' disabled={!isValid} loading={addAdminLoading}/>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddAdminInfo;
