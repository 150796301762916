import { store } from '..';
import { setIsLoading } from '../components/components-slice';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { recruitment } from '../../api/hr/recruitment';


export function useGetRecruitments(limit, page, statusFilter, search, start_date, end_date) {
    return useQuery(
      ['recruitments', { limit, page, statusFilter, search, start_date, end_date }],
      () => {
        store.dispatch(setIsLoading(true));
        return recruitment.getRecriuitments({ limit, page, statusFilter, search, start_date, end_date });
      },
      {
        select: (data) => {
          data?.data?.forEach((ticket) => {
            ticket.created = moment(ticket.created).format('MMMM DD, YYYY');
            ticket.computedActions = [0, 1];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}