import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddSuspension, useEditSuspension } from '../../../redux/employee/hook/suspensions';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employee/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';

function AddSuspension({ isOpen, closeModal, refresh, suspData, title, buttonText }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [payment_status, setPaymentStatus] = useState(2);

  const { mutateAsync: addSuspension, isLoading: addSuspensionloading } = useAddSuspension();
  const { mutateAsync: editSuspension, isLoading: editSuspensionloading } = useEditSuspension();
  const [employee, setEmployee] = useState();
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [employeeName, setEmployeeName] = useState();
  const [purposeEdit, setPurposeEdit] = useState()
  const [suspId, setSuspId] = useState()
  const [suspType, setSuspType] = useState()

  useEffect(() => {
    if (suspData) {
      console.log('single', suspData)
      setStartDate(suspData.start_date)
      setEndDate(suspData.end_date)
      setPurposeEdit(suspData.purpose)
      setEmployee(suspData.employee)
      setEmployeeName(suspData.first_name + " " + suspData.last_name)
      setSuspId(suspData.id)
      setPaymentStatus(suspData.payment_status)
      setSuspType('edit')
      
    } else {
      setSuspType('add')
    }
  }, [suspData]);

  const submitForm = async (data) => {
    const purpose = DOMPurify.sanitize(data?.purpose);
    const start_date = DOMPurify.sanitize(data?.start_date);
    const end_date = DOMPurify.sanitize(data?.end_date);

    if (suspType === 'add') {
      let payload = {
        employee,
        purpose,
        start_date,
        end_date,
        payment_status,
      };
      await addSuspension(payload).then(() => {
        closeModal();
      });
    } else if (suspType === 'edit') {
      let editPayload = {
        id: suspId,
        body: {
          employee,
          start_date: startDate,
          end_date: endDate,
          purpose: purposeEdit,
          payment_status
        }
      }
      await editSuspension(editPayload).then(() => {
        closeModal();
      });
    }
  };

  const paymentOptions = [
    {
      value: 1,
      label: 'With Payment',
    },
    {
      value: 2,
      label: 'Without Payment',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form p-3' onSubmit={handleSubmit(submitForm)}>
          <div className='flex justify-end'>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
          <div className='flex flex-col mx-8'>
            <div>
              <span className='header-3'>{title}</span>
              <hr className='divider' />
            </div>

            <div>
              <FormSelectEmployee
                label={'Employee'}
                name='employee'
                placeholder='Select Employee'
                value={employee}
                readOnly={suspType==='edit'? true : false}
                empName={employeeName}
                setEmployee={setEmployee}
              />
              <FormInput
                label='Purpose'
                name='purpose'
                type='text'
                inputRef={register(formValidation('text', true))}
                readOnly={addSuspensionloading || editSuspensionloading}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
                defaultValue={purposeEdit}
                onChange={(e) => {
                  setPurposeEdit(e.target.value);
                }}
              />

              <div className='flex flex-row md:flex-col w-full justify-between z-[1] h-full'>
                <div className='w-full md:w-1/2'>
                <FormInput
                label='Start Date'
                name='start_date'
                type='date'
                defaultValue={startDate}
                inputRef={register(formValidation('text', true))}
                readOnly={addSuspensionloading || editSuspensionloading}
                error={errors.start_date}
                errorMessage={errors.start_date && errors.start_date.message}
                  />
                </div>

                <div className='w-full md:w-1/2 ml-2'>
                <FormInput
                label='End Date'
                name='end_date'
                type='date'
                defaultValue={endDate}
                inputRef={register(formValidation('text', true))}
                readOnly={addSuspensionloading || editSuspensionloading}
                error={errors.end_date}
                errorMessage={errors.end_date && errors.end_date.message}
                  />
                </div>
              </div>

              <FormSelect
                defaultValue={payment_status}
                options={paymentOptions}
                onChange={(selected) => {
                  setPaymentStatus(selected);
                }}
                label='Payment Status'
              />
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={!isValid || !employee}
                loading={addSuspensionloading || editSuspensionloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddSuspension;
