import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { payroll } from '../../../api/payroll/payroll';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetAdvancedPayroll(limit, page, statusFilter, search) {
  return useQuery(
    ['advanced-payrolls', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getAdvancedPayrolls({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((payroll) => {
          payroll.created = payroll.created.split('T')[0];
          payroll.status = payroll.status ? parseFloat(payroll.status) : 0;

          switch (payroll.status) {
            case 0:
              payroll.computedActions = [0, 2, 3];
              break;
            case 1:
              payroll.computedActions = [1, 2];
              break;
            case 2:
              payroll.computedActions = [2, 4];
              break;
            // case 3:
            //   payroll.computedActions = [1];
            //   break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useCheckBulkTransactionStatus() {
  return useMutation(
    () => {
      return payroll.refreshTransactionStatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        store.dispatch(setAlert(true, 'success', 'All Statuses Refreshed Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetBasicPayroll(limit, page, statusFilter, search) {
  return useQuery(
    ['basic-payrolls', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getBasicPayrolls({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((payroll) => {
          payroll.created = payroll.created.split('T')[0];
          payroll.status = payroll.status ? parseFloat(payroll.status) : 0;

          switch (payroll.status) {
            case 0:
              payroll.computedActions = [0, 2];
              break;
            case 1:
              payroll.computedActions = [1];
              break;
            case 2:
              payroll.computedActions = [3];
              break;
            // case 3:
            //   payroll.computedActions = [1];
            //   break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPayrollHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'payroll-history',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getPayrollHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.status = parseFloat(transaction.status);
          transaction.created = transaction.created
            .split('.')[0]
            .replace('T', ' ');
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefBasicPayroll(refId, limit, page, statusFilter, search) {
  return useQuery(
    ['basic-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchBasicPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.computedActions = [0, 1, 2];
          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.net_pay = parseFloat(item.net_pay).toFixed(2);
          item.trans_cost
            ? (item.trans_cost = parseFloat(item.trans_cost))
            : (item.trans_cost = 44.99);
          item.total = (
            item.trans_cost + parseFloat(item.net_pay)
          ).toLocaleString();
          item.net_pay = item.net_pay.toLocaleString();
          if (!item.status) {
            item.status = 0;
          }
        });
        data.total_net_pay = data?.total_net_pay.toLocaleString();
        data.total_trans_cost = data?.total_trans_cost.toLocaleString();
        data.total_payable = data?.total_payable.toLocaleString();
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefBasicPayrollView(
  refId,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['basic-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchBasicPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          if (item.processed === 1) {
            if (item.status === 2) {
              item.computedActions = [1, 2];
            } else if (item.status === 0) {
              item.computedActions = [0, 1];
            } else if (item.status === 1) {
              item.computedActions = [1];
            }
          }
          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.net_pay = parseFloat(item.net_pay).toFixed(2);
          item.trans_cost
            ? (item.trans_cost = parseFloat(item.trans_cost))
            : (item.trans_cost = 44.99);
          item.total = (
            item.trans_cost + parseFloat(item.net_pay)
          ).toLocaleString();
          item.net_pay = item.net_pay.toLocaleString();
          if (!item.status) {
            item.status = 0;
          }
        });
        data.total_net_pay = data?.total_net_pay.toLocaleString();
        data.total_trans_cost = data?.total_trans_cost.toLocaleString();
        data.total_payable = data?.total_payable.toLocaleString();
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefAdvancedPayroll(
  refId,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['advanced-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchAdvancedPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          if (!item.status) {
            item.status = 0;
          }
          item.other_accum_allowance =
            parseFloat(item.basic_salary) +
            parseFloat(item.benefit_in_kind) +
            parseFloat(item.bonus) +
            parseFloat(item.housing_allowance) +
            parseFloat(item.transport_allowance) +
            parseFloat(item.leave_allowance) +
            parseFloat(item.lunch_allowance) +
            parseFloat(item.other_allowance) +
            parseFloat(item.utility_allowance);
          item.bank_info = `${item.bank_name}`;
          item.trans_cost = item.trans_cost ? parseFloat(item.trans_cost) : 0;
          item.total = (
            item.trans_cost + parseFloat(item.net_pay)
          ).toLocaleString();

          item.status = parseInt(item.status);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);

          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.other_accum_allowance = parseFloat(
            item.other_accum_allowance,
          ).toFixed(2);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);
          item.benefit_in_kind = parseFloat(item.benefit_in_kind).toFixed(2);
          item.bonus = parseFloat(item.bonus).toFixed(2);
          item.housing_allowance = parseFloat(item.housing_allowance).toFixed(
            2,
          );
          item.transport_allowance = parseFloat(
            item.transport_allowance,
          ).toFixed(2);
          item.leave_allowance = parseFloat(item.leave_allowance).toFixed(2);
          item.lunch_allowance = parseFloat(item.lunch_allowance).toFixed(2);
          item.other_allowance = parseFloat(item.other_allowance).toFixed(2);
          item.utility_allowance = parseFloat(item.utility_allowance).toFixed(
            2,
          );
          item.gross_pay = parseFloat(item.gross_pay).toFixed(2);
          item.pension = parseFloat(item.pension).toFixed(2);
          item.employer_pension = parseFloat(item.employer_pension).toFixed(2);
          item.salary_advance = parseFloat(item.salary_advance).toFixed(2);
          item.tax = parseFloat(item.tax).toFixed(2);
          item.nhf = parseFloat(item.nhf).toFixed(2);
          item.nsitf = parseFloat(item.nsitf).toFixed(2);
          item.itf = parseFloat(item.itf).toFixed(2);
          item.nhis = parseFloat(item.nhis).toFixed(2);
          item.employer_nhis = parseFloat(item.employer_nhis).toFixed(2);
          item.reimburse = parseFloat(item.reimburse).toFixed(2);
          item.net_pay = parseFloat(item.net_pay).toFixed(2);

          switch (item.status) {
            case 0:
              item.computedActions = [0, 1, 2];
              break;
            case 1:
              item.computedActions = [1, 2];
              break;
            case 2:
              item.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });
        data.total_net_pay = data?.total_net_pay.toLocaleString();
        data.total_trans_cost = data?.total_trans_cost.toLocaleString();
        data.total_payable = data?.total_payable.toLocaleString();

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRecalculateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.recalculateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollSelective() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollSelective(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollExcel() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollExcel(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheck() {
  return useQuery(
    ['advanced-payroll-check'],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.advancedPayrollCheck();
    },
    {
      enabled: false,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckGroup(payload) {
  return useQuery(
    ['advanced-payroll-check-group', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.advancedPayrollCheckGroup(payload);
    },
    {
      enabled: false,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckSelective() {
  return useMutation((payload) => {
    return payroll.advancedPayrollCheckSelective(payload);
  });
}

export function useRunAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendPayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendPayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendSinglePayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendSinglePayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollSelective() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollSelective(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.disburseBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.disburseAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRevertPayroll() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Payroll With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckTransactionStatus() {
  return useMutation(
    (payload) => {
      return payroll.checkTransactionStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        queryClient.invalidateQueries('vendor-history');
        store.dispatch(
          setAlert(true, 'success', 'Status Updated Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefreshBulkTransactionStatus() {
  return useMutation(
    () => {
      return payroll.refreshTransactionStatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        store.dispatch(
          setAlert(true, 'success', 'All Statuses Refreshed Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveBasicPayrollById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.removeBasicPayrollByRef(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payroll-by-ref');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditBaisicPayrollById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.editBasicPayrollById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payroll-by-ref');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.declineBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.declineAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
