import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetRecruitments } from '../../redux/recruitment/hook';
import RecruitmentDetailsModal from '../../components/modal/recruitment/view-recruitment';

const RecruitmentPage = () => {

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    start_date: '',
    end_date: '',
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const [showRecrutimentDetails, setShowRecruitmentDetails] = useState(false);
  const [recruitment, setRecruitment] = useState();

  const { data: recruitments } = useGetRecruitments(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  const tableConfig = {
    headers: [
      'Date Created',
      'Company',
      'Role',
      'Job Type',
      'Location Type',
      'Application Close',
    ],
    keys: ['created', 'company_name', 'role', 'job_type','location_type', 'application_end'],
    mobileHeader: {
      left: {
        title: 'Role',
        key: 'role',
      },
      right: {
        title: 'Application Close',
        key: 'application_end',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View Recruitment',
      color: 'green',
      action: async (id) => {
        let index = recruitments.data.findIndex((item) => item.id === id);
        setRecruitment(recruitments.data[index])
        setShowRecruitmentDetails(true)
      },
    },
  ];

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  return (
    <DashboardLayout title='Recruitments'>
      {showRecrutimentDetails ?
        <RecruitmentDetailsModal
          isOpen={showRecrutimentDetails}
          closeModal={() => setShowRecruitmentDetails(false)}
          recruitmentData={recruitment}
          title='Recruitment Details'
        />
      : null}
      <div className='flex flex-col bg-white'>
        <div className='flex flex-col'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Recruitments
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          <DataTable
            data={recruitments}
            tableConfig={tableConfig}
            noCheck={true}
            // filterByStatus={true}
            // filterByType={true}
            actionConfig={actionConfig}
            filterByDate={dateConfig}
            updatePagination={(data) => setPagination(data)}
            computedActions={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RecruitmentPage;
