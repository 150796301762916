import DOMPurify from 'dompurify';
import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormSwitch from '../../form-switch';
import LoadingBar from '../../loader-bar';
import {
  useGetEmployee,
  useUpdateEmployee,
} from './../../../redux/employee/hook/index';

const EmployeeProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { id } = useParams();
  const { data: employee, isLoading } = useGetEmployee(id);
  const [activateEmployee, setActivateEmployee] = useState(false);
  const { mutateAsync: updateEmployee, isLoading: loading } =
    useUpdateEmployee();
  const [gender, setGender] = useState('Male');
  const [country, setCountry] = useState('nigeria');

  const [editMode, setEditMode] = useState(false);

  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  const countryOptions = [{ label: 'Nigeria', value: 'nigeria' }];

  useEffect(() => {
    if (employee) {
      setActivateEmployee(employee?.status == 1 ? true : false);
      setGender(employee.gender);
      setCountry(employee.country);
      // console.log('employee', employee);
    }
  }, [employee]);

  function submit(data) {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const staff_number = DOMPurify.sanitize(data?.staff_number);
    const dob = DOMPurify.sanitize(data?.dob);
    const address = DOMPurify.sanitize(data?.address);
    const city = DOMPurify.sanitize(data?.city);
    const state = DOMPurify.sanitize(data?.state);
    const tax_number = DOMPurify.sanitize(data?.tax_number);
    const pfa_name = DOMPurify.sanitize(data?.pfa_name);
    const rsa_pin = DOMPurify.sanitize(data?.rsa_pin);
    const date_joined = DOMPurify.sanitize(data?.date_joined);
    const date_left = DOMPurify.sanitize(data?.date_left);

    let payload = {
      employeeId: id,
      data: {
        first_name,
        last_name,
        email,
        mobile,
        gender,
        staff_number,
        tax_number,
        dob,
        country,
        address,
        city,
        state,
        pfa_name,
        rsa_pin,
        date_joined,
        date_left,
      },
    };

    updateEmployee(payload).then((result) => {
      setEditMode(false);
    });
  }

  return (
    <React.Fragment>
      
          <div className='box-shadow h-full w-full py-4 px-5'>
            <span className='h4'>Profile</span>
            <hr className='divider' />
            {isLoading?
            <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
                <LoadingBar loading={isLoading} />
            </div>
            :
            <>
              {employee ? 
              <>
              <div className='w-full md:w-[20%] mt-2'>
                <FormSwitch
                  label={'Edit Mode'}
                  name={'switch-edit-mode'}
                  checked={editMode}
                  onClick={() => setEditMode(!editMode)}
                  value={editMode}
                />
              </div>
              {editMode ? (
                <form className='form ' onSubmit={handleSubmit(submit)}>
                  <div className={'flex flex-col mt-3 w-full md:w-[100%]'}>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='First Name'
                          name='first_name'
                          type='text'
                          defaultValue={
                            employee.first_name ? employee.first_name : 'Not Set'
                          }
                          inputRef={register(formValidation('text', true))}
                          readOnly={loading}
                          error={errors.first_name}
                          errorMessage={
                            errors.first_name && errors.first_name.message
                          }
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Last Name'
                          name='last_name'
                          type='text'
                          defaultValue={
                            employee.last_name ? employee.last_name : 'Not Set'
                          }
                          inputRef={register(formValidation('text', true))}
                          error={errors.last_name}
                          errorMessage={
                            errors.last_name && errors.last_name.message
                          }
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Email'
                          name='email'
                          type='text'
                          inputRef={register(formValidation('email', true))}
                          defaultValue={employee.email ? employee.email : 'Not Set'}
                          error={errors.email}
                          errorMessage={errors.email && errors.email.message}
                        />
                      </div>
                    </div>

                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Mobile'
                          name='mobile'
                          type='number'
                          inputRef={register(formValidation('number'))}
                          error={errors.mobile}
                          errorMessage={errors.mobile && errors.mobile.message}
                          defaultValue={
                            employee.mobile ? employee.mobile : 'Not Set'
                          }
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormSelect
                          defaultValue={gender}
                          options={genderOptions}
                          onChange={(selected) => {
                            setGender(selected);
                          }}
                          label='Gender'
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Staff Number'
                          name='staff_number'
                          inputRef={register(formValidation('text'))}
                          error={errors.staff_number}
                          errorMessage={
                            errors.staff_number && errors.staff_number.message
                          }
                          defaultValue={
                            employee.staff_number
                              ? employee.staff_number
                              : 'Not Set'
                          }
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Date Of Birth'
                          name='dob'
                          type='date'
                          defaultValue={employee.dob ? employee.dob : 'Not Set'}
                          inputRef={register(formValidation('text'))}
                          error={errors.dob}
                          errorMessage={errors.dob && errors.dob.message}
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Date Joined'
                          name='date_joined'
                          type='date'
                          defaultValue={
                            employee.date_joined ? employee.date_joined : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          error={errors.date_joined}
                          errorMessage={
                            errors.date_joined && errors.date_joined.message
                          }
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Date Left'
                          name='date_left'
                          type='date'
                          defaultValue={
                            employee.date_left ? employee.date_left : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          error={errors.date_left}
                          errorMessage={
                            errors.date_left && errors.date_left.message
                          }
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Tax ID'
                          name='tax_number'
                          type='text'
                          defaultValue={
                            employee.tax_number ? employee.tax_number : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          error={errors.tax_number}
                          errorMessage={
                            errors.tax_number && errors.tax_number.message
                          }
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='Pension RSA Pin'
                          name='rsa_pin'
                          type='text'
                          defaultValue={
                            employee.rsa_pin ? employee.rsa_pin : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          error={errors.rsa_pin}
                          errorMessage={errors.rsa_pin && errors.rsa_pin.message}
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='PFA Name'
                          name='pfa_name'
                          type='text'
                          defaultValue={
                            employee.pfa_name ? employee.pfa_name : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          error={errors.pfa_name}
                          errorMessage={errors.pfa_name && errors.pfa_name.message}
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[250px]'>
                        <FormSelect
                          defaultValue={country}
                          options={countryOptions}
                          onChange={(selected) => {
                            setCountry(selected);
                          }}
                          label='Country'
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='State'
                          name='state'
                          type='text'
                          defaultValue={employee.state ? employee.state : 'Not Set'}
                          inputRef={register(formValidation('text'))}
                          readOnly={loading}
                          error={errors.state}
                          errorMessage={errors.state && errors.state.message}
                        />
                      </div>
                      <div className='w-full md:w-[250px]'>
                        <FormInput
                          label='City'
                          name='city'
                          type='text'
                          defaultValue={employee.city ? employee.city : 'Not Set'}
                          inputRef={register(formValidation('text'))}
                          readOnly={loading}
                          error={errors.city}
                          errorMessage={errors.city && errors.city.message}
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-[800px]'>
                        <FormInput
                          label='Address'
                          name='address'
                          type='text'
                          defaultValue={
                            employee.address ? employee.address : 'Not Set'
                          }
                          inputRef={register(formValidation('text'))}
                          readOnly={loading}
                          error={errors.address}
                          errorMessage={errors.address && errors.address.message}
                        />
                      </div>
                    </div>

                    <div className='flex flex-row mt-4 justify-between items-center'>
                      <div className='w-full md:w-[40%] mt-3'>
                        <FormSwitch
                          label={'Deactivate employee'}
                          name={'activate-employee'}
                          checked={activateEmployee}
                          onClick={() => setActivateEmployee(!activateEmployee)}
                        />
                      </div>
                    </div>
                    <div className='flex justify-end mt-4'>
                      <div className='flex items-end h-full'>
                        <Button
                          text='Update'
                          type='submit'
                          loading={loading}
                          // disabled={false}
                          className='!px-7 !h-[42px] py-6'
                          textClass='!header-6'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className='w-full md:w-[100%]'>
                  <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>First name</span>
                      <span className='mt-1 text-color-black'>
                        {employee.first_name ? employee.first_name : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Last name</span>
                      <span className='mt-1 text-color-black'>
                        {employee.last_name ? employee.last_name : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>
                        Email Address
                      </span>
                      <span className='mt-1 text-color-black'>
                        {employee.email ? employee.email : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>
                        Phone number{' '}
                      </span>
                      <span className='mt-1 text-color-black'>
                        {employee.mobile ? employee.mobile : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Employee ID</span>
                      <span className='mt-1 text-color-black'>
                        {employee.staff_number ? employee.staff_number : 'Not Set'}
                      </span>
                    </div>

                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Tax ID</span>
                      <span className='mt-1 text-color-black'>
                        {employee.tax_number ? employee.tax_number : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>
                        Pension RSA Pin
                      </span>
                      <span className='mt-1 text-color-black'>
                        {' '}
                        {employee.rsa_pin ? employee.rsa_pin : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>PFA Name</span>
                      <span className='mt-1 text-color-black'>
                        {' '}
                        {employee.pfa_name ? employee.pfa_name : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Date Joined</span>
                      <span className='mt-1 text-color-black'>
                        {employee.date_joined ? employee.date_joined : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'> Date Left</span>
                      <span className='mt-1 text-color-black'>
                        {employee.date_left ? employee.date_left : 'Not Set'}
                      </span>
                    </div>
                  </div>
                  <hr className='divider' />
                  <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Gender</span>
                      <span className='mt-1 text-color-black'>
                        {employee.gender ? employee.gender : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>Country</span>
                      <span className='mt-1 text-color-black'>
                        {employee.country ? employee.country : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>State</span>
                      <span className='mt-1 text-color-black'>
                        {' '}
                        {employee.state ? employee.state : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>City</span>
                      <span className='mt-1 text-color-black'>
                        {' '}
                        {employee.city ? employee.city : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'> Address</span>
                      <span className='mt-1 text-color-black'>
                        {employee.address ? employee.address : 'Not Set'}
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='header-4 text-color-gray'>
                        Date of birth
                      </span>
                      <span className='mt-1 text-color-black'>
                        {employee.dob ? employee.dob : 'Not Set'}
                      </span>
                    </div>
                  </div>
                  <hr className='divider' />

                  <div className='flex flex-row mt-4 justify-between items-center'>
                    <div className='w-full md:w-[40%] mt-3'>
                      <FormSwitch
                        label={'Deactivate employee'}
                        name={'activate-employee'}
                        checked={activateEmployee}
                        value={activateEmployee}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              </>
              : null}
            </>
            }
          </div>
    </React.Fragment>
  );
};

export default EmployeeProfile;
