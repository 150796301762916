import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useSendUserBulkNotification } from '../../../redux/email/hook';
import Button from '../../button';
import FormInput from '../../form-input';
import FormTextArea from '../../form-textarea';

function SendNotification ({ isOpen, closeModal }) {
    const {
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const [subject, setSubject] = useState()
    const [body, setBody] = useState()

    const { mutateAsync: sendUserNotification, isLoading: sendUserBulkNotificationLoading } =
    useSendUserBulkNotification();


    const submitForm = async () => {
        let payload = {
            subject,
            body,
        }
        await sendUserNotification(payload).then(()=> {
            closeModal()
        });
    };

    return (
        <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
        <div className='flex flex-col pb-4 overflow-auto'>
            <div className='pl-8 pr-8 pt-8'>
            <div className='flex flex-row justify-between'>
                <span className='header-3'>{'Send Mobile User Notification'}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
                </div>
            </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
                <form className='form'>
                    <FormInput
                        label='Subject'
                        name='subject'
                        type='text'
                        value={subject}
                        placeholder={'Email Subject'}
                        onInput={(e) => {
                            setSubject(e.target.value)
                        }}
                        error={errors.subject}
                        errorMessage={errors.subject && errors.subject.message}
                    />
                    <div className='w-full'>
                        <FormTextArea
                            label='Message'
                            name='comment'
                            value={body}
                            placeholder={'Enter new message here'}
                            onInput={(e) => {
                                setBody(e.target.value)
                            }}
                            className='text-[14px]'
                            type='text'
                            error={errors.body}
                            errorMessage={errors.body && errors.body.message}
                        />
                    </div>
                    <div className='w-full mt-[20px]'>
                        <Button
                        text={'Send Notification'}
                        type='button'
                        onClick={() => submitForm()}
                        loading={sendUserBulkNotificationLoading}
                        />
                    </div>
                </form>
            </div>
        </div>
        </Modal>
    );
}

export default SendNotification;
