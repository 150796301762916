import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import FacebookSVG from '../../../assets/svg/facebook.svg';
import TwitterSVG from '../../../assets/svg/twitter.svg';
import LinkedinSVG from '../../../assets/svg/linkedin.svg';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import moment from 'moment';

function RecruitmentDetailsModal({ isOpen, closeModal, recruitmentData }) {

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto pb-6'>
        <div className='pl-6 pr-6 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-3'>View Recruitment Details</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
        </div>
        <hr className='divider mb-1' />
        <div className='flex flex-col pr-12 pl-12 w-full'>
          <div className='w-full'>
            <div
              className={classNames('flex flex-col')}
            >
                <>
                <div className='mt-2 grid md:grid-cols-2 grid-cols-1 gap-4 w-full'>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Job Role
                    </span>
                    <span className='mt-1 text-color-black text-[14px] font-bold'>
                        {recruitmentData?.role}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Job Type
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {recruitmentData?.job_type}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Salary Range
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {recruitmentData?.min_salary} - 
                        {recruitmentData?.max_salary}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Education Level
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {recruitmentData?.min_education}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Experience Level
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {recruitmentData?.experience_level}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Location Type
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {recruitmentData?.location_type}
                    </span>
                    </div>
                    <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                        Application Closing Date
                    </span>
                    <span className='mt-1 text-color-black text-[14px]'>
                        {moment(recruitmentData?.application_end).format('MMMM DD, YYYY')}
                    </span>
                    </div>
                </div>
                <div className='grid md:grid-cols-1  grid-cols-1 gap-4 w-full p4 mt-[20px]'>
                    <div className='border-1 p-3 !max-h-[600px] rounded-md overflow-auto'>
                    <span className='header-5 text-color-gray mb-2'>
                        Job Description
                    </span>
                    <td dangerouslySetInnerHTML={{__html: recruitmentData?.job_description}} />
                    </div>
                </div>
                </>
            </div>
          </div>
          <hr className='divider mt-1 mb-1' />
        </div>
        <hr className='divider mt-2' />
        <div className='flex flex-col p3 items-center justify-center'>
          <span className='text-color-black pt-2 text-[14px]'>
            Candidate application link:
          </span>
          <span className=' text-secondary-2 pt-1 !text-center'>
            {recruitmentData?.application_url}{' '}
          </span>
        </div>
        <div className='p-2 text-[14px] text-center w-[100%] '>Share via:</div>
        <div className='flex flex-row gap-4 justify-center items-center'>
          <a href='https://www.facebook.com' target='_blank' rel='noreferrer'>
            <img src={FacebookSVG} alt='facebook' />
          </a>
          <a href='https://www.twitter.com' target='_blank' rel='noreferrer'>
            <img src={TwitterSVG} alt='twitter' />
          </a>
          <a href='https://www.linkedin.com' target='_blank' rel='noreferrer'>
            <img src={LinkedinSVG} alt='linkedin' />
          </a>
        </div>
      </div>
    </Modal>
  );
}

export default RecruitmentDetailsModal;
