import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';

import { useGetEmployeeReimbursements } from '../../redux/employee/hook/reimbursements';
import AddReImbursement from '../modal/employees/add-reimbursement';

const EmployeeReimbursementTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: employeeReimbursements } = useGetEmployeeReimbursements(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();

  const tableConfig = {
    title: 'Reimbursement Management',
    headers: [
      'Date Created',
      'Employee Name',
      'Phone Number',
      'Purpose',
      'Type',
      'Month',
      'Amount',
    ],
    keys: [
      'created', 
      'employeeName',
      'phone',
      'expenses_description',
      'typeLabel',
      'month',
      'expenses_amount',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Mobile',
        key: 'mobile',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View/Edit',
      color: 'green',
      action: (id) => {
        let index = employeeReimbursements.data.findIndex((item) => item.id === id);
        setReimbData(employeeReimbursements.data[index])
        setShowAddReImbursement(true)
      },
    },
  ];

  return (
    <div>
      {showAddReImbursement?
      <AddReImbursement
        isOpen={showAddReImbursement}
        closeModal={() => {
          setShowAddReImbursement(false)
        }}
        reimbData={reimbData}
        title='Edit Reimbursement'
        buttonText='UPDATE'
      />
      :
      null
      }
      <DataTable
        data={employeeReimbursements}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default EmployeeReimbursementTab;
