import DOMPurify from 'dompurify';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import { useGenerateReferralSettings } from '../../../redux/referrals/hook';

function AddReferralSettingsModal({ isOpen, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addReferralSettings, isLoading: addReferralSettingsLoading } = useGenerateReferralSettings();
  
  const submitForm = async (data) => {

    let payload = {
      scope: DOMPurify.sanitize(data?.scope),
      percentage: DOMPurify.sanitize(data?.percentage),
      duration_type: DOMPurify.sanitize(data?.duration_type),
      duration: DOMPurify.sanitize(data?.duration),
    };
    addReferralSettings(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-3' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>Add/Update Referral Settings</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              <FormInput
                label='Scope'
                name='scope'
                type='text'
                inputRef={register(formValidation('text', true))}
                error={errors.scope}
                errorMessage={
                  errors.scope && errors.scope.message
                }
              />
              <FormInput
                label='Percentage'
                name='percentage'
                type='number'
                inputRef={register(formValidation('text', true))}
                error={errors.percentage}
                errorMessage={errors.percentage && errors.percentage.message}
              />
              <FormInput
                label='Duration Type'
                name='duration_type'
                type='text'
                inputRef={register(formValidation('text', true))}
                error={errors.duration_type}
                errorMessage={errors.duration_type && errors.duration_type.message}
              />

              <FormInput
                label='Duration'
                name='duration'
                type='text'
                inputRef={register(formValidation('text', true))}
                error={errors.duration}
                errorMessage={errors.duration && errors.duration.message}
              />
            </div>

            <div className='w-full mt-[20px]'>
              <Button text='SUBMIT' type='submit' disabled={!isValid} loading={addReferralSettingsLoading}/>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddReferralSettingsModal;
