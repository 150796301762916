import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetAdvancedPayroll, useRecalculateAdvancedPayroll } from '../../redux/payroll/hook';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import DataTable from '../datatable';

export const AdvancedPayrollTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: advancedPayroll } = useGetAdvancedPayroll(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );
  const { mutateAsync: rerunAdvancedPayroll, isLoading: loading } =
    useRecalculateAdvancedPayroll();


  useEffect(() => {
    if (advancedPayroll) {
      console.log(advancedPayroll, 'adv');
    }
  }, [advancedPayroll]);

  const history = useHistory();

  async function Continue(id, type) {
    let index = advancedPayroll.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === 'preview') {
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}`,
        );
      } else if (type === 'view') {
        history.push(`/payroll/advanced/view/${advancedPayroll.data[index].ref}`);
      } else if (type === 'recalculate') {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await rerunAdvancedPayroll(advancedPayroll.data[index].ref).then(()=> {
          history.push(`/payroll/advanced/preview/${advancedPayroll.data[index].ref}`,)
        });
      }
    }
  }

  const statusConfig = [
    {
      label: 'In Progress',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Successful',
      color: 'green',
      value: 1,
    },
    
  ];

  const tableConfig = {
    headers: ['Date', 'Ref. Number', 'Description', 'Month', 'Status'],
    keys: ['created', 'ref', 'desc', 'month', 'status'],
    mobileHeader: {
      left: {
        title: 'Date',
        key: 'created',
      },
      right: {
        title: 'Description',
        key: 'desc',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Continue',
      color: 'green',
      action: (id) => {
        Continue(id, 'preview');
      },
    },
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        Continue(id, 'view');
      },
    },
    {
      name: 'Send Payslips',
      color: 'red',
      action: () => {},
    },
    {
      name: 'Recalculate',
      color: 'orange',
      action: (id) => {
        Continue(id, 'recalculate')
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white'>
      <div className='flex flex-col'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Advanced Payroll Summary
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={advancedPayroll}
          tableConfig={tableConfig}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          searchData={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};
