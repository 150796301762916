import result from 'autoprefixer/data/prefixes';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useEffect } from 'react';
import CollapsingIcon from './collapsing-icon';

const FormDatalist = ({
  id,
  label,
  className,
  value,
  error,
  errorMessage,
  theme,
  setEmployee,
  inputRef,
  onSelect,
  data = [],
  noOfResults = 10,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [text, setText] = useState('');
  const [showResults, setShowResult] = useState(false);
  const [results, setResults] = useState([]);

  function handleItemSelect(item) {
    onSelect(item.value);
    setText(item.label);
    setShowResult(false);
  }

  useEffect(() => {
    if (search !== '') {
      let newResult = data.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase()),
      );
      //Trim results
      newResult = newResult.slice(0, noOfResults);
      setResults(newResult);
      setShowResult(true);
    }
  }, [search]);

  return (
    <>
      <div className='pb-[20px] flex flex-col'>
        <div className='flex w-full flex-col'>
          {label && (
            <label className='text-[18px] leading-[23px] font-normal text-color-gray'>
              {label}
            </label>
          )}
          <div>
            <div id='datalist'>
              <input
                id='datalist-input'
                type='text'
                autoComplete='off'
                // onFocus={() => setShowResult(true)}
                // onBlur={() => setShowResult(false)}
                ref={inputRef}
                onInput={(event) => {
                  setSearch(event.target.value);
                  setText(event.target.label);
                }}
                value={text}
                {...rest}
              />
              <i id='datalist-icon' className='icon iconfont'>
                <CollapsingIcon />
              </i>
              {/* Result List */}
              {results && results.length > 0 ? (
                <ul
                  className={classNames(
                    'datalist-items px-0 mt-2 rounded-sm border border-secondary-2 !z-[10000] bg-white',
                    {
                      hidden: results.length === 0 || !showResults,
                    },
                  )}
                >
                  {results.map((item) => (
                    <li
                      id={'12'}
                      className={`flex flex-row justify-between py-2 cursor-pointer
                       hover:bg-secondary-2
                        px-3 rounded-sm hover:text-white`}
                      onClick={() => handleItemSelect(item)}
                    >
                      <div className='flex flex-row items-center text-inherit'>
                        <span className='text-inherit'>{item.label}</span>
                      </div>

                      {/* <div className='text-inherit'>
                        <span className='text-inherit'>{item.mobile}</span>
                      </div> */}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        {error && <span className='text-error'>* {errorMessage}</span>}
      </div>
    </>
  );
};

export default FormDatalist;
