import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import AdminManagement from '../../components/settings/admin-management';
import PlansAndBillings from '../../components/settings/plans-and-billings';
import TabView from '../../components/tabview';
import ReferralManagement from '../../components/settings/referrals-management';

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const SettingsTabs = [
    {
      title: 'Admin Users Management',
      Content: AdminManagement,
    },
    {
      title: 'Referrals Management',
      Content: ReferralManagement,
    },
    {
      title: 'Plans and Billings',
      Content: PlansAndBillings,
    },
  ];

  return (
    <DashboardLayout title={'Settings'}>
      <div className='flex flex-col'>
        <TabView
          Tabs={SettingsTabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
    </DashboardLayout>
  );
};

export default SettingsPage;
