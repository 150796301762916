import DOMPurify from 'dompurify';
import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetBanks, useResolveBankAccount } from '../../../redux/bank/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormDatalist from '../../form-datalist';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormSwitch from '../../form-switch';
import LoadingBar from '../../loader-bar';
import {
  useGetEmployee,
  useUpdateEmployee,
} from './../../../redux/employee/hook/index';

const EmployeeBankInformation = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange'
  });

  const { id } = useParams();
    
  const { data: employee } = useGetEmployee(id);
  const { mutateAsync: updateEmployee, isLoading: loading } =
      useUpdateEmployee();
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
        useResolveBankAccount();
  const { data: banks, isLoading } = useGetBanks();
  
  const [editMode, setEditMode] = useState(false);
  const [bankname, setBankName] = useState('');
  const [account_number, setAccountNumber] = useState('');
  const [account_name, setAccountName] = useState('');

  useEffect(() => {
    if (employee) {
      setAccountName(employee.account_name);
      setAccountNumber(employee.account_number);
      setBankName(employee.bank);
    }
  }, [employee]);
    
    
  useEffect(() => {
    if (account_number.length >= 10 && bankname!== "") {
      let payload = {
        bankname,
        account_number,
      };
        setAccountName('');
        resolveAccount(payload).then((response) => {
            setAccountName(response.data.accountname);
      });
    }
  }, [account_number, bankname]);
    

  function submit(data) {
    let payload = {
      employeeId: id,
      data: {
        bank: bankname,
        account_number,
        account_name,
      },
    };
      
    updateEmployee(payload).then((result) => {
      setEditMode(false);
    });
  }

  return (
    <React.Fragment>
        <div className='box-shadow h-full w-full py-4 px-5 min-h-[450px]'>
          <span className='h4'>Bank Information</span>
          <hr className='divider' />
          {isLoading?
            <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
                <LoadingBar loading={isLoading} />
            </div>
            :
            <>
              {employee 
              ?
              <>
                <div className='w-full md:w-[20%] mt-2'>
                  <FormSwitch
                    label={'Edit Mode'}
                    name={'switch-edit-mode'}
                    checked={editMode}
                    onClick={() => setEditMode(!editMode)}
                    value={editMode}
                  />
                </div>   
                {editMode ? (
                  <form className='form ' onSubmit={handleSubmit(submit)}>
                    <div className={'flex flex-col mt-3 w-full md:w-[100%]'}>
                      <div className='flex flex-col md:flex-row justify-between'>
                        <div className='w-full md:w-[250px]'>
                          <FormDatalist
                            label='Bank Name'
                            data={banks ? banks : []}
                            value={bankname}
                            onSelect={(value) => setBankName(value)}
                          />
                        </div>
                                        
                        <div className='w-full md:w-[250px]'>
                          <FormInput
                            label='Bank Account Number'
                            name='account_number'
                            type='text'
                            value={
                            account_number
                            }
                            inputRef={register(formValidation('number', true, 10))}
                            error={errors.account_number}
                            errorMessage={
                              errors.account_number && errors.account_number.message
                            }
                            onInput={(e)=> setAccountNumber(e.target.value)}
                          />
                        </div>
                      <div className='w-full md:w-[250px]'>
                                      
                          <FormInput
                            label='Bank Account Name'
                            name='account_name'
                            type='text'
                            placeholder={resolveAccountLoading ? "Fetching Account Name ...." : ""}
                            readOnly
                            inputRef={register(formValidation('text', true))}
                            value={account_name}
                            error={errors.account_name}
                            errorMessage={errors.account_name && errors.account_name.message}
                          />
                        </div>
                      </div>

                      <div className='flex justify-end mt-4'>
                        <div className='flex items-end h-full'>
                          <Button
                            text='Update'
                            type='submit'
                            loading={loading}
                              disabled={!isValid}
                            className='!px-7 !h-[42px] py-6'
                            textClass='!header-6'
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                ) 
                : 
                (
                  <div className='w-full md:w-[100%]'>
                    <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                      <div className='flex flex-col'>
                        <span className='header-4 text-color-gray'>Bank name</span>
                        <span className='mt-1 text-color-black'>
                          {employee.bank ? employee.bank : 'Not Set'}
                        </span>
                      </div>
                      <div className='flex flex-col'>
                        <span className='header-4 text-color-gray'>Bank Account Number</span>
                        <span className='mt-1 text-color-black'>
                          {employee.account_number? employee.account_number : 'Not Set'}
                        </span>
                      </div>
                      <div className='flex flex-col'>
                        <span className='header-4 text-color-gray'>
                          Bank Account Name
                        </span>
                        <span className='mt-1 text-color-black'>
                          {employee.account_name ? employee.account_name : 'Not Set'}
                        </span>
                      </div>
                    </div>

          
                  </div>
                )}
              </>
              : 
              null
              }
            </>
            }
          
        </div>
      
    </React.Fragment>
  );
};

export default EmployeeBankInformation;
