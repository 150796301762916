import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useParams } from 'react-router-dom';
import TabView from '../../components/tabview';
import { useHistory } from 'react-router-dom';
import { useGetEmployee } from '../../redux/employee/hook';
import { StatusText } from '../../components/fragments/status-text';
import { employeeStatusConfig } from '../../utils/constants';
import EmployeeProfile from '../../components/employee/view/profile';
import EmployeeCompensation from '../../components/employee/view/compensation';
import SingleEmployeeSalaryAdvanceTab from '../../components/employee/view/salary-advance';
import { SingleEmployeeBonusTab } from '../../components/employee/view/bonus';
import SingleEmployeeSuspensionsTab from '../../components/employee/view/suspension';
import SingleEmployeeReimbursementTab from '../../components/employee/view/reimbursements';
import EmployeeBankInformation from '../../components/employee/view/bank-information';

const ViewEmployeePage = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: employee, isLoading } = useGetEmployee(id);

  useEffect(() => {
    if (employee) {
      console.log('employee', employee);
    }
  }, [employee]);

  const EmployeeTabs = [
    {
      title: 'Profile',
      Content: EmployeeProfile,
    },
    {
      title: 'Compensation',
      Content: EmployeeCompensation,
    },
    {
      title: 'Bank information',
      Content: EmployeeBankInformation
    },
    {
      title: 'Salary Advance',
      Content: SingleEmployeeSalaryAdvanceTab,
    },
    {
      title: 'Bonus',
      Content: SingleEmployeeBonusTab,
    },
    {
      title: 'Suspension',
      Content: SingleEmployeeSuspensionsTab,
    },
    {
      title: 'Reimbursables',
      Content: SingleEmployeeReimbursementTab,
    },
  ];

  return (
    <DashboardLayout title='View Employee'>
      <>
        <div className='flex flex-col'>
          <div className='flex flex-row items-center box-shadow h-[104px]'>
            <div className='flex flex-row px-[55px] justify-between  w-full'>
              <div className='flex flex-row'>
                <div className='h-[48px] w-[48px] rounded-[48px] border-[3px] border-secondary-2'></div>
                <div className='flex flex-col pl-4'>
                  <span className='p1-bold'>
                    {employee?.first_name} {employee?.last_name}
                  </span>
                  <span className='p4 text-color-gray'>
                    {employee?.job_position}
                  </span>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <StatusText
                  statusConfig={employeeStatusConfig}
                  code={employee?.status}
                  className={'py-2 rounded p1-bold'}
                />
              </div>
            </div>
          </div>

          <div className='mt-8'>
            <TabView
              Tabs={EmployeeTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default ViewEmployeePage;
