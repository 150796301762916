import React from 'react';
import { useGetEmployees } from '../../redux/employee/hook';
import DataTable from '../datatable';
import { useState } from 'react';
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import SendEmail from '../modal/emails/send-email';
import Button from '../button';
import SendSingleEmail from '../modal/emails/send-single-email';
import SendFeatureEmail from '../modal/emails/send-feature-email';

  const OverviewTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: employees } = useGetEmployees(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const history = useHistory();
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendSingleEmail, setShowSendSingleEmail] = useState(false);
  const [showSendFeatureEmail, setShowSendFeatureEmail] = useState(false);
  const [employeeId, setEmployeeId] = useState(false);

  const tableConfig = {
    headers: [
      'Created',
      'Employee Name',
      'Company Name',
      'Email',
      'Mobile',
      'Status',
    ],
    keys: ['created', 'employeeName','company_name', 'email', 'mobile', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => history.push('/employee/view/' + id),
    },
    {
      name: 'Send Email',
      color: 'green',
      action: (id) => {
        let index = employees.data.findIndex((item) => item.id === id);
        setEmployeeId(employees.data[index].id)
        setShowSendSingleEmail(true)
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white'>
      {showSendSingleEmail ?
        <SendSingleEmail
            title={'Send Email To Employee'}
            isOpen={showSendSingleEmail}
            closeModal={() => setShowSendSingleEmail(false)}
            type={'employee'}
            id={employeeId}
            buttonText='Send Email'
        />
      : null}
      {showSendEmail ?
        <SendEmail
            title={'Send Email To Employees'}
            isOpen={showSendEmail}
            closeModal={() => setShowSendEmail(false)}
            type={'employees'}
            buttonText='Send Email'
        />
      : null}
      {showSendFeatureEmail ?
        <SendFeatureEmail
            title={'Send Feature Email To Employes'}
            isOpen={showSendFeatureEmail}
            closeModal={() => setShowSendFeatureEmail(false)}
            type={'employee'}
            buttonText='Send Email'
        />
      : null}
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Employees
        </div>
        <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
          <div className='md:w-fit w-[100%]'>
            <Button
              text='Send Email'
              type='button'
              className='flex gap-2 h-[35px] w-fit p-2'
              textClass={'!text-[13px]'}
              onClick={() => {setShowSendEmail(true)}}
            />
          </div>
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      
      <DataTable
        data={employees}
        tableConfig={tableConfig}
        statusConfig={employeeStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default OverviewTab;
