import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddPeoplePaymentPlan, useGetPeoplePlansForPayments, useUpdatePeoplePaymentPlan } from '../../../redux/hr-subscription/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';

function AddPeoplePaymentPlan({ isOpen, closeModal, title, buttonText, paymentData }) {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const { data: subscription_packages } = useGetPeoplePlansForPayments(
        10000,
        1,
        '',
    );
    useEffect(() => {
        if (paymentData) {
            setbilling_cycle(paymentData.billing_cycle)
            setsubscription_package_id(paymentData.subscription_package_id)
        }
    }, [paymentData])

    const [subscription_package_id, setsubscription_package_id] = useState(null);

    const [billing_cycle, setbilling_cycle] = useState('monthly')

    const cycleOptions = [
        {label:'Annual', value:'annual'},
        {label:'Monthly', value:'monthly'},
    ]
    
    const { mutateAsync: addPeoplePaymentPlans, isLoading: addPeoplePaymentPlansLoading } = useAddPeoplePaymentPlan();
    const { mutateAsync: updatePeoplePaymentPlans, isLoading: updatePeoplePaymentPlansLoading } = useUpdatePeoplePaymentPlan();
    
    const submitForm = async (data) => {
    const grace_period = DOMPurify.sanitize(data?.grace_period);
    const employee_price = DOMPurify.sanitize(data?.employee_price);
    const base_price = DOMPurify.sanitize(data?.base_price);
    const discount = DOMPurify.sanitize(data?.discount);

    let payload = {
        subscription_package_id: subscription_package_id,
        billing_cycle: billing_cycle,
        grace_period,
        employee_price,
        base_price,
        discount,
    };
    if (!paymentData) {
        addPeoplePaymentPlans(payload).then(() => {
            closeModal();
        });
    } else {
        let editPayload = {
            id: paymentData?.id,
            data: payload
        }
        updatePeoplePaymentPlans(editPayload).then(() => {
            closeModal();
        });
    }
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-8'>
              <div className='flex flex-row justify-between'>
                <span className='header-3'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
              <div className='flex flex-col md:flex-col w-full justify-between'>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                    {subscription_packages ? (
                        <div className='w-full'>
                            <FormSelect
                                value={subscription_package_id}
                                options={subscription_packages}
                                onChange={(selected) => {
                                    setsubscription_package_id(selected);
                                }}
                                label='Subscription Package'
                            />
                        </div>
                    ) : null}
                </div>
                <FormSelect
                    value={billing_cycle}
                    options={cycleOptions}
                    onChange={(selected) => {
                        setbilling_cycle(selected);
                    }}
                    label='Billing Cycle'
                />
                <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:w-1/2 pr-2'>
                        <FormInput
                            defaultValue={paymentData?.grace_period}
                            label='Grace Period'
                            name='grace_period'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            error={errors.grace_period}
                            errorMessage={errors.grace_period && errors.grace_period.message}
                        />
                    </div>
                    <div className='w-full md:w-1/2 pl-2'>
                        <FormInput
                            defaultValue={paymentData?.employee_price}
                            label='Employee Price'
                            name='employee_price'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            error={errors.employee_price}
                            errorMessage={errors.employee_price && errors.employee_price.message}
                        />
                    </div>
                </div>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:w-1/2 pr-2'>
                        <FormInput
                            defaultValue={paymentData?.base_price}
                            label='Base Price'
                            name='base_price'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            error={errors.base_price}
                            errorMessage={errors.grace_period && errors.base_price.message}
                        />
                    </div>
                    <div className='w-full md:w-1/2 pl-2'>
                        <FormInput
                            defaultValue={paymentData?.discount}
                            label='Discount'
                            name='discount'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            error={errors.discount}
                            errorMessage={errors.discount && errors.discount.message}
                        />
                    </div>
                </div>
              </div>
              <div className='w-full mt-[20px]'>
                <Button text={buttonText} type='submit' disabled={!isValid} loading={addPeoplePaymentPlansLoading || updatePeoplePaymentPlansLoading}/>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddPeoplePaymentPlan;
