import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { formValidation } from "../../utils/functions";
import AuthLayout from "../../components/layouts/auth";
import { useSelector } from "react-redux";
import { useForgotPassword } from "../../redux/admin/hook";

const ForgotPasswordPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { section } = useSelector((state) => state.sectionSlice);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const history = useHistory();
  const { mutateAsync: recoverPassword, isLoading: loading } = useForgotPassword();

  const submitForm = async (data) => {
    const email = DOMPurify.sanitize(data?.email);
  
    recoverPassword(email).then((result) => {
      history.push(`/check-email`);
    })
  };

  return (
    <AuthLayout>
      <React.Fragment>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className='w-full text-center flex flex-col pb-[25px]'>
            <span className={section && section.name === 'core hr' 
              ?'header-3-regular md:header-2 text-hr-primary-1 pb-1'
              :'header-3-regular md:header-2 text-secondary-2 pb-1'}>
              Forgot your Password?
            </span>
            <span className='p4 md:header-4-regular text-color-gray'>
              Enter your email address below to enable us send your reset password link.
            </span>
          </div>
          <div className="top_section">
            <h6>
              
            </h6>
          </div>
          <FormInput
            label="Email"
            name="email"
            placeholder="Enter Your Email"
            type="text"
            inputRef={register(formValidation("email", true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <Button
            text="Send"
            type="submit"
            loading={loading}
            disabled={!isValid}
          />
          <div className="flex justify-end d_flex remember_section mb-3 mt-3">
            <p className="forgot_password header-4-regular text-color-gray">
              Remember now?
                <Link to='/'>
                <span className='header-4'> Sign In</span>
              </Link>
            </p>
          </div>
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
