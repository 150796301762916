import { Axios } from './axios';

const getTickets = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

    const { data } = await Axios.get(
        `/tickets?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`,
    );
    return data?.data;
};

const getTicket = async (payload) => {
    const { data } = await Axios.get('/tickets/' + payload);
    return data?.data;
  };

const addTicket = async (payload) => {
  
    const { data } = await Axios.post(
    `/tickets`, payload,
    {
    timeout: 0
    });
    return data;
};

const geTicketComments = async (payload) => {
    const { data } = await Axios.get( `/ticket-comments/${payload}`,);
    return data?.data;
};

const addTicketComment = async (payload) => {
    const { data } = await Axios.post('/ticket-comments', payload,
    {
      timeout: 0
    });
    return data;
};

const updateTicketComment = async (payload) => {
    const { data } = await Axios.put('/ticket-comments/' + payload.id, payload.data);
    return data;
};

const markTicketResolved = async (payload) => {
    const { data } = await Axios.post(
      '/tickets/mark-as-resolved/' + payload,
    );
    return data;
};

export const contact_us = {
    getTickets,
    addTicket,
    getTicket,
    geTicketComments,
    addTicketComment,
    updateTicketComment,
    markTicketResolved
};