import { Axios } from './axios';

const checkReferral = async () => {
    const { data } = await Axios.get(`/code-check`);
    return data;
};

const getReferralUsage = async (payload) => {
    const { data } = await Axios.get(`/referral-codes?limit=${payload.limit}&page=${payload.page}`);
    return data?.data;
};

const generateReferralCode = async (payload) => {
    const { data } = await Axios.post('/referral-codes', payload);
    return data;
};

const generateReferralSettings = async (payload) => {
    const { data } = await Axios.post('/referral-code-settings', payload);
    return data;
};

const getReferral = async (payload) => {
    const { data } = await Axios.get('/referral-codes/'+payload);
    return data;
};

const updateReferral = async (payload) => {
    const { data } = await Axios.put('/referral-codes/'+payload.id, payload.body);
    return data;
};

const updateReferralGroup = async (payload) => {
    const { data } = await Axios.put('/referral_codes/by-group/update');
    return data;
};

const activateReferral = async (payload) => {
    const { data } = await Axios.get(`/referral-codes/${payload.id}/activation`, payload.data);
    return data;
};

export const referrals = {
    checkReferral,
    getReferralUsage,
    generateReferralCode,
    getReferral,
    updateReferral,
    updateReferralGroup,
    generateReferralSettings,
    activateReferral
};