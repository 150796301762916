import { Axios } from '../axios';

const getEmployeeReimbursements = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/reimbursements?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const AddReImbursement = async (payload) => {
  const { data } = await Axios.post('/reimbursements', payload);
  return data;
};

const editReImbursement = async (payload) => {
  const { data } = await Axios.put('/reimbursements/'+payload.id, payload.body);
  return data;
};

const bulkAddReimbursement = async (payload) => {
  const { data } = await Axios.post('/reimbursements/bulk', payload);
  return data;
};

const getSingleEmployeeReimbursements = async (payload) => {
  
  const { data } = await Axios.get(
    `/reimbursements/employee/` + payload.id,
  );

  return data?.data;
};

export const employeeReimbursement = {
  getEmployeeReimbursements,
  AddReImbursement,
  bulkAddReimbursement,
  getSingleEmployeeReimbursements,
  editReImbursement,
};
